/* eslint-disable react/react-in-jsx-scope */
import style from './fileItem.module.css'
import CloseIcon from './closeIcon'
import CompletedIcon from './completedIcon'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useState} from 'react'
import utils from './utils'
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from './animatedProgressProvider'
import { Line } from 'rc-progress';

import { useStateValue } from '../state';
import consts from './consts';

export default function FileItem({fileName, text, fileSize, onClose, uploadCompleted, fileUploadProgressInPercentage, mbUploaded, uploadInProgress, preparingForUpload, updateFileUploadCompleted}) {
    const [showDeleteIcon, updateShowDeleteIcon] = useState(false);
    const [progressBarCompleted, updateProgressBarCompleted] = useState(uploadCompleted);
    const PROGRESS_BAR_ENABLED = 'line'; //can be 'line' 'circle'
    const showProgressBar = false;

    const [
      {formFooterViewState},
      dispatch,
    ] = useStateValue();

    const handleOnClickClose = () => {
        onClose({fileName, fileSize});
    }
    const handleOnMouseEnter = () => {
      updateShowDeleteIcon(true);
    }
    const handleOnMouseLeave = () => {
      updateShowDeleteIcon(false);
    }

    const handleFileUploadCompleted = () => {
      updateProgressBarCompleted(true);
      //updateFileUploadCompleted(fileName);
    }
    
    //TODO: Unmark if we want to show progress in KB
    //let numFormat = new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(utils.bytesToKbs(undefineIsZero(mbUploaded), true));
    return (
        <li style={{backgroundColor: formFooterViewState !== consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD && '#EEF6FD', opacity: formFooterViewState !== consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD && '0.5'}} onMouseOver={handleOnMouseEnter} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={style.listItemStyle} key={fileName}>
          <div className={style.listItemContent}>
          <p className={uploadCompleted ? style.fileName : style.fileNameInProgress}> {text} </p>
         <p style={{display: !showProgressBar || !uploadInProgress || uploadCompleted ? 'flex' : 'none'}} className={style.fileSize}> {fileSize} </p>
         <p style={{display: !uploadInProgress || !showProgressBar ? 'none' : 'flex'}} className={style.uploadProgress}>{`${utils.bytesToSize(utils.undefineIsZero(mbUploaded), true)} / ${fileSize} uploaded`} </p>
         <AnimatedProgressProvider valueStart={0} valueEnd={utils.undefineIsZero(fileUploadProgressInPercentage)} duration={0.1} easingFunction={easeQuadInOut}>
          {value => {
          value == 100 && handleFileUploadCompleted();
          return (
            <Line style={{opacity: formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS && showProgressBar ? '1' : '0', height: (formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD || !showProgressBar) && 0, display: formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD || !showProgressBar ? 'block' : uploadInProgress ? 'inline-block' : !progressBarCompleted && mbUploaded > 0 ? 'inline-block' : 'none', width: '235px', borderRadius: '50px'}} trailWidth="2" percent={value} strokeWidth="2" strokeColor="#13BCB4" />
          );
        }} 
      </AnimatedProgressProvider> 
        {false && <img style={{display: preparingForUpload ? 'inline-block' : 'none'}} className={style.spinner} src="/images/spinner.gif" alt="spinner"/>}
         {formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD && <CloseIcon onClick={handleOnClickClose} iconStyle={{
                    position: "absolute",
                    width: "24px", 
                    height: "24px",
                    left: "240px",
                    top: "16px",
                    cursor: "pointer",
                    display: 'inline-block',
                    //display: uploadCompleted && progressBarCompleted && !showDeleteIcon ? 'none' : 'inline-block',
                    //display: preparingForUpload || uploadCompleted && progressBarCompleted && !showDeleteIcon ? 'none' : 'inline-block',
                    zIndex: 10
         }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>   }
        {false && <CompletedIcon iconStyle={{
                    position: "absolute",
                    left: "244px",
                    top: "16px",
                    opacity: uploadCompleted && progressBarCompleted && !showDeleteIcon ? '1' : '0',
                    zIndex: 1
                 }}/>}
        {PROGRESS_BAR_ENABLED == 'circle' &&  <div className={style.CircularProgressbar} style={{ display: uploadCompleted && progressBarCompleted ? 'none' : 'inline-block', width: 30, height: 30 }}> 
         <AnimatedProgressProvider valueStart={0} valueEnd={utils.undefineIsZero(fileUploadProgressInPercentage)} duration={1.4} easingFunction={easeQuadInOut}>
          {value => {
          value == 100 && handleFileUploadCompleted();
          return (
            <CircularProgressbar value={Math.round(utils.undefineIsZero(value))} text={`${Math.round(utils.undefineIsZero(value))}%`}
            styles={buildStyles({
             textSize: '32px',
             pathTransition: "none"
           })}/>
          );
        }}
      </AnimatedProgressProvider>
    </div>    }
          </div>
        </li>
    )
  };