/* eslint-disable react/react-in-jsx-scope */
export default function AddFileIcon({styleOverride, onClick, width, height})  {
    return (
        <div onClick={onClick} role="button" style={ styleOverride ? styleOverride : {position: "absolute", width: "36px", height: "36px", left: "-50px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 36" fill="none" cursor='pointer'>
        <ellipse cx="17.7053" cy="18" rx="17.7053" ry="18" fill="#13BCB4"/>
        <path d="M19.5986 16.5596H25.5458V19.123H19.5986V25.8613H16.874V19.123H10.9267V16.5596H16.874V10.334H19.5986V16.5596Z" 
        fill="white"/> </svg>
        </div>
    );
};
