/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import style from './trialExpiredModal.module.css'
import CheckMark from './checkMark'
import consts from './consts'
import utils from './utils'
import restUtils from './restUtils'
import CloseIcon from './closeIcon'

import { useStateValue } from '../state';

export default function TrialExpiredModal({isEmbedded}) {
  const BUTTONS_VIEW_FOOTER = 'buttons view';
  const CONFIRMATION_VIEW_FOOTER = 'confirmation view';

  const [footerViewState, updateFooterViewState] = useState(BUTTONS_VIEW_FOOTER);


  const [
    {user},
    dispatch,
  ] = useStateValue()

  useEffect(() => {
     utils.trackPageView('trialExpiredModal');
    }, [])

  function renderIcon() {
    return (
        <svg className={style.iconStyle} xmlns="http://www.w3.org/2000/svg" width="66" height="105" viewBox="0 0 66 105" fill="none">
        <path d="M11.8164 88.2823V89.275C11.8164 90.1137 12.4971 90.7944 13.3358 90.7944H52.6665C53.5052 90.7944 54.1859 90.1137 54.1859 89.275V88.2823C54.1859 82.9765 51.4652 77.8166 46.526 73.7506C42.764 70.655 37.9606 68.9492 33.0012 68.9492C28.0418 68.9492 23.2384 70.655 19.4783 73.7506C14.5372 77.8145 11.8164 82.9745 11.8164 88.2823ZM21.407 76.0966C24.6261 73.4467 28.7427 71.9881 32.9991 71.9881C37.2556 71.9881 41.3722 73.4467 44.5933 76.0966C48.7099 79.4859 50.9648 83.5073 51.1349 87.7556H14.8654C15.0376 83.5053 17.2904 79.4839 21.407 76.0966Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        <path d="M5.91684 10.5123V16.4644C5.91684 23.2856 7.75635 30.0217 11.2348 35.9474C11.6603 36.6706 12.5901 36.9137 13.3134 36.4883C14.0366 36.0629 14.2797 35.133 13.8543 34.4077C10.6493 28.9479 8.95568 22.7446 8.95568 16.4644V10.5143H57.0463V16.4624C57.0463 25.8645 53.3491 34.7805 46.6353 41.5652C45.2739 42.9428 43.7848 44.2252 42.2127 45.3779C40.0592 46.9581 38.7728 49.4844 38.7728 52.1343C38.7728 54.7842 40.0552 57.3064 42.2006 58.8825C48.258 63.3253 52.7251 69.3625 55.1238 76.3376C55.3405 76.9677 55.9301 77.3627 56.5601 77.3627C56.7242 77.3627 56.8904 77.3364 57.0544 77.2797C57.8486 77.0062 58.27 76.1431 57.9965 75.349C55.3973 67.7843 50.5554 61.2427 43.9976 56.4312C42.6281 55.4264 41.8116 53.8198 41.8116 52.1343C41.8116 50.4487 42.6341 48.8382 44.0097 47.8272C45.7094 46.5793 47.32 45.1915 48.7929 43.7005C56.0719 36.3424 60.0811 26.6688 60.0811 16.4603V10.5123C62.8039 10.4353 64.998 8.17037 64.998 5.3949C64.998 2.5708 62.729 0.273438 59.9393 0.273438H6.05866C3.25887 0.273438 1 2.56877 1 5.3949C1 8.17037 3.19404 10.4353 5.91684 10.5123ZM6.05866 3.31228H59.9413C61.0556 3.31228 61.9612 4.24621 61.9612 5.3949C61.9612 6.54358 61.0556 7.47752 59.9413 7.47752H6.05866C4.94441 7.47752 4.03884 6.54358 4.03884 5.39692C4.03884 4.22596 4.96062 3.31228 6.05866 3.31228Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        <path d="M60.0852 93.7607C60.0852 87.4987 60.1723 85.7766 59.7165 82.519C59.601 81.6884 58.8332 81.109 58.0005 81.2245C57.1699 81.3399 56.5905 82.1078 56.706 82.9404C57.1233 85.9164 57.0443 87.3893 57.0443 93.7566H33.001H8.95568C8.95568 87.1725 8.5424 81.7694 11.6744 74.2372C11.6744 74.2351 11.6765 74.2351 11.6765 74.2331C14.2048 68.1554 18.3963 62.8476 23.7994 58.8849C25.9469 57.3108 27.2292 54.7866 27.2292 52.1367C27.2292 49.4868 25.9428 46.9605 23.7913 45.3803C21.6054 43.7758 19.6018 41.9383 17.8331 39.9145C17.2801 39.2824 16.3218 39.2196 15.6898 39.7706C15.0577 40.3217 14.9949 41.282 15.5459 41.914C17.4604 44.104 19.6301 46.0935 21.9943 47.8276C23.3699 48.8385 24.1924 50.4471 24.1924 52.1347C24.1924 53.8202 23.374 55.4288 22.0045 56.4336C16.1557 60.7245 11.6157 66.476 8.87262 73.0642C8.87262 73.0662 8.8706 73.0662 8.8706 73.0682C5.50964 81.1495 5.91887 86.9091 5.91887 93.7587C4.61622 93.7951 3.39866 94.3259 2.47485 95.2639C1.52268 96.2323 1 97.5167 1 98.8781C1 101.702 3.269 104 6.05866 104H59.9413C62.731 104 65 101.702 65 98.8781C65.002 96.1026 62.808 93.8377 60.0852 93.7607ZM59.9413 100.961H6.05866C4.94441 100.961 4.03884 100.027 4.03884 98.8801C4.03884 97.7132 4.9586 96.7975 6.05866 96.7975H32.999H59.9413C61.0556 96.7975 61.9612 97.7315 61.9612 98.8801C61.9632 100.027 61.0556 100.961 59.9413 100.961Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        <path d="M34.5203 47.3749C34.5203 46.5362 33.8396 45.8555 33.0009 45.8555C32.1621 45.8555 31.4814 46.5362 31.4814 47.3749V49.4008C31.4814 50.2395 32.1621 50.9202 33.0009 50.9202C33.8396 50.9202 34.5203 50.2395 34.5203 49.4008V47.3749Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        <path d="M33.0009 66.7229C33.8396 66.7229 34.5203 66.0422 34.5203 65.2035V63.1776C34.5203 62.3389 33.8396 61.6582 33.0009 61.6582C32.1621 61.6582 31.4814 62.3389 31.4814 63.1776V65.2035C31.4814 66.0422 32.1601 66.7229 33.0009 66.7229Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        <path d="M33.0009 53.7568C32.1621 53.7568 31.4814 54.4375 31.4814 55.2763V57.3021C31.4814 58.1409 32.1621 58.8216 33.0009 58.8216C33.8396 58.8216 34.5203 58.1409 34.5203 57.3021V55.2763C34.5203 54.4375 33.8396 53.7568 33.0009 53.7568Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        <path d="M53.8277 21.2824C53.9654 20.3647 53.2543 19.5381 52.3244 19.5381H13.6785C12.7506 19.5381 12.0375 20.3647 12.1753 21.2824C13.1984 28.0975 16.3344 34.3393 21.2432 39.3351C24.3246 42.4712 28.6114 44.2702 33.0015 44.2702C37.3916 44.2702 41.6784 42.4712 44.7598 39.3351C49.6685 34.3393 52.8046 28.0975 53.8277 21.2824ZM33.0015 41.2314C29.4197 41.2314 25.925 39.7646 23.4109 37.2059C19.3773 33.1014 16.6626 28.0732 15.4957 22.5769H50.5052C49.3383 28.0732 46.6256 33.1014 42.592 37.2059C40.0779 39.7646 36.5832 41.2314 33.0015 41.2314Z" fill="#13BCB4" stroke="#13BCB4" strokeWidth="0.5"/>
        </svg>
    );
  }

  function renderFeature(featureText) {
    return (
      <div className={style.fetaureContainer}>
        <CheckMark iconStyle={{marginRight: '12px'}}/>
        <span className={style.featureText}>
          {featureText}
        </span>
      </div>
    );
  }

  function renderFeatureList() {
    const features = ['Branded page', 'Send up to 50GB per transfer', '30 days file retention', 'Up to 100 of recipients per transfer'];
    return (
      <div className={style.featureList}>
          {features.map(f => 
          {
            return renderFeature(f);
          })}
      </div>
    );
  }

  function onUpgradePlanClick() {
    //let packagePrice = utils.setSessionStorageItem(consts.SELECTED_PACKAGE.PRICE, 12);
    isEmbedded ? window.open('/posts/payment') : window.location.assign('/posts/payment');
    isEmbedded && parent.postMessage('close', '*');
  }

  function renderButtons() {
    return (
      <div style={{marginTop: '10%'}} className={style.buttonsContainer}>
        <button onClick={() => updateFooterViewState(CONFIRMATION_VIEW_FOOTER)} className={style.downgradePlan}>
        Downgrade Plan
        </button>
        <button onClick={onUpgradePlanClick} className={style.upgradePlan}>
        Upgrade Plan
          </button>
      </div>
    );
  }

  async function onDowngradeConfirmed() {
    utils.trackEvent(consts.TRACKING_CATEGORY.PRICING, 'cancel_trial_on_trial_expiration','signup_id',user.userId);
    await restUtils.post('cancelTrial', null, true);
    let url = `/${isEmbedded ? '?embedded=true' : ""}`;
    window.location.assign(url);
  }

  function renderConformationView() {
    return (
      <div style={{marginTop: '10px'}} className={style.confirmationContainer}>
        <span className={style.confirmationText}>
        Are you sure? <br/> All business features will be lost
        </span>
       <div className={style.buttonsContainer}>
        <button onClick={() =>  onDowngradeConfirmed()} className={style.downgradePlan}>
         I'm sure
        </button>
        <button onClick={() => updateFooterViewState(BUTTONS_VIEW_FOOTER)} className={style.upgradePlan}>
        Let me think
          </button>
      </div>
      </div>
    )
  }

  function renderFooterViewByState() {
    switch (footerViewState) {
      case BUTTONS_VIEW_FOOTER:
        return renderButtons();
      case CONFIRMATION_VIEW_FOOTER:
      return renderConformationView();
  
      default:
        break;
    }
  }
    return (
       <div className={style.overlayContainer}>
         {isEmbedded && <CloseIcon onClick={() => parent.postMessage("close", "*")} iconStyle={{
          width: "30px", 
          height: "30px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '10px',
          marginRight: '10px',
          zIndex: 3000,
          opacity: 0.5,
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>}
  <div className={style.container}>
      <div className={style.modalContent}>
        {renderIcon()}
      <span className={style.title}>
      Your FileDriver free trial <br/> has expired!
      </span>
      <span className={style.subTitle}>
      Upgrade now for only $12 / month
      </span>
      {renderFeatureList()}
      {renderFooterViewByState()}
      </div>
   </div>
      </div>

    )
  }