
/* eslint-disable react/react-in-jsx-scope */
import style from './noConnectionView.module.css'
import { useEffect } from 'react'

export default function NoConnectionView({transferButton, isEmbedded})  {
    return (
        <div style={{height: '100%',overflow: 'auto'}}>
            <div className={style.container}>
        <div className={isEmbedded ? style.errorTitleSmall : style.errorTitle}>
          <h1>Network Error :/</h1>
           </div>
           <div className={style.errorContent}>
           <p> Don't worry, we've got your back!</p>
           <p>We saved the files which have been uploaded successfully</p>
           <p> Once your connection is back, simply click the button below to start from where you left off</p>
           </div>
           {transferButton}
           </div>
        </div>
    );
}