
/* eslint-disable react/react-in-jsx-scope */
import style from './uploadFinishedView.module.css'
import { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import CheckMark from './checkMark';
import LinkBox from './linkBox'

export default function UploadFinishedView({transferButton, onBack, recipientsEmails, onInit, uploadFinishedViewType, downloadLink})  {
    const [copySucceeded, updateCopySucceeded] = useState(false);
    const [confettiAnimation, updateConfettiAnimation] = useState(false);

    const linkInputRef = useRef(null);

    useEffect(() => {
        history.pushState({}, '');
        window.onpopstate = function(event) {
         onBack();
        };
    })

    useEffect(() => { 
        let timeout = setTimeout(applyOnInit,1000); //a hack for the callback to work properly
        return () => {
            clearTimeout(timeout);
        }
        }, []);

        useEffect(() => { 
            let timeout;
            if(copySucceeded) {
                timeout = setTimeout(resetButton, 4000); 
            }
            return () => {
                timeout && clearTimeout(timeout);
            }
            }, [copySucceeded]);

    function resetButton() {
        updateCopySucceeded(false);
        linkInputRef && linkInputRef.current && linkInputRef.current.blur();
    }

    function applyOnInit() {
        onInit && onInit();
    }

    function renderMailCompletionView() {
        return (
            <div> 
            <Scrollbars
            renderView={props => (<div id='scrollBarDiv' {...props} style={{ ...props.style, overflowX: 'hidden' }} />)}
            thumbSize={100} universal style={{ width: 280, height: 328, overflowX: 'hidden', borderRadius: 19 }}>
            <div style={{height: '100%',overflow: 'auto'}}>
                <div className={style.container}>
              <img className={style.doneImage} src="/images/finishFlags.svg" alt="done"></img>
               <div className={style.summaryTitle}>
               <span> Great job!!</span> 
               <span className={style.summaryText}>The download link was sent to: </span>
               <ul id='recipients-list' className={style.recipientsList}>
               {recipientsEmails.map((email, index, arr) =>
                {return<li key={email} className={index  == arr.length - 1 ? style.listItemLast : style.listItem}>
                    <div className={style.listItemContent}>
                    <CheckMark iconStyle={{marginTop: '11px', marginRight: '9px'}}/>
                    <p className={style.listItemEmail}>{email}</p>
                    </div>
                    </li>})}
               </ul>
               </div>
               </div>
            </div>
            </Scrollbars>
            {transferButton}
            </div>
        );
    }

    function onCopyClick() {
        if(linkInputRef && linkInputRef.current) {
            updateConfettiAnimation(true);
            setTimeout(() => updateConfettiAnimation(false), 700);

            linkInputRef.current.focus();
            linkInputRef.current.select();
            var successful = document.execCommand('copy');
            updateCopySucceeded(successful);
        }
    }

    function renderCopyIcon() {
        return (
        <svg style={{position: 'relative', top: '2px'}} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 23" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.25177 2.48123C6.25177 2.28546 6.41047 2.12676 6.60623 2.12676H9.00749H13.8102C13.8977 2.12676 13.9822 2.15918 14.0473 2.21776L17.7564 5.556C17.8311 5.62322 17.8738 5.71898 17.8738 5.81947V16.1441C17.8738 16.3398 17.7151 16.4985 17.5193 16.4985H6.60623C6.41047 16.4985 6.25177 16.3398 6.25177 16.1441V2.48123ZM6.60623 0C5.23589 0 4.12501 1.11088 4.12501 2.48123V4.12461H2.48122C1.11088 4.12461 0 5.23549 0 6.60584V20.2687C0 21.639 1.11088 22.7499 2.48122 22.7499H12.0194C13.3898 22.7499 14.5007 21.639 14.5007 20.2687V18.6253H17.5193C18.8897 18.6253 20.0005 17.5144 20.0005 16.1441V5.81947C20.0005 5.11608 19.702 4.44573 19.1792 3.97519L15.47 0.636945C15.0144 0.226893 14.4231 0 13.8102 0H9.00749H6.60623ZM12.3739 18.6253H6.60623C5.23589 18.6253 4.12501 17.5144 4.12501 16.1441V6.25137H2.48122C2.28546 6.25137 2.12676 6.41007 2.12676 6.60584V20.2687C2.12676 20.4644 2.28546 20.6231 2.48122 20.6231H12.0194C12.2152 20.6231 12.3739 20.4644 12.3739 20.2687V18.6253Z" fill="white"/>
        </svg>
        );
    }

    function renderLinkBox() {
       return( <div className={style.linkBoxContainer}>
            <input ref={linkInputRef} value={copySucceeded ? 'Link copied' : downloadLink} type="text" readOnly className={copySucceeded ? style.linkBoxCopied : style.linkBox}/>
            <button onClick={onCopyClick} className={confettiAnimation ? style.copyLinkButtonAnimate : style.copyLinkButton}>
                {copySucceeded ? <CheckMark iconColor={'white'}/> : renderCopyIcon()}
            </button>
        </div>
       );
    }

    function renderLinkCompletionView() {
        return (
            <div> 
            <Scrollbars
            renderView={props => (<div id='scrollBarDiv' {...props} style={{ ...props.style, overflowX: 'hidden' }} />)}
            thumbSize={100} universal style={{ width: 280, height: 328, overflowX: 'hidden', borderRadius: 19 }}>
            <div style={{height: '100%',overflow: 'auto'}}>
                <div className={style.container}>
              <img className={style.doneImage} src="/images/finishFlags.svg" alt="done"></img>
               <div className={style.summaryTitle}>
               <span> Great job!!</span> 
               <span className={style.summaryText}>Copy the link and share it </span>
               <div className={style.divider}/>
                <LinkBox downloadLink={downloadLink} linkBoxContainerStyle={{paddingTop: '48px'}}/>
               </div>
               </div>
               <div style={{top: '28px'}} className={style.divider}/>
            </div>
            </Scrollbars>
            {transferButton}
            </div>
        );
    }

    return (
        uploadFinishedViewType === 'email' ? renderMailCompletionView() : renderLinkCompletionView()
    );
}