
/* eslint-disable react/react-in-jsx-scope */
import style from './uploadExpiredView.module.css'
import { useEffect } from 'react'

export default function UploadExpiredView({transferButton})  {
    return (
        <div style={{height: '100%',overflow: 'auto'}}>
            <div className={style.container}>
        <div className={style.expiredIcon}>
          <img src="/images/sad-icon.jpg" alt="expired"></img>
           </div>
           <div className={style.summaryTitle}>
           <p> Oh oh, it seems like this upload has expired :(</p> 
           <p> Don't worry, we've got your back!</p>
           <p> Simply click the button below for a new upload</p>
           </div>
           {transferButton}
           </div>
        </div>
    );
};