/* eslint-disable react/react-in-jsx-scope */
import style from './tab.module.css'

export default function Tab({text, icon, isActive, onClick, tabStyle, selectedTabClassName})  {
    return (
      <div onClick={onClick} className={isActive ? selectedTabClassName ? style[selectedTabClassName] : style.selectedTab : style.tab} style={tabStyle} tabIndex={0}>
        {icon}
        {text}
      </div>
    );
};