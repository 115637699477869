
/* eslint-disable react/react-in-jsx-scope */
import style from './upsellCTA.module.css'
import CloseIcon from'./closeIcon'
import ExternalLinkIcon from './externalLinkIcon'
import utils from './utils'
import consts from './consts'

import SpaceShipIcon from './spaceShipIcon'
import { useEffect } from 'react'

export default function UpsellCTA({show, onClose, children, reason, linkUrl, text, openInNewTab, hideCloseIcon,
  customIcon, buttonStyle})  {

  function onCtaClick() {
    onClose && onClose();
  }

  useEffect(() => {
    utils.trackEvent(consts.TRACKING_CATEGORY.UPSELL, 'tooltip-shown','reason', reason);
  }, [])

  function renderCta() {
    return (
      <div className={style.ctaContent}>
      {customIcon ? customIcon : <SpaceShipIcon/>}
      <div className={style.textWrapper}>
        {children}
      <div className={style.linkWrapper}>
      <a onClick={onCtaClick} style={{...{textDecoration: 'none'}, ...buttonStyle}} className={style.ctaLink} href={linkUrl} target={openInNewTab && "_blank"} rel={openInNewTab && "noopener noreferrer"}>
        <div style={{display: 'flex'}}>
        {openInNewTab && <ExternalLinkIcon styleOverride={{height:'24px', width: '24px', left: '10px', position: 'absolute',
         top: '55%', transform: 'translateY(-50%)'}}/>}
         <span className={style.ctaLinkText}>
        {text}
        </span>
        </div>
        </a>
      </div>
      </div>
      </div>
    );
  }

  return (
    show && <div className={style.container}>
      {!hideCloseIcon && <CloseIcon onClick={onClose} iconStyle={{
          width: "20px", 
          height: "20px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '10px',
          marginRight: '10px',
          zIndex: 10
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>}
        {renderCta()}
      </div>
  ); 
}