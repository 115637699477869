
/* eslint-disable react/react-in-jsx-scope */
import style from './customizationCTA.module.css'
import CloseIcon from'./closeIcon'
import ExternalLinkIcon from './externalLinkIcon'
import utils from './utils'
import consts from './consts'

import { useStateValue } from '../state';

export default function CustomizationCTA({show, onClose})  {
  const [
    {},
    dispatch,
  ] = useStateValue();

  function onCtaClick() {
    utils.setSessionStorageItem(consts.SESSION_STORAGE_KEYS.JUMP_TO_EDITOR, true);
    onClose && onClose();
  }

  function renderCta() {
    return (
      <div className={style.ctaContent}>
      <span className={style.ctaTitle}>Your Brand,</span>
      <span style={{paddingLeft: '4px'}} className={style.ctaTitle}> Everywhere.</span>
      <span className={style.ctaText}>Customize everything and give your<br/>clients a delightful sharing experience</span>
      <div className={style.linkWrapper}>
      <span className={style.linkNote}>* This won't affect the current upload</span>
      <a onClick={onCtaClick} style={{textDecoration: 'none'}} className={style.ctaLink} href={'/posts/signup/?source=tooltip-cta'} target="_blank" rel="noopener noreferrer">
        <div style={{display: 'flex'}}>
        <ExternalLinkIcon styleOverride={{height:'24px', width: '24px', left: '10px', position: 'absolute',
         top: '55%', transform: 'translateY(-50%)'}}/>
         <span className={style.ctaLinkText}>
        Add your brand
        </span>
        </div>
        </a>
      </div>
      </div>
    );
  }

  return (
    show && <div className={style.container}>
      <CloseIcon onClick={onClose} iconStyle={{
          width: "20px", 
          height: "20px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '10px',
          marginRight: '10px',
          zIndex: 10
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>
       {renderCta()}
      </div>
  ); 
};