/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react';

import style from './quotaUpgradeMessage.module.css'
import utils from './utils'
import consts from './consts';

import { useStateValue } from '../state';

export default function QuotaUpgradeMessage({extendedStyle, applyEmbeddedStyle})  {
  const [fillerProgress, updateFillerProgress] = useState(0);

  const [
    {user},
    dispatch
  ] = useStateValue();

  useEffect(() => {
    if(user && user.monthlyTransferCount && user.monthlyTransfersLimit) {
      let fillerProgress = (user.monthlyTransferCount / user.monthlyTransfersLimit) * 100;
      updateFillerProgress(fillerProgress)
    }
  }, [user])


  useEffect(() => {
    if(fillerProgress >= 100) {
      utils.trackEvent(consts.TRACKING_CATEGORY.UPSELL, 'limit-reached-shown','reason', 'limit-reached');
    }
  }, [fillerProgress])

  function renderBellIcon() {
    return (
    <svg className={style.bellIcon} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.08528 3.27843C7.08528 2.74045 7.29899 2.22452 7.67939 1.84411C8.0598 1.46371 8.57574 1.25 9.11371 1.25C9.65168 1.25 10.1676 1.46371 10.548 1.84411C10.9284 2.22452 11.1421 2.74045 11.1421 3.27843C12.3069 3.82917 13.2998 4.68649 14.0144 5.75848C14.7291 6.83048 15.1386 8.07675 15.199 9.36371V12.4063C15.2753 13.0369 15.4986 13.6407 15.8509 14.1692C16.2033 14.6977 16.6747 15.136 17.2274 15.449H1C1.55268 15.136 2.02416 14.6977 2.37648 14.1692C2.72879 13.6407 2.9521 13.0369 3.02843 12.4063V9.36371C3.08883 8.07675 3.49831 6.83048 4.21298 5.75848C4.92764 4.68649 5.92055 3.82917 7.08528 3.27843" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.07129 15.4492V16.4634C6.07129 17.2704 6.39185 18.0443 6.96246 18.6149C7.53306 19.1855 8.30697 19.5061 9.11393 19.5061C9.92089 19.5061 10.6948 19.1855 11.2654 18.6149C11.836 18.0443 12.1566 17.2704 12.1566 16.4634V15.4492" stroke="#FAFAFA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    );
  }

  function getMessage() {
    return !applyEmbeddedStyle ? `You’ve hit your monthly limit of ${user.monthlyTransfersLimit} transfers.` :
    <span style={{fontSize: '12px', textAlign: 'center'}}>
    You’ve used up all your transfers, get <br/> more transfers and upgrade your plan.
    </span>
  }

  function getCta() {
   return (
     applyEmbeddedStyle ?
     <React.Fragment>
    <a href={"/posts/pricing/?source=transfers-limit-reached-cta&upgrade=true"} className={style.limitReachedUpgradeLink} target="_blank" rel="noopener noreferrer">
    Upgrade now
    </a>
     </React.Fragment>  :
     <React.Fragment>
     <a href={"/posts/pricing/?source=transfers-limit-reached-cta&upgrade=true"} className={style.limitReachedUpgradeLink} target="_blank" rel="noopener noreferrer">
     Upgrade now
     </a>
     <span style={{marginLeft: '0.4%'}} className={style.limitReachedText}>
   {'to unlock unlimited transfers.'}
     </span>
      </React.Fragment>  
   )
  }

  function renderLimitReachedMessage() {
    return (
      <div style={extendedStyle} className={style.limitReachedMessageContainer}>
        {!applyEmbeddedStyle && renderBellIcon()}
          <span style={applyEmbeddedStyle && {textAlign: 'center'}} className={style.limitReachedText}>
        {getMessage()}
          </span>
        {getCta()}
      </div>
    )
  }

  function renderUpgradeMessage() {
    let nextResetDate = new Date(user.nextResetDate);
    const resetMonth = nextResetDate.toLocaleString('default', { month: 'short', day: 'numeric' });
    return (
      <div style={extendedStyle} className={style.upgradeMessageContainer}>
        <div className={style.transfersLeftContainer}>
          <div className={style.textFlexWrapper}>
          <span style={applyEmbeddedStyle && {fontSize: '12px'}} className={style.upgradeMessageText}>
        {`${user.monthlyTransferCount} / ${user.monthlyTransfersLimit}  Transfers completed`}
          </span>
          <span style={applyEmbeddedStyle && {fontSize: '10px'}} className={style.resetDate}>
          {`Resets on ${resetMonth}`}
          </span>
          </div>
          <div className={style.progressBar}>
            <div style={{width: `${fillerProgress}%`}} className={fillerProgress >= 75 ? style.progressFillerRed : fillerProgress >= 50 ? style.progressFillerYellow : style.progressFiller}/>
          </div>
        </div>
          <button onClick={() => window.open('/posts/pricing/?source=trial-upgrade-cta&upgrade=true')} className={style.upgradeButton}>Unlock unlimited transfers</button>
      </div>
    );
  }

    return (
     fillerProgress < 100 ?  renderUpgradeMessage()
      : renderLimitReachedMessage()
    );
}