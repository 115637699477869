/* eslint-disable react/react-in-jsx-scope */

export default function EmailVerifiedIcon({iconStyle, width, height})  {
    return (
<svg style={iconStyle} xmlns="http://www.w3.org/2000/svg" width={width || "41"} height={height || "31"} viewBox="0 0 67 51" fill="none">
<path d="M53.0412 19.9046V5.66711C53.0412 2.54203 50.5379 0 47.4588 0H5.58377C2.50465 0 0 2.54203 0 5.66711V34C0 37.1251 2.50465 39.6671 5.58377 39.6671H36.8866C38.7161 46.1975 44.6322 51 51.6463 51C60.1116 51 67 44.0088 67 35.4171C67 27.3023 60.8548 20.6245 53.0412 19.9046ZM5.58377 2.83289H47.4588C47.5164 2.83289 47.5661 2.86344 47.6223 2.86609L27.6689 19.4902C26.9583 19.9458 25.9311 19.8488 25.4679 19.562L5.4215 2.86609C5.47777 2.86344 5.52619 2.83289 5.58377 2.83289ZM36.3619 36.8329H5.58377C4.04486 36.8329 2.79254 35.5619 2.79254 34V5.66711C2.79254 5.26602 2.88021 4.8875 3.02939 4.54086L23.8203 21.845C24.633 22.3829 25.566 22.6671 26.5226 22.6671C27.4412 22.6671 28.3389 22.4041 29.1306 21.9061C29.2091 21.8636 29.285 21.8118 29.3557 21.752L50.0158 4.53953C50.1649 4.88617 50.2526 5.26469 50.2526 5.66578V19.9046C42.439 20.6231 36.2938 27.3009 36.2938 35.4158C36.2912 35.8952 36.32 36.3667 36.3619 36.8329ZM51.6463 48.1671C44.7186 48.1671 39.0838 42.4482 39.0838 35.4171C39.0838 28.386 44.7186 22.6671 51.6463 22.6671C58.574 22.6671 64.2088 28.386 64.2088 35.4171C64.2088 42.4482 58.5727 48.1671 51.6463 48.1671Z" fill="#13BCB4"/>
<path d="M57.6383 30.1657L48.8537 39.0814L45.6529 35.8328C45.1072 35.279 44.2239 35.279 43.6795 35.8328C43.1338 36.3867 43.1338 37.2831 43.6795 37.8356L47.867 42.0856C48.1392 42.3619 48.4965 42.5014 48.8537 42.5014C49.2109 42.5014 49.5682 42.3632 49.8404 42.0856L59.6117 32.1685C60.1573 31.6147 60.1573 30.7182 59.6117 30.1657C59.066 29.6132 58.184 29.6119 57.6383 30.1657Z" fill="#13BCB4"/>
</svg>
    );
};
