/* eslint-disable react/react-in-jsx-scope */

export default function DownloadIconCircle({color, iconStyle, href, onClick, fileName, width, height})  {
    return (
    <svg id='DownloadIconCircle' style={iconStyle} xmlns="http://www.w3.org/2000/svg" width={width || "20"} height={height || "20"} viewBox="0 0 34 34" fill="none">
        <a id={`download_link_${fileName}`} href={href} onClick={onClick} download={fileName}>
        <circle cx="17" cy="17" r="17" fill={color}/>
        <path d="M17.002 24.655V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 19.3245L17 24.6536L10 19.3245" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </a>
    </svg>
    );
};
