/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import style from './addFilesForm.module.css'
import { Scrollbars } from 'react-custom-scrollbars';
import { useEffect } from 'react';

export default function AddFilesForm({addFilesView, mailDetailsView, transferButton, scrollableDivRef, extendedStyle, scrollableContentHeight, disableScroll, isEmbedded})  {
    useEffect(() => {
        if(isEmbedded) { //a hack to handle windows css bugs
            //document.getElementById('root-scroll').style.overflow = 'hidden'; 
            //document.getElementById('scrollBarDiv').style.overflowY = isEmbedded? 'auto' : 'scroll'; 
          }
    })

    let scrollBarId = disableScroll ? 'disabledScrollBarDiv' : 'scrollBarDiv';
    return (   
       /* <div> 
        <Scrollbars
        renderView={props => (<div id={scrollBarId} {...props} style={{ ...props.style, width:'280px', borderRadius: '19px' }} />)}
        thumbSize={100} universal style={{ width: 280, height: scrollableContentHeight, overflow: 'hidden', borderRadius: 19 }} ref = {scrollableDivRef}>
        <div style={{borderRadius: '19px'}}>
            <div style={extendedStyle} className={style.container}>
                {addFilesView}
                {mailDetailsView}
            </div>
        </div>
        </Scrollbars>
        {transferButton}
        </div>*/
        <React.Fragment>
        <Scrollbars
        renderView={props => (<div id={scrollBarId} {...props} style={{ ...props.style, width:'280px', height: scrollableContentHeight, overflow:disableScroll ? 'hidden' : 'auto', borderRadius: '19px' }} />)}
        thumbSize={100} universal
        autoHide hideTracksWhenNotNeeded autoHideTimeout={1}
        autoHideDuration={50}
        style={{ width: 280, height: scrollableContentHeight, overflow: 'hidden', borderRadius: 19 }}
        ref = {scrollableDivRef}>
        <div style={extendedStyle} className={style.container}>
            {addFilesView}
            {mailDetailsView}
        </div>
        </Scrollbars>
        {transferButton}
        </React.Fragment>

    );
}