/* eslint-disable react/react-in-jsx-scope */
import styles from './footer.module.css'
import Link from 'next/link'

export default function Footer({show, linkStyle}) {
    return (
      show && <div className={styles.container}>
        <div className={styles.innerContainer}>
        <Link href="/posts/terms">
            <a style={{...linkStyle, paddingBottom: '14px'}} className={styles.footerLink}>Terms of service</a>
            </Link>
            <Link href="/posts/privacy">
            <a style={linkStyle} className={styles.footerLink}>Privacy policy</a>
            </Link>
        </div>
   </div>
    )
  }