
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { useEffect, useState } from 'react';
import style from './emailVerified.module.css'
import EmailVerifiedIcon from './emailVerifiedIcon'
import CloseIcon from'./closeIcon'
import LoadingView from './loadingview';

import { useStateValue } from '../state';
import HeartIcon from './heartIcon';

export default function EmailVerified({show, onClose, email, showQuestionOnly})  {
  const [shouldShowThankYou, updateShouldShowThankYou] = useState(false);

  const [
    {},
    dispatch,
  ] = useStateValue();

  function onTransferQuestionAnswered(transferType) {
    if(transferType != "") {
      updateShouldShowThankYou(true);
    }
    dispatch({ type: 'TRANSFER-TYPE-CHANGED', payload: transferType}); 
  }

    useEffect(() => {
      if(shouldShowThankYou) {
        let timeout = setTimeout(onClose, 2000);
        return () => {
          clearTimeout(timeout);
      }
      }
  },[shouldShowThankYou])

  function renderEmailVerifiedWithQuestion() {
    return (
      <React.Fragment>
      <div className={style.header}>
      <div className={style.headerTitle}>
      <EmailVerifiedIcon iconStyle={{marginRight: '12.5px'}}/>
      <span className={style.EmailVerifiedText}>Email Verified</span>
      </div>
    </div>
    {renderQuestion()}
      </React.Fragment>
    );
  }

  function renderQuestion() {
    return (
    <LoadingView onUploadFinished={(transferType) => onTransferQuestionAnswered(transferType)} loadingViewStyle={{paddingTop: '17px', height: '0'}}/>
    );
  }

  function renderThankYou() {
    return (
      <div className={style.emailVerifiedContainer}>
      <HeartIcon iconStyle={{display: 'block', marginRight: 'auto', marginLeft: 'auto', marginBottom: '18px'}}/>
      <span className={style.EmailVerifiedText}>Thank You!</span>
      </div>
    );
  }

  function renderEmailVerified() {
    return (
      <div className={style.emailVerifiedContainer}>
      <EmailVerifiedIcon width={"65"} height={"49"} iconStyle={{display: 'block', marginRight: 'auto', marginLeft: 'auto', marginBottom: '18px'}}/>
      <span className={style.EmailVerifiedText}>Email Verified</span>
      <span className={style.verifiedEmail}>{email}</span>
      </div>
    );
  }

  function handleOnClose() {
    onTransferQuestionAnswered("");
    onClose && onClose();
  }

  return (
    show && <div className={style.container}>
      <CloseIcon onClick={handleOnClose} iconStyle={{
          width: "20px", 
          height: "20px",
          cursor: "pointer",
          position: 'absolute',
          top: '0px',
          right: '0px',
          marginTop: '10px',
          marginRight: '10px',
          zIndex: 10
       }} svgStyle={{position: 'relative', left: '0.5px', top: '0.5px'}}/>
       {shouldShowThankYou ? renderThankYou() : showQuestionOnly ? renderQuestion() : renderEmailVerifiedWithQuestion()}
      </div>
  ); 
};