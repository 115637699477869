/* eslint-disable react/react-in-jsx-scope */
import style from './loadingView.module.css'
import { useEffect, useState } from 'react'
import SelectionChip from './selectionChip'
import ForwardIcon from './forwardIcon'

export default function LoadingView({onUploadFinished, loadingViewStyle}) {
    var [mockRemainingTime, updateMockRemainingTime] = useState(10);
    const [transferType, updateTransferType] = useState("");
    const TRANSFER_TYPE_PERSONAL = 'personal';
    const TRANSFER_TYPE_WORK = 'work';
    let timeout;

  /*  const SimulateMailSending = () => {
        if(mockRemainingTime > 0){
            updateMockRemainingTime(--mockRemainingTime);
        }
    }

    useEffect(() => { 
    timeout = setTimeout(SimulateMailSending,1000);
    return () => {
        clearTimeout(timeout);
    }
    });

    useEffect(() => {
        if(mockRemainingTime === 0) {
          onUploadFinished(transferType);
        }
    }, [mockRemainingTime]);*/

      useEffect(() => {
        if(transferType != '') {
            let waitBeforeContinueTimer = setTimeout(handleOnSkipClick,1000);
            return () => {
                clearTimeout(waitBeforeContinueTimer);
            }
        }
    }, [transferType]);

    function handlePersonalClicked() {
        updateTransferType(TRANSFER_TYPE_PERSONAL);
    }

    function handleWorkClicked() {
        updateTransferType(TRANSFER_TYPE_WORK);
    }

    async function handleOnSkipClick() {
        clearTimeout(timeout);
        onUploadFinished && await onUploadFinished(transferType);
    }

    return (
        <>
        <div style={loadingViewStyle} className= {style.container}>
          <img className={style.questionImage} src="/images/helpUsHearts.svg" alt="loading"></img>
           <div className={style.loadingStatus}>
              <p>
              {`Will continue automatically in ${mockRemainingTime} seconds`}
              </p>
           </div>
           <div className={style.loadingTitle}>
           <p style={{height: '14px'}}>
             Help us improve
               </p>
               <p className={style.questionText}>
               Is this a work or a <br/> personal transfer?
               </p>
           </div>
           <div className={style.selectionChips}>
            <SelectionChip text = 'Personal' isClicked = {transferType === TRANSFER_TYPE_PERSONAL} onClick = {handlePersonalClicked}/>
            <SelectionChip text = 'Work' isClicked = {transferType === TRANSFER_TYPE_WORK} onClick = {handleWorkClicked}/>
           </div> 
           {false && <div style={{opacity: transferType != '' ? '1' : '0'}} className={style.thankYou}>
                Thank you!
           </div>}
           {false && <div style={{display: transferType != '' ? 'none' : 'flex'}} onClick={handleOnSkipClick} id='skip-question' className={style.skip}>
            <label style={{marginRight: '2px', cursor: 'pointer'}}> Skip </label>
            <ForwardIcon styleOverride={{position: 'relative', top: '2.5px', cursor: 'pointer'}} iconStyle={{width: '12px', height: '12px'}}/>
             </div>}  
        </div>
        </>
    );
};