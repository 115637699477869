
/* eslint-disable react/react-in-jsx-scope */
import style from './mainPage.module.css'
import consts from './consts'
import QuotaUpgradeMessage from './quotaUpgradeMessage';

export default function MainPage({form, toolTip, showPoweredBy,  children, showTitle,
  containerClass, homePageWrapperClass, centerContentClass, showUpgradeMessage, header,
  footer, applyEmbeddedStyle, userName})  {

  function renderPoweredBy() {
    return (
     <div className={style.poweredByContainer}>
        <span className={style.poweredByText}>Powered By FileDriver.com</span>
        <a href={`${window.location.origin}/?source=${consts.ORGANIC_SOURCES.SHARE_FILES_LINK}`} className={style.poweredByLink}>Share your files</a>
     </div>

    );
  }

  return (
    <div className={containerClass ? style[containerClass] : applyEmbeddedStyle ? style.homePageContainerEmbedded : style.homePageContainer} id='home-page-container'>
      {header}
      {showUpgradeMessage && <QuotaUpgradeMessage applyEmbeddedStyle={applyEmbeddedStyle}/>}
      <div className={homePageWrapperClass ? style[homePageWrapperClass]: style.homePageWrapper}>
        <div className={style.formWrapper}>
          <div className={style.mainContentOuterWrapper}>
            {form}
            {showPoweredBy && renderPoweredBy()}
          </div>
        {toolTip}
        </div>
        <div className={centerContentClass ? style[centerContentClass] : style.centerContent}>
        {showTitle && <span className={style.pageTitle}>Welcome, {userName}!</span>}
        {children}
        </div>
      </div>
      {footer}
  </div>
      );
}