/* eslint-disable react/react-in-jsx-scope */
import FloatingLabelInput from './floatingLabeInput'
import FloatingLabeTextArea from './floatingLabeTextArea'
import style from './mailDetails.module.css'
import {useState, useRef, useEffect} from 'react'
import Chip from './chip'
import utils from './utils'
import TRACKING_CATEGORY from './consts'
import RecipientList from './recipientList'
import consts from './consts'
import DisabledOverlay from './disabledOverlay'

import { useStateValue } from '../state';

export default function MailDetails({emailFrom, updateEmailFrom, emailTo, updateEmailTo, message, updateMessage, items, updateItems})  {
    const listRef = useRef(null);
    const emailToInputRef = useRef(null);
    const emailFromInputRef = useRef(null);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [isEmailToBlur, updateIsEmailToBlur] = useState(false);
    const [isEmailFromBlur, updateIsEmailFromBlur] = useState(true);
    const [focusOnEmailFromFirst, updateFocusOnEmailFromFirst] = useState(false);
    const [totalRecipientsLimit, updateTotalRecipientsLimit] = useState(null);

    const textRowCount = message ? message.split('\n').length : 0 + 1;
    
    const [
      {focusSenderEmail, formData, senderEmail, formFooterViewState, user, defaultLimits, currentActiveTab, codeVerificationData, validationTooltip},
      dispatch,
    ] = useStateValue();

    const scrollToBottom = () => {
        if(listRef.current){
            listRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
  useEffect(() => {

    //let cachedEmailFrom = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_FROM);
    let cachedEmailRecipients = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_RECIPIENTS);
    let cachedEmailMessage = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_MESSAGE);
    //cachedEmailFrom && updateEmailFrom(cachedEmailFrom);
    cachedEmailRecipients && updateItems(cachedEmailRecipients);
    cachedEmailMessage && updateMessage(cachedEmailMessage);
    if( cachedEmailRecipients && cachedEmailRecipients.length > 0) {
      emailFromInputRef && emailFromInputRef.current.focus();
      updateFocusOnEmailFromFirst(true);
    } 
    else {
      //emailToInputRef && emailToInputRef.current.focus(); TODO: Uncomment if we want to have auto-focus on form again
    }
     const handleKeyDown = event => {
      const { key } = event
      if (["Tab"].includes(key) && !isEmailToBlur) {
       updateIsEmailToBlur(true);
      }
    };
     document.addEventListener('keydown', handleKeyDown);
     return () => {
         document.removeEventListener('keydown', handleKeyDown)
       }
  }, [])

    //useEffect(scrollToBottom, [items]);

    useEffect(() => {
      if(focusSenderEmail) {
        updateIsEmailFromBlur(false); 
        emailFromInputRef && emailFromInputRef.current.focus();
      }
    },[focusSenderEmail]);

    useEffect(() => {
      let userPackageLimit = user && user.enabledFeatures && user.enabledFeatures.find(f => f.name === consts.FEATURE_NAMES.RECIPIENTS_NUMBER_LIMIT);
      let totalRecipientsLimit = userPackageLimit && userPackageLimit.value|| defaultLimits && defaultLimits.recipientsNumber;
      updateTotalRecipientsLimit(totalRecipientsLimit);
    }, [user, defaultLimits])

    useEffect(() => {
      if(emailFrom === "") {
        //emailFromInputRef && emailFromInputRef.current.focus(); TODO: Uncomment if we want to have auto-focus on form again
      }
    }, [emailFrom])

    function createChip(value){
      if (value && isValid(value)) {
        if(items.length >= totalRecipientsLimit) {
          dispatch({ type: 'UPSELL-TOOLTIP-CHANGED', payload: {show: true, reason: consts.UPSELL_REASONS.RECIPIENTS_NUMBER_EXCEEDED, currentLimit: totalRecipientsLimit}});
          updateEmailTo("");
          return;
        }
        updateItems([...items, value])
        updateEmailTo('');
        utils.trackRecipientAdded({user_email: emailFrom, recipient_email: value, is_work_email_user: utils.isWorkEmail(emailFrom), is_work_email_recipient: utils.isWorkEmail(value)})
        return true;
      }
      return false;
    }

    function createChips(addressesArray) {
     // if (value && isValid(value)) {
      let allowedAddresses = addressesArray.slice(0, totalRecipientsLimit);
      if(addressesArray.length > totalRecipientsLimit) {
        dispatch({ type: 'UPSELL-TOOLTIP-CHANGED', payload: {show: true, reason: consts.UPSELL_REASONS.RECIPIENTS_NUMBER_EXCEEDED, currentLimit: totalRecipientsLimit}});
      }
      let validatedAllowedAddresses = allowedAddresses.map(address => {
        utils.trackRecipientAdded({user_email: emailFrom, recipient_email: address, is_work_email_user: utils.isWorkEmail(emailFrom), is_work_email_recipient: utils.isWorkEmail(address)})
        return {address, isValid: isValid(address)}
      })
      updateItems([...items, ...validatedAllowedAddresses.filter(i => i.isValid).map(i => i.address)])
      updateEmailTo('');
      return true;
      //}
      //return false;
    }

    const handleOnDelete = (itemToDelete)=> {
        console.log(itemToDelete.item)
        console.log(items)
        let filteredArray = items.filter(  item => item !== itemToDelete.item);
        updateItems(filteredArray);
    }

    const handleEmailToKeyDown = evt => {
        if (["Enter", "Tab", ",", ";"].includes(evt.key)) {
          if(createChip(emailTo)) {
            evt.preventDefault();
            evt.stopPropagation();
          }
        }
      };

      const handleEmailToOnblur = evt => {
        createChip(emailTo);
      };

      const handleEmailFromKeyDown = evt => {
        var value = evt.target.value.trim();
        if (value && isValid(value)) {
          utils.trackEvent(TRACKING_CATEGORY.EMAIL_DETAILS, 'value_changed', 'email_from' ,value);
        }
      };

      const handleEmailFromOnBlur = evt => {
        updateFocusOnEmailFromFirst(false);
        dispatch({ type: 'FOCUS-SENDER-EMAIL-CHANGED', payload: false});
        updateIsEmailFromBlur(true)
        var value = emailFrom;
        let isValidEmail = isEmail(value);
        if(value != senderEmail.email) {
          dispatch({ type: 'SENDER-EMAIL-CHANGED', payload: {email: value, isValid: isValidEmail}}); 
          dispatch({ type: 'CODE-VERIFICATION-STATUS-CHANGED', payload: {...codeVerificationData, status: consts.CODE_VERIFICATION_STATUS.IDLE}});
        }
        if (value && isValidEmail) {
          let eventData = {user_email: value, is_work_email: utils.isWorkEmail(value)};
          console.log('user email added event data ' + JSON.stringify(eventData));
          utils.trackUserEmailAdded(eventData);
          utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.ENGAGED_USER);
        }
      };
    
      const isValid = (email) => {
        let error = null;
    
        if (isInList(email)) {
          error = `${email} has already been added.`;
        }
    
        if (!isEmail(email)) {
          error = `${email} is not a valid email address.`;
        }
    
        if (error) {
        //  this.setState({ error });
    
          return false;
        }
    
        return true;
      }
    
      const isInList = (email) => {
        return items.includes(email);
      }
    
      const isEmail = (email) => {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
      }

      function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    updateIsEmailToBlur(true);
                }
            }
    
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    function handleEmailFromOnChange(e) {
      updateEmailFrom(e.target.value);
      dispatch({ type: 'FORM-DATA-CHANGED', payload: {...formData, emailFrom: e.target.value}}); 
    }

    function parseEmails(emails, separator) {
      let addressesArray = [];
      let addresses;
      if(separator) {
         addresses = emails.split(separator);
      } else {
        addresses = [emails];
      }
      for (const address of addresses) {
        let startIndex = 0;
        let endIndex = address.length;
        if(address.includes('<')) {
          startIndex = address.indexOf('<')+1;
        } 
        if(address.includes('>')) {
          endIndex = address.indexOf('>');
        }
        let parsedAddress = address.substring(startIndex,endIndex);
        addressesArray.push(parsedAddress);
      }
      createChips(addressesArray);
      //updateEmailTo("");
    }

    async function onEmailToChange(e) {
      
      if(e.target.value.includes(';')) {
          parseEmails(e.target.value, ';')
      } else if(e.target.value.includes(',')) {
        parseEmails(e.target.value, ',')
      } else if(e.target.value.includes('<') || e.target.value.includes('>')) {
          parseEmails(e.target.value)
      } else {
        updateEmailTo(e.target.value)
      }
    }

    return (
        <div>
        {formFooterViewState !== consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD && <DisabledOverlay overlayStyle={{height: `${162 + textRowCount * 24}px`}}/>}
        <div className={style.userDetails}>
        <div ref={wrapperRef} style={{display: ((isEmailToBlur) && items && items.length > 0) || currentActiveTab.name === consts.TAB_NAMES.Generate_Link ? 'none' : 'block'}} id='recipientsDiv'>
           <ul className={style.chipsList}>  
           {items.map(item => ( <Chip key={item} item={item} onDelete={(item) => handleOnDelete(item)}/>))}
           <div ref={listRef} />
          </ul>
          <FloatingLabelInput
            name = "emailTo"
            value = {emailTo}
            onChange={onEmailToChange}
            label= "Email To"
            onKeyDown={handleEmailToKeyDown}
            hideLabelOnBlur = {true}
            onBlur = {handleEmailToOnblur}
            extendInputStyle={{border: validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_RECIPIENTS_EMAIL ? '1.49207px solid #FF3B4D' : 'unset', 
            marginTop: validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_RECIPIENTS_EMAIL && '1px',
            width: validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_RECIPIENTS_EMAIL && '250px',
            borderBottom: validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_RECIPIENTS_EMAIL ? '1.49207px solid #FF3B4D' : '1px solid rgb(0, 0, 0, 0.15)', minHeight: '46px'}}
            onFocus = {() => {
              updateIsEmailToBlur(false);
            }}
            isBlur = {isEmailToBlur}
            inputRef = {emailToInputRef}
            onWrapperClick = {() => emailToInputRef && emailToInputRef.current.focus()}
            floatingLabelInputStyle={{display: currentActiveTab.name === consts.TAB_NAMES.Generate_Link && 'none'}}
        />
        </div>
        <RecipientList onClick = {() => {
          updateIsEmailToBlur(false);
        }} recipients = {items} extendedStyle={{display: ((isEmailToBlur) && items && items.length > 0) && currentActiveTab.name !== consts.TAB_NAMES.Generate_Link ? 'flex' : 'none'}}/>
        <FloatingLabelInput
            name = "emailFrom"
            value = {emailFrom}
            onChange={handleEmailFromOnChange}
            label = "Your Email"
            onKeyDown={handleEmailFromKeyDown}
            extendInputStyle={{border: validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_USER_EMAIL && emailFrom === '' ? '1.49207px solid #FF3B4D' : !isEmailFromBlur && focusSenderEmail ? '1.42296px solid #13BCB4' : 'none', width: (!isEmailFromBlur && focusSenderEmail || validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_USER_EMAIL && emailFrom === '') ? '250px' : '100%', borderBottom: validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_USER_EMAIL && emailFrom === '' ? '1.49207px solid #FF3B4D' : !isEmailFromBlur && focusSenderEmail ? '1.42296px solid #13BCB4' : '1px solid rgb(0, 0, 0, 0.15)', paddingLeft: (!isEmailFromBlur && focusSenderEmail || validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_USER_EMAIL && emailFrom === '') ? '11px' : '0px' , minHeight: '46px'}}
            isBlur = {isEmailFromBlur}
            hideLabelOnBlur = {true}
            inputRef = {emailFromInputRef}
            onWrapperClick = {() => emailFromInputRef && emailFromInputRef.current.focus()}
            onBlur = {handleEmailFromOnBlur}
            onFocus = {() => updateIsEmailFromBlur(false)}
            hideLabelOnFocus={focusSenderEmail}
            floatingLabelInputStyle={{display: currentActiveTab.name === consts.TAB_NAMES.Generate_Link && 'none'}}
            readOnly={user && user.isLoggedIn}
        />
        <FloatingLabeTextArea
            name = "message"
            value = {message}
            onChange={e => updateMessage(e.target.value)}
            label = "Message"
            hideLabelOnBlur = {true}
            textRowCount={textRowCount}
            extendInputStyle={{height: currentActiveTab.name === consts.TAB_NAMES.Generate_Link && '110px'}}
        />
        </div>
        </div>
    );
}