/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import consts from './consts'
import style from './codeVerificationResend.module.css'
import BackIcon from './backIcon'

import { useStateValue } from '../state';

export default function CodeVerificationResend({show, onBack, onButtonClick, onInit, onGetLink})  {
   
    const [
        {senderEmail},
        dispatch,
      ] = useStateValue();

    useEffect(() => {
        onInit();
    }, []);

    function onGetLinkClick() {
        dispatch({ type: 'ACTIVE-TAB-CHANGED', payload: consts.TAB_NAMES.Generate_Link });
        onGetLink && onGetLink();
    }

    function renderLinkIcon() {
        return(
        <svg className={style.linkIcon} width="14" height="14" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.17975 13.936C9.56734 14.3316 10.03 14.6458 10.5405 14.8604C11.0511 15.0749 11.5994 15.1854 12.1532 15.1854C12.707 15.1854 13.2552 15.0749 13.7658 14.8604C14.2764 14.6458 14.739 14.3316 15.1266 13.936L19.8841 9.1785C20.6727 8.38989 21.1157 7.32032 21.1157 6.20507C21.1157 5.08981 20.6727 4.02024 19.8841 3.23164C19.0955 2.44303 18.0259 2 16.9107 2C15.7954 2 14.7258 2.44303 13.9372 3.23164L13.3426 3.82632" stroke="#13BCB4" stroke-width="2.41033" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.936 9.1784C13.5485 8.78282 13.0858 8.46855 12.5753 8.25401C12.0647 8.03946 11.5164 7.92896 10.9626 7.92896C10.4088 7.92896 9.86055 8.03946 9.34998 8.25401C8.83941 8.46855 8.37678 8.78282 7.98919 9.1784L3.2317 13.9359C2.44309 14.7245 2.00006 15.7941 2.00006 16.9093C2.00006 18.0246 2.44309 19.0942 3.2317 19.8828C4.0203 20.6714 5.08987 21.1144 6.20513 21.1144C7.32038 21.1144 8.38996 20.6714 9.17856 19.8828L9.77324 19.2881" stroke="#13BCB4" stroke-width="2.41033" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        );
    }

    function renderGetLink() {
        return (
            onGetLink && 
            <div className={style.getLink}>
            {renderLinkIcon()}
            <span onClick={onGetLinkClick} className={style.getLinkText}>{"Get link instead"}</span>
            </div>
        );
    }

    return (
        <div className={show ? style.container : style.containerClosed}>
        <img className={style.emailSentImage} src="/images/emailSent.svg" alt="email"></img>
        {false && <BackIcon onClick={onBack} iconStyle={{position: 'relative', left: '4px'}} styleOverride={{position: 'relative', width: '24px', height: '24px', bottom: '60px', left: '10px', cursor: 'pointer'}}/>}
        <span className={style.verificationTitle}>Check your mail</span>
        <span className={style.verificationText}>Have we got this right? <br/> This is the email you’re <br/> using on FileDriver</span>
        <button disabled={!senderEmail.isValid} onClick={onButtonClick} className={senderEmail.isValid ? style.resendButton : style.resendButtonDisabled} tabIndex={0}>
            Resend
        </button>
        {renderGetLink()}
        </div>
    ); 
};