/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import styles from './mobileHeader.module.css'
import Link from 'next/link'
import utils from './utils'
import consts from './consts'

import { useStateValue } from '../state';
import { useState } from 'react';

export default function MobileHeader({headerStyle, openInNewTab, showExpendIcon, showCloseIcon}) {
  const [showInfoTooltip, updateShowInfoTooltip] = useState(false);
  const [
    {formResizeMode, formFooterViewState},
    dispatch
  ] = useStateValue();

    function renderInfoTooltip() {
      return (
        <div className={formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? styles.infoTooltipContainerWrapperMinimize : styles.infoTooltipContainerWrapperExpand}>
          <div className={styles.infoTooltipPointer}/>
          <div className={styles.infoTooltipContainer}>
        <span className={styles.infoTooltipText}>
          {formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED ? 'Expand' : 'Minimize'}
        </span>
      </div>
        </div>

      );
    }

    function onExpandHover() {
      updateShowInfoTooltip(true);
    }

    function renderCloseIcon() {
      return (
        <div onClick={() => {
          //let transferInProgress = formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS; //TODO: Add confirmation dialog if transfer is in progress (send message to extension)
          utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EXTENSION_OPENED, false, 7);
          parent.postMessage("close", "*");
          }} className={formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? styles.closeIconExpanded : styles.closeIconMinimized}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M1.54102 1.00073L13.9737 14.2112" stroke="#13BCB4" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M13.9746 1L1.5419 14.2105" stroke="#13BCB4" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
        </div>

        )
    }

    function onDragOver() {
      parent.postMessage("drag-over", "*")
    }

    function renderDragIcon() {
      return(
        <div onClick={onDragOver} onMouseUp={() => parent.postMessage("drag-leave", "*")} className={formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? styles.dragIconExpanded : styles.dragIconMinimized}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M6 2.98999C6 3.81842 6.67157 4.48999 7.5 4.48999C8.32843 4.48999 9 3.81842 9 2.98999C9 2.16156 8.32843 1.48999 7.5 1.48999C6.67157 1.48999 6 2.16156 6 2.98999ZM11 2.98999C11 3.81842 11.6716 4.48999 12.5 4.48999C13.3284 4.48999 14 3.81842 14 2.98999C14 2.16156 13.3284 1.48999 12.5 1.48999C11.6716 1.48999 11 2.16156 11 2.98999ZM7.5 9.48999C6.67157 9.48999 6 8.81842 6 7.98999C6 7.16156 6.67157 6.48999 7.5 6.48999C8.32843 6.48999 9 7.16156 9 7.98999C9 8.81842 8.32843 9.48999 7.5 9.48999ZM11 7.98999C11 8.81842 11.6716 9.48999 12.5 9.48999C13.3284 9.48999 14 8.81842 14 7.98999C14 7.16156 13.3284 6.48999 12.5 6.48999C11.6716 6.48999 11 7.16156 11 7.98999ZM7.5 14.49C6.67157 14.49 6 13.8184 6 12.99C6 12.1616 6.67157 11.49 7.5 11.49C8.32843 11.49 9 12.1616 9 12.99C9 13.8184 8.32843 14.49 7.5 14.49ZM6 17.99C6 18.8184 6.67157 19.49 7.5 19.49C8.32843 19.49 9 18.8184 9 17.99C9 17.1616 8.32843 16.49 7.5 16.49C6.67157 16.49 6 17.1616 6 17.99ZM12.5 14.49C11.6716 14.49 11 13.8184 11 12.99C11 12.1616 11.6716 11.49 12.5 11.49C13.3284 11.49 14 12.1616 14 12.99C14 13.8184 13.3284 14.49 12.5 14.49ZM11 17.99C11 18.8184 11.6716 19.49 12.5 19.49C13.3284 19.49 14 18.8184 14 17.99C14 17.1616 13.3284 16.49 12.5 16.49C11.6716 16.49 11 17.1616 11 17.99Z" fill="#13BCB4"/>
        </svg>
        </div>
      );
    }
    return (
      <React.Fragment>
         <div style={headerStyle} className={styles.nav}> 
         <div className={styles.headerContainer}>
           <Link href={'https://filedriver.com'}>
            <a target={openInNewTab && '_blank'} rel={openInNewTab && "noreferrer"} style={{fontSize: '28px', textDecoration: 'none'}} className={formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? styles.linkStyleExpanded : styles.linkStyle}>FileDriver</a>
          </Link>
          {showExpendIcon && 
          <div onMouseEnter={onExpandHover} onMouseOver={onExpandHover} onMouseLeave={() => updateShowInfoTooltip(false)} className={styles.expandContainer}>
            <div onClick={() => {
            dispatch({ type: 'FORM-RESIZE-MODE-CHANGED', payload: formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? consts.FORM_RESIZE_MODE.MINIMIZED : consts.FORM_RESIZE_MODE.EXPANDED});
            parent.postMessage("expand", "*")
        }} className={formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? styles.minimize : styles.expand}/>
        {showInfoTooltip && renderInfoTooltip()}
          </div>
        }
        {showCloseIcon && renderCloseIcon()}
        {false && renderDragIcon()}
         </div>

         </div>
      </React.Fragment>
    )
  }