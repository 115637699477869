/* eslint-disable react/react-in-jsx-scope */
import style from './chip.module.css'
import {useState} from 'react'
import CloseIcon from './closeIcon'

export default function Chip({item, onDelete})  {
  const [showCloseIcon, updateShowCloseIcon] = useState(false);
  const handleOnMouseEnter = () => {
    updateShowCloseIcon(true);
    }
  const handleOnMouseLeave = () => {
    updateShowCloseIcon(false);
  } 
  const handleOnClickClose = (e) => {
    onDelete({item});
    e.preventDefault();
    e.stopPropagation();
}
    return (
      <div>
        <li onMouseOver={handleOnMouseEnter} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={style.item} key={item}>
        {item}
        <div onClick={handleOnClickClose}>
         <CloseIcon iconStyle={{
                    width: "14px", 
                    height: "16px",
                    cursor: "pointer",
                    opacity: showCloseIcon ? 0.9 : 0,
                    zIndex: 1,
                    backgroundColor: '#d4d5d6',
                    borderRadius: '25px',
                    position: 'relative',
                    bottom: '22px',
                    outline: 'none',
                 }}/>   
         </div>
      </li>
      </div>
    );
};