import { useRef, useState } from 'react';
/* eslint-disable react/react-in-jsx-scope */
import style from './recipientList.module.css'
import MoreRecipientsChip from './moreRecipientsChip'

export default function RecipientList({onFocus, onBlur, onClick, recipients, extendedStyle = {}}) {
    const inputRef = useRef(null);
    const [isBlur, updateIsBlur] = useState(false);

    function handleOnFocus() { 
        updateIsBlur(false);
        typeof onFocus === 'function' && onFocus();
    }

    function handleOnBlur() {
        updateIsBlur(true);
        typeof onBlur === 'function' && onBlur();
    }

    function renderSingleRecipient() {
        return (
            <p style={{marginBottom: '-15px', minHeight: '46px'}}> {recipients[0]} </p>
        )
    }

    function renderMultipleRecipients() {
        return (
            <>
            <p style={{display: 'flex', maxHeight: '16px'}}> {recipients[0]} ,
            <MoreRecipientsChip numberOfRecipients = {recipients.length - 1}/>
            </p>
            </>
        )
    }

    return (
        <div onClick = {onClick} style={extendedStyle} id='recipients-list' className={style.recipientsList}>
            {recipients && recipients.length === 1 ? renderSingleRecipient() : renderMultipleRecipients()}
        </div>
        );
    }