
/* eslint-disable react/react-in-jsx-scope */
import style from './varificationForm.module.css'
import BackIcon from './backIcon'
import { useEffect } from 'react'

export default function VerificationForm({onBack, verificationCode, updateVerificationCode, isVerificationFailed, emailFrom, transferButton, onSendAgainClick})  {
    const INPUT_PLACE_HOLDER = "Enter Verification Code"

    useEffect(() => {
        history.pushState({}, '');
        window.onpopstate = function(event) {
         onBack();
        };
    })

    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        onSendAgainClick();
    }

    return (
        <div style={{overflow: 'hidden'}}>
        <div className={style.container}>
            <div style={{textAlign: 'center', marginTop: '10px'}}>
        <BackIcon onClick={onBack}/>
        <div className={style.lockStyle}>
        <img src="/images/lock.jpg" alt="lock"></img>
        </div>
            <div className={style.verifyEmailTitle}>
                <p> Verify Your Email</p>
            </div>
            <div className={style.varificationText}>
                <p>We've sent an email to <b>{emailFrom}</b> <br/> to verify it's your account</p>
                <p onClick={handleClick} className={style.sendAgainText}>Send email again?</p>
            </div>
            <div style={{opacity: isVerificationFailed ? 1 : 0}} className={style.errorText}>
                <p> Whoops, code seems to be wrong. 
                    <br/>
                    please try again or resend the email</p>
            </div>
            <input
                type="number"
                id= "verification_input"
                value={verificationCode}
                onChange={e => updateVerificationCode(e.target.value)}
                className={style.input}
                placeholder= {INPUT_PLACE_HOLDER}
                autoComplete='off'
                style={{paddingRight: verificationCode && verificationCode !='' ? '0px' : '22px'}}
                maxLength={6}
            />
            </div>
        </div>
        {transferButton}
        </div>
    ); 
};