/* eslint-disable react/react-in-jsx-scope */
import style from './customViewContent.module.css'
import consts from './consts'

export default function CustomViewContent({form, toolTip, showPoweredBy,  isDemo, children, header, footer})  {

  function renderPoweredBy() {
    return (
     <div className={style.poweredByContainer}>
        <span className={style.poweredByText}>Powered By FileDriver.com</span>
        <a href={`${window.location.origin}/?source=${consts.ORGANIC_SOURCES.SHARE_FILES_CUSTOM_BRAND_LINK}`} className={style.poweredByLink}>Share your files</a>
     </div>

    );
  }

  return (
    <div className={style.gridContainer}>
      {header}
    <div className={isDemo ? style.homePageContainer : style.homePageContainerFlex} id='home-page-container'>
      <div className={isDemo ? style.formWrapper : style.formWrapperFlex}>
      <div className={style.mainContentOuterWrapper}>
        {form}
      {showPoweredBy && renderPoweredBy()}
      </div>
      {toolTip}
      </div>
      {children}
  </div>
  {footer}
    </div>

      );
}