/* eslint-disable react/react-in-jsx-scope */
import styles from './footerDemo.module.css'
import Link from 'next/link'
import ExternalLinkIcon from './externalLinkIcon'

export default function FooterDemo({show, ctaHeader, ctaSubHeader, ctaLinkText, ctaLink}) {

    function renderCtaLink() {
    return (
      <a style={{textDecoration: 'none'}} className={styles.ctaLink} href={ctaLink} target="_blank" rel="noopener noreferrer">
        <div style={{display: 'flex'}}>
        <ExternalLinkIcon styleOverride={{height:'24px', width: '24px', left: '10px', position: 'absolute',
         top: '55%', transform: 'translateY(-50%)'}}/>
         <span className={styles.ctaLinkText}>
       {ctaLinkText}
        </span>
        </div>
        </a>
    );
  }

    return (
      show && <div className={styles.container}>
        <div className={styles.innerContainer}>
        <div className={styles.ctaHeaderContainer}>
        <span className={styles.ctaHeader}>{ctaHeader}</span>
        <span className={styles.ctaSubHeader}>{ctaSubHeader}</span>
        </div>
          {renderCtaLink()}
        </div>
   </div>
    )
  }