import { useRef, useState } from 'react';
/* eslint-disable react/react-in-jsx-scope */
import style from './floatinglabelInput.module.css'

export default function FloatingLabelInput({name, value, label, onChange, onFocus, onBlur, onKeyDown, extendLabelStyle = {}, extendInputStyle = {}, hideLabelOnBlur, hideLabelOnFocus, isBlur, inputRef, onWrapperClick, maxLength, floatingLabelInputStyle={}, readOnly}) {
   
    function handleOnFocus() {
        typeof onFocus === 'function' && onFocus();
    }

    function handleOnBlur() {
        typeof onBlur === 'function' && onBlur();
    }

    return (
        <div style={{...floatingLabelInputStyle, cursor: 'text'}} onClick={onWrapperClick} id="float-label">
            <div id='inner-label-input'>
            <label style={{opacity: (hideLabelOnBlur && isBlur && value != '') || hideLabelOnFocus ? '0' : '0.4',height: hideLabelOnBlur && isBlur && value != '' ? '0px' : '1px', marginBottom: !isBlur ? '8px' : '0px', ...extendLabelStyle}} className={style.label}>
                {label}
            </label>
            <input
                id= {name}
                value={value}
                onChange={onChange}
                className={readOnly ? style.floatinglabelInputReadOnly : style.floatinglabelInput}
                onFocus={handleOnFocus}
                autoComplete="chrome-off"
                onKeyDown={onKeyDown}
                ref={inputRef}
                style={extendInputStyle}
                onBlur={handleOnBlur}
                maxLength={maxLength || 524288}
                readOnly={readOnly}
            />
            </div>
        </div>
);
                }