/* eslint-disable react/react-in-jsx-scope */
import style from './editTabsContainer.module.css'
import Tab from './tab'
import consts from './consts'
import ComputerIcon from './computerIcon'
import EmailIcon from './emailIcon'
import LinkIcon from './linkIcon'

import { useStateValue } from '../state';

export default function EditTabsContainer({children, tabGroupName, containerStyle, tabStyle={}, selectedTabClassName })  {
  const [
    {currentActiveTab, tabs},
    dispatch,
  ] = useStateValue();

    function updateActiveTab(tabName) {
      dispatch({ type: 'ACTIVE-TAB-CHANGED', payload: tabName });
    }

    function getTabIcon(tabName) {
      switch (tabName) {
        case consts.TAB_NAMES.Send_Email:
          return <EmailIcon iconColor={'#13BCB4'} iconStyle={{position: 'relative', top: '4px', marginRight: '6px'}}/>
        case consts.TAB_NAMES.Generate_Link:
            return <LinkIcon iconColor={'#13BCB4'} width={14} height={14} iconStyle={{position: 'relative', top: '2px', marginRight: '4px'}}/>
        case consts.TAB_NAMES.Brand_Your_Site:
          return <ComputerIcon iconStyle={{position: 'relative', top: '7px', marginRight: '6px'}}/>
        case consts.TAB_NAMES.Brand_Your_Email:
            return <EmailIcon iconStyle={{position: 'relative', top: '4px', marginRight: '6px'}}/>
        default:
          break;
      }
    }

    function getTabStyle(tabName) {
      switch (tabName) {
        case consts.TAB_NAMES.Send_Email:
          return {marginLeft: '20px'};
        default:
          break;
      }
    }

    return (
      <div style={containerStyle} className={style.container}>
        {tabs && tabs.map(({name, enabled, tabGroup}) => {return enabled && tabGroup === tabGroupName ? <Tab selectedTabClassName={selectedTabClassName} tabStyle={{...tabStyle, ...getTabStyle(name), position: 'relative', bottom: '1px'}} key={name} text={name} icon={getTabIcon(name)} onClick={() => updateActiveTab(name)} isActive={currentActiveTab && currentActiveTab.name === name} /> : null})}
         {children}
      </div>
    );
};