
/* eslint-disable react/react-in-jsx-scope */
import style from './transferStoppedView.module.css'

export default function TransferStoppedView()  {

    function renderClockIcon() {
        return (
        <svg className={style.clockIcon} width="92" height="104" viewBox="0 0 92 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.9013 15.6824V10.9562H57.3053C58.1646 10.9562 58.9165 10.2043 58.9165 9.34501V1.61121C58.9165 0.751897 58.1646 0 57.3053 0H38.5079C37.6486 0 36.8967 0.751897 36.8967 1.61121V9.34501C36.8967 10.2043 37.6486 10.9562 38.5079 10.9562H42.9118V15.6824C32.4927 16.864 23.2551 21.6976 16.2732 28.7869L14.4472 26.9609L17.4548 23.9533C18.0992 23.3088 18.0992 22.3421 17.4548 21.6976L11.2248 15.4676C10.5803 14.8231 9.61354 14.8231 8.96906 15.4676L0.483363 23.7385C-0.161121 24.383 -0.161121 25.3497 0.483363 25.9942L6.71337 32.2242C7.03561 32.5464 7.46527 32.6538 7.89492 32.6538C8.32458 32.6538 8.75423 32.5464 9.07647 32.2242L12.1915 29.1092L14.2323 31.15C7.78751 38.7764 3.92061 48.6585 3.92061 59.3999C3.92061 83.6754 23.6848 103.44 47.9603 103.44C72.2358 103.44 92 83.6754 92 59.3999C91.8926 36.843 74.8138 18.1529 52.9013 15.6824ZM7.89492 28.8943L3.92061 24.8126L9.93578 18.7974L13.9101 22.7717L11.547 25.1348C11.2248 25.4571 10.7951 25.8867 10.3654 26.3164L7.89492 28.8943ZM44.5231 7.7338H40.1191V3.22242H55.6941V7.7338H51.2901C50.4308 7.7338 49.6789 8.4857 49.6789 9.34501V15.4676C49.1419 15.4676 48.4974 15.4676 47.9603 15.4676C47.4232 15.4676 46.7787 15.4676 46.2417 15.4676V9.34501C46.1343 8.37828 45.3824 7.7338 44.5231 7.7338ZM47.8529 100.217C25.4034 100.217 7.03561 81.9568 7.03561 59.3999C7.03561 36.843 25.296 18.5826 47.8529 18.5826C48.7122 18.5826 49.5715 18.5826 50.4308 18.69C50.8605 18.69 51.2901 18.7974 51.7198 18.7974C72.4507 20.7309 88.6702 38.2393 88.6702 59.3999C88.6702 81.9568 70.4098 100.217 47.8529 100.217Z" fill="#13BCB4"/>
        <path d="M71.0543 32.9759C70.9469 32.8685 70.8394 32.7611 70.732 32.6537C64.6094 27.3904 56.6608 24.2754 47.9603 24.2754C28.5184 24.2754 12.8359 40.0652 12.8359 59.3997C12.8359 78.7342 28.5184 94.6315 47.8529 94.6315C67.1874 94.6315 82.9772 78.8416 82.9772 59.5071C83.0846 48.8732 78.3584 39.4207 71.0543 32.9759ZM47.8529 91.4091C30.237 91.4091 15.9509 77.123 15.9509 59.5071C15.9509 41.8913 30.237 27.6052 47.8529 27.6052C55.2644 27.6052 62.0315 30.1832 67.4022 34.3723L50.0011 54.3513C49.3567 54.1364 48.6048 53.9216 47.9603 53.9216C44.9527 53.9216 42.4822 56.3921 42.4822 59.3997C42.4822 62.4073 44.9527 64.8778 47.9603 64.8778C50.9679 64.8778 53.331 62.4073 53.331 59.3997C53.331 58.3256 53.0087 57.2515 52.4717 56.3921L69.9801 36.3057C76.1027 42.1061 79.8622 50.2695 79.8622 59.3997C79.8622 77.0156 65.4687 91.4091 47.8529 91.4091ZM50.1086 59.3997C50.1086 60.6887 49.1418 61.6554 47.8529 61.6554C46.5639 61.6554 45.5972 60.6887 45.5972 59.3997C45.5972 58.2182 46.5639 57.144 47.8529 57.144C49.1418 57.144 50.1086 58.2182 50.1086 59.3997Z" fill="#13BCB4"/>
        </svg>
        );
    }

    return (
        <div className={style.container}>
            {renderClockIcon()}
            <span className={style.text}>Transfer stopped by sender</span>
           </div>
    );
}