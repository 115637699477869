/* eslint-disable react/react-in-jsx-scope */
import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from 'next/router'

import Layout from '../components/layout'
import MainContent from '../components/mainContent'
import Header from '../components/header'
import {useDropzone} from 'react-dropzone'
import utilStyles from '../styles/utils.module.css'
import Footer from '../components/footer'
import restUtils from '../components/restUtils';
import Loader from '../components/loader';
import utils from '../components/utils';
import consts from '../components/consts'
import MobileHeader from '../components/mobileHeader'
import FooterDemo from '../components/footerDemo';
import TrialExpiredModal from '../components/trialExpiredModal';
import ExitIntent from '../components/exitIntent';
import InactiveSurveyModal from '../components/inactiveSurveyModal'
import EngagedUsersSurvey from '../components/engagedUsersSurvey';
import LoaderOverlay from '../components/loaderOverlay';
import * as Sentry from "@sentry/nextjs";

import { useStateValue } from '../state';
import NewExtensionPopup from "../components/newExtensionPopup";
import config from "../components/config";
import SiteHead from "../components/siteHead";

export default function Home({deviceType, decodedFiles, isUploadExpired, siteTemplateDTO, isDemoSite, uploadMessage, animatedDemo, isSafari, isEmbedded, newChromeInstall, signup, startUpload, uploadId, uploadCompleted}) {
  const router = useRouter();

  let customSiteContent = siteTemplateDTO && siteTemplateDTO.templateData;
  const isTransferInProgress = uploadId && uploadCompleted !== null;
  const isDownloadView = (decodedFiles && decodedFiles.length > 0) || isTransferInProgress;
  const isCustomView = isDownloadView && customSiteContent;
  const defaultBackgroundColor = isEmbedded ? "#252852" : "#181C45";

  const [droppedFiles, updateDroppedFiles] = useState([]);
  var [incrementalKey, updateIncrementalKey] = useState(0)
  const [userData, updateUserData] = useState(null);
  const [backgroundColor, updateBackgroundColor] = useState(isCustomView ? customSiteContent.backgroundColor : defaultBackgroundColor);
  const [templateData, updateTemplateData] = useState(customSiteContent);
  const [showInactiveSurvey, updateShowInactiveSurvey] = useState(false);
  //const backgroundColor = isCustomView ? customSiteContent.backgroundColor : defaultBackgroundColor;
  const complementaryColor = isCustomView && customSiteContent.complementaryColor;
  let requestFilesTimeout;

  const [
    {siteTemplate, user, showEngagedSurveyModal, formFooterViewState, formResizeMode, loading, defaultLimits},
    dispatch,
  ] = useStateValue()

  const onDrop = useCallback(acceptedFiles => {
    updateDroppedFiles(acceptedFiles);
  }, [])

  /*useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 5,
      eventThrottle: 10,
      maxDisplays: 1,
      onExitIntent: () => {
        let filesAdded = utils.getSessionStorageItem(consts.SESSION_STORAGE_KEYS.FILES_ADDED);
        if(!filesAdded && deviceType === consts.DEVICE_TYPES.DESKTOP && !isDownloadView && !isUploadExpired) {
          updateShowInactiveSurvey(true);
        }
      },
    })
    return () => {
      removeExitIntent()
    }
  })*/

  useEffect(() => {
    if(droppedFiles && droppedFiles.length >= 1 && isEmbedded && startUpload) {
      if(user.monthlyTransferCount >= user.monthlyTransfersLimit) {
        dispatch({ type: 'UPSELL-TOOLTIP-CHANGED', payload: {show: true, reason: consts.UPSELL_REASONS.TRANSFER_LIMIT_EXCEEDED, currentLimit: user.monthlyTransfersLimit}});
      } else {
        dispatch({ type: 'USER-CHANGED', payload: {...user, startUpload: true}});
      }
    }
  }, [droppedFiles])

  useEffect(() => {
    function requestFiles() {
      parent.postMessage('request-files', "*");
      requestFilesTimeout =  setTimeout(() => {
        if(droppedFiles.length < 1) {
          requestFiles();
        } else {
          clearTimeout(requestFilesTimeout);
        }
      }, 500)
    }

    window.scrollTo(0,0);
      if(user.isLoggedIn) {
          if(isEmbedded && startUpload && user.totalFilesSizeLimit && user.monthlyTransferCount >=0 && user.monthlyTransfersLimit && !user.startUpload && !checkIsTrialExpired()) {
            let eventDataDone = {from_step: 'gmail-action', to_step: 'auto-upload', user_email: '', is_work_email: false};
            utils.trackFormStepChanged(eventDataDone)
            initExtensionListeners(); 
            requestFiles();
          }
          utils.initUserLeap(user.userId, user.userEmail);
      } else if(user.initCompleted && isEmbedded && startUpload) {
        parent.postMessage('auto-transfer-started', '*');
        parent.postMessage("expand", "*");
      }
  }, [user])

    useEffect(() => {
      if(isEmbedded) {
        document.getElementById("__next").style.backgroundColor = '#252852';
        utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EXTENSION_OPENED, true, 1); //if we are in the extension onboarding page, let the page know that the user successfully opened the extension
        utils.setSessionStorageItem(consts.SESSION_STORAGE_KEYS.IS_EXTENSION_DROP_OFF, false);
      }
      async function getUser() {
          try {
            let userDTO = await restUtils.post('user');
            updateUserData(userDTO.data);
          } catch (error) {
              console.log('error while fetching user: ' + error);
              updateUserData("");
          }

      }
      let siteTemplateData = {};
      if(isCustomView) {
        siteTemplateData = {...{backgroundColor: backgroundColor, complementaryColor: complementaryColor, backgroundImage: null}};
      }
      dispatch({ type: 'SITE-TEMPLATE-CHANGED', payload: {...siteTemplate, ...siteTemplateData , isDemoTemplate: isDemoSite} })
      uploadId && dispatch({ type: 'UPLOAD-ID-CHANGED', payload: uploadId});
      uploadCompleted && dispatch({ type: 'UPLOAD-COMPLETED-CHANGED', payload: uploadCompleted});
      getUser();   
       let source = router.query['source'];
       if(source && (source === consts.ORGANIC_SOURCES.SHARE_FILES_LINK || source === consts.ORGANIC_SOURCES.SHARE_FILES_CUSTOM_BRAND_LINK)) {
        utils.trackEvent(consts.TRACKING_CATEGORY.ORGANIC_VISITS, 'user_visit','source', source);
       }
       setTimeout(() => {
         utils.reportHjUserAttributes();
         const getUserId = utils.userId();
         const userId = getUserId();
         Sentry.setUser({ id: userId });
         Sentry.setTag("userId", userId);
        }, 3000); //wait for cookie to be ready 
       window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
         utils.trackEvent(consts.TRACKING_CATEGORY.LOGGING, 'uncaught error', `${errorMsg}, url: ${url}, line number: ${lineNumber}`)
        return false;
    }
    if(!isEmbedded) {
      let isExtensionDropOff = utils.getSessionStorageItem(consts.SESSION_STORAGE_KEYS.IS_EXTENSION_DROP_OFF);
      if(isExtensionDropOff === 'true') {
        utils.trackUserLeapEvent('extension-drop-off');
        utils.setSessionStorageItem(consts.SESSION_STORAGE_KEYS.IS_EXTENSION_DROP_OFF, false);
      }
    }
    },[])

    useEffect(() => {
      async function GetUserData() {
        let userState = {};
        if(userData && userData != "" && userData.userId) {
          if(!(isDownloadView || isUploadExpired) && window.location.href.includes('landing')) {
            router.push('/'); 
          }
          let currentDate = new Date();
          let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          let isExtensionInstalled =  await utils.checkIfExtensionIsInstalled();
          utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EXTENSION_INSTALLED, isExtensionInstalled, 7);
          if(newChromeInstall) { 
            utils.trackEvent(consts.TRACKING_CATEGORY.EXTENSION, 'successfully_installed', 'install');
            utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.EXTENSION_INSTALLED);
            let onboardingType = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.ONBOARDING_TYPE);
            let onboardingUrl = onboardingType === consts.ONBOARDING_TYPES.EXTENSION_GMAIL ? 'gmail-redirect' : 'extension-onboarding';
            router.push(`/posts/onboarding/${onboardingUrl}`)
            //await completeProfile();
            //userData.completedOnboarding = true;
          }
          let userPackageLimit = userData && userData.enabledFeatures && userData.enabledFeatures.find(f => f.name === consts.FEATURE_NAMES.UPLOAD_SIZE_LIMIT);
          let monthlyTransfersLimit = userData && userData.enabledFeatures && userData.enabledFeatures.find(f => f.name === consts.FEATURE_NAMES.TRANSFERS_PER_MONTH_LIMIT);
          let totalFilesSizeLimit = userPackageLimit && userPackageLimit.value;

          userState = {...{isLoggedIn: true, userId: userData.userId, userName: userData.userName,
            userEmail: userData.userEmail, hasPublishedTemplate: userData.hasPublishedTemplate, enabledFeatures: userData.enabledFeatures,
            planInfo: userData.planInfo, isPaying: userData.isPaying, completedOnboarding: userData.completedOnboarding,
            trialInfo: {onTrial: userData.planInfo.isPaidPlan && !userData.isPaying, trialExpired: new Date(userData.planInfo.trialEndDate).getTime() < currentDate.getTime(), daysRemain: Math.round(Math.abs((new Date(userData.planInfo.trialEndDate).getTime() - currentDate.getTime()) / (oneDay)))},
            isExtensionInstalled: isExtensionInstalled, totalFilesSizeLimit: totalFilesSizeLimit,monthlyTransferCount: parseInt(userData.monthlyTransferCount),
            monthlyTransfersLimit: parseInt(monthlyTransfersLimit && monthlyTransfersLimit.value), nextResetDate: userData.nextResetDate
          }};
            utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.IS_LOGGED_IN, true, 365);
            utils.setSessionStorageItem(consts.SESSION_STORAGE_KEYS.USER_EMAIL, userData.userEmail);
            userState.initCompleted = true;
            let isNewSignup = utils.getSessionStorageItem(consts.SESSION_STORAGE_KEYS.NEW_SIGNUP);
            if(isNewSignup === 'true') {
              await restUtils.post('sendNewSignupEmail', {userEmail: userData.userEmail, userName: userData && userData.userName && userData.userName.split(" ")[0]}, true);
              utils.setSessionStorageItem(consts.SESSION_STORAGE_KEYS.NEW_SIGNUP, false);
            }
        } else if (userData === "") {  
         /* let mockState = generateMockUserState();
          userState = mockState;*/
          userState.totalFilesSizeLimit = defaultLimits && defaultLimits.uploadLimitInBytes;    
          utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.IS_LOGGED_IN, null, 365); //make sure the state is correct when the server returns an empty response (i.e. the user is logged out)
          utils.removeSessionStorageItem(consts.SESSION_STORAGE_KEYS.USER_EMAIL);
          if(deviceType === consts.DEVICE_TYPES.DESKTOP && !(isDownloadView || isUploadExpired) && !signup && !isTransferInProgress) {
            window.location.assign(`/landing/trustLp/${newChromeInstall ? '?newChromeInstall=true' : ''}`);
          } else if(isEmbedded) {
            router.push('/posts/login/?embedded=true');
            //window.location.assign('/posts/login/?embedded=true');
          }
          userState.initCompleted = true;
        }
        userState.deviceType = deviceType;
        userState.isUsingSafari = isSafari;
        dispatch({ type: 'USER-CHANGED', payload: userState})
      }
      async function completeProfile() {
        dispatch({ type: 'LOADING-CHANGED', payload: true}); 
        await restUtils.post('completeOnboarding', null, true, config.NETWORK_REQUEST_TIME_OUT);
        dispatch({ type: 'LOADING-CHANGED', payload: false}); 
      }
      GetUserData();

    },[userData])

    useEffect(() => {
      function animateSiteTemplateChange() {
        let colorIndex = 0;
        let titlesIndex = 0;
        let descriptionIndex = 0;
        let oneLinerIndex = 0;
        let coverImageIndex = 0;
        let circleColors = ["#F5BE58", "#F8746B", "#71D2F1"];
        let colorPalette = {
          "#181C45": {
            complementaryColor: "#13BCB4",
          },
          "#F8746B": {
            complementaryColor: "#AE504B",
          },
          "#71D2F1": {
            complementaryColor: "#439BC0",
          },
          "#24B47E": {
            complementaryColor: "#197E68",
          },
          "#F5BE58": {
            complementaryColor: "#AB7E3E",
          },
          "#8C9BE8": {
            complementaryColor: "#645DBB",
          },
          "#373737": {
            complementaryColor: "#323232",
          },
        }
        let titles = ["ACME MEDIA", "ACME LAW OFFICE", "ACME TRAVEL"];
        let descriptions = ["Music & Sound Design", "Giving you advice that matters", "Dream destinations"];
        let oneLiners = ["Unique music for games and media.", "Solutions for entrepreneurs and small businesses", "You only live once. Make it count."];
        let coverImages = ["guitars", "nyc", "ocean"];
        return function animateChange() {
            let currentColor = circleColors[colorIndex++ % circleColors.length];
            let imageName = coverImages[coverImageIndex++ % coverImages.length];
            let title = titles[titlesIndex++ % titles.length];
            let description = descriptions[descriptionIndex++ % descriptions.length];
            let oneLiner = oneLiners[oneLinerIndex++ % oneLiners.length];
            updateBackgroundColor(currentColor);
            updateTemplateData({...templateData, title: title,
             description: description, oneLiner: oneLiner, coverImage: `/images/${imageName}PreDefinedCoverPhoto.jpg`});
            let siteTemplateData = {...{backgroundColor: currentColor, complementaryColor: colorPalette[currentColor].complementaryColor, backgroundImage: null}};
            dispatch({ type: 'SITE-TEMPLATE-CHANGED', payload: {...siteTemplateData , isDemoTemplate: true} });
        }
      }
      if(isDemoSite && animatedDemo) {
        let executeAnimation = animateSiteTemplateChange();
        executeAnimation();
        let timeout = setInterval(executeAnimation,5000); 
        return () => {
            clearInterval(timeout);
        }
      }
      },[isDemoSite, animatedDemo])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true, noDragEventsBubbling: true, key: {incrementalKey}})

  //An ugly hack to clear Dropzone's cache
  // https://github.com/Yuvaleros/material-ui-dropzone/issues/9
  useEffect(() => {
    updateIncrementalKey(++incrementalKey);
  }, [droppedFiles]);  

  function generateMockUserState() {
    const getUserId = utils.userId();
    const userId = getUserId();
    let enabledFeatures = [{"name":"downloads_number_limit","type":"limited_feature","value":"10"},{"name":"file_retention_days","type":"limited_feature","value":"7"},{"name":"recipients_number_limit","type":"limited_feature","value":"100"},{"name":"upload_size_limit_bytes","type":"limited_feature","value":"2147483648"},{"name":"transfers_per_month_limit","type":"limited_feature","value":"4"}];
    let planInfo = {"packageId":1,"trialEndDate":null,"isPaidPlan":false};
    let userState = {...{isLoggedIn: true, userId: userId, userName: 'test user',
      userEmail: 'test@email.com', hasPublishedTemplate: false, enabledFeatures: enabledFeatures,
      planInfo: planInfo, isPaying: false, completedOnboarding: true,
      trialInfo: {onTrial:false,trialExpired:true,daysRemain:18864},
      isExtensionInstalled: false, totalFilesSizeLimit: 2147483648,monthlyTransferCount: 2,
      monthlyTransfersLimit: 4, nextResetDate: '2021-09-22T21:00:00.000Z'
    }};
    return userState;
  }

  function initExtensionListeners() {
    console.log('initExtensionListeners');
    var eventMethod = window.addEventListener
    ? 'addEventListener'
    : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    
    eventer(messageEvent, function (e) {
    // if (e.origin !== 'http://the-trusted-iframe-origin.com') return;
          var eventMethod = window.addEventListener
            ? 'addEventListener'
            : 'attachEvent';
          var eventer = window[eventMethod];
          var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    
          eventer(messageEvent, function (e) {
            // if (e.origin !== 'http://the-trusted-iframe-origin.com') return;
            if ((e.data && e.data.message && e.data.message === 'request-files-response') || (e.message && e.message.message && e.message.message === 'request-files-response')) {
                requestFilesTimeout && clearTimeout(requestFilesTimeout);
                updateDroppedFiles(e.data.files)
                //parent.postMessage('request-files-completed', "*");
                //onDrop([e.data.files])
            }
          });
    });
  }

  function renderPoweredBy() {
    return (
     <div className={utilStyles.poweredByContainer}>
        <span className={utilStyles.poweredByText}>Powered By FileDriver.com</span>
        <a href={`${window.location.origin}/?source=${consts.ORGANIC_SOURCES.SHARE_FILES_CUSTOM_BRAND_LINK}`} className={utilStyles.poweredByLink}>Share your files</a>
     </div>

    );
  }

  function checkIsTrialExpired() {
    /****currently we have no time-based trial, uncomment this code to bring back the check*****/
    return false;
    //return user && user.trialInfo && user.trialInfo.onTrial && user.trialInfo.trialExpired && !isDownloadView
  }

  function renderHeader(shouldShowLayout, customLinkStyle) {
    return(
      <React.Fragment>
      {shouldShowLayout && <Layout hide={isDragActive || (isEmbedded && userData === null)}  home extendedStyle={{backgroundColor: isEmbedded && "#252852"  ,paddingRight: deviceType === consts.DEVICE_TYPES.MOBILE && 'unset',
      borderBottom: (!isCustomView || isEmbedded) && '1px solid #434571',
      height: deviceType === consts.DEVICE_TYPES.MOBILE ? isEmbedded && (formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS || formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.CANCEL_UPLOAD) && formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED ? '100%' : 'unset' : 'unset',
      display: deviceType === consts.DEVICE_TYPES.MOBILE ? 'flex' : 'block', justifyContent: 'center', marginBottom: isEmbedded ? formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED ? '12%' : '4%' : 'unset'}} isCustomView={isCustomView}>
      {deviceType === consts.DEVICE_TYPES.MOBILE ? <MobileHeader showCloseIcon={isEmbedded} openInNewTab={isEmbedded}
      headerStyle={{marginRight: (isEmbedded && formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED) && '21px'}}
      showExpendIcon={isEmbedded && (formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS || formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.CANCEL_UPLOAD)}/>
      : <Header linkStyle={customLinkStyle} isCustomView={isCustomView}/>}
    </Layout>}
      </React.Fragment>
    );
  }

  function renderBody(trialExpired, header, footer) {
    return (
      <div id='mainWrapper' className={utilStyles.mainWrapper}>
      <div className={utilStyles.root} style={{backgroundColor}} {...getRootProps({})}>
     <input {...getInputProps()} />
     <div style={{height: '100%', display: isDragActive ? "flex" : "none"}} className={utilStyles.dropZone}>
     <img src="/images/upload_file.jpg" alt="uploadFile"></img>
     <h3> drop your files here</h3>
     </div>
      <div style={{display: isDragActive ? 'none' : 'unset', height: '100%'}}>
      {renderModalsIfNeeded(trialExpired)}
      {loading && <LoaderOverlay LoaderOverlayStyle={{zIndex: '2000'}}>
     <span className={utilStyles.loaderText}>{'Completing your profile...'}</span>
     <span className={utilStyles.loaderTextSubHeadline}>We will be ready in a few moments, please don't close this page.</span>
     </LoaderOverlay>}
   {newChromeInstall && false && <NewExtensionPopup/>}
   { !user.initCompleted ? <Loader color={complementaryColor}/> :
   !trialExpired && <MainContent droppedFiles={droppedFiles} updateDroppedFiles = {updateDroppedFiles} decodedFiles = {decodedFiles}
   isUploadExpired = {isUploadExpired} deviceType={deviceType} customSiteContent={templateData}
   uploadMessage = {uploadMessage} isDemoSite={isDemoSite} isEmbedded={isEmbedded} isExpired={isUploadExpired}
   signup={signup} header={header} footer={footer} uploadId={uploadId} uploadCompleted={uploadCompleted}
   />}
   </div>
   {isCustomView && !isDemoSite && userData !== null && renderPoweredBy()}
   </div>
   </div>
    )
  }

  function renderModalsIfNeeded(trialExpired) {
    return(
      <React.Fragment>
      {trialExpired && <TrialExpiredModal isEmbedded={isEmbedded}/>}
      {showEngagedSurveyModal && config.SHOW_ENGAGED_SURVEY && <EngagedUsersSurvey onClose={() => dispatch({ type: 'SHOW-ENGAGED-SURVEY-MODAL-CHANGED', payload:false})}/>}
      {showInactiveSurvey && <InactiveSurveyModal surveyId={'hp'} onClose={() => updateShowInactiveSurvey(false)}/>}
      </React.Fragment>
    )
  }

  function renderFooter(customLinkStyle) {
    return (
      <React.Fragment>
    {isDemoSite ? <FooterDemo show={true}
    ctaHeader={animatedDemo ? 'Ready to add your own brand?' : 'Your branded page preview'}
    ctaSubHeader={animatedDemo ? 'Customize what your clients will see with just a few clicks' : 'This is what your recipients will see '}
    ctaLinkText={animatedDemo ? 'Start Now' : 'Drive Files'}
    ctaLink = {animatedDemo ? '/posts/signup/?type=work&source=live-demo-cta' : '/'}
    /> : !isEmbedded && <Footer show={!isCustomView} linkStyle={customLinkStyle}/>}
      </React.Fragment>
    );
  }
   
   const renderMainLayout = () => {
     let customLinkStyle = isCustomView ? {color: 'white'} : {};
     let shouldShowLayout = !isCustomView && !isDemoSite;
     let trialExpired = checkIsTrialExpired();
     let header = renderHeader(shouldShowLayout, customLinkStyle);
     let footer = renderFooter(customLinkStyle);
     return (
       <React.Fragment>
      {renderBody(trialExpired, header, footer)}
       </React.Fragment>
     );
   }

  return (
    <SiteHead>
   {renderMainLayout()}
    </SiteHead>
  );
}

// This gets called on every request
export async function getServerSideProps(context) {
  const base64EncodedFilePreviews = context.query.filePreviews;
  const uploadId = context.query.uploadId;
  const userId = context.query.userId;
  const isDemoSite = context.query.demoSite || null;
  const animatedDemo = context.query.animatedDemo || null;
  const embedded = context.query.embedded || null;
  const newChromeInstall = context.query.newChromeInstall || null;
  const signup = context.query.signup || null;
  const startUpload = context.query.startUpload || null;

  let resDTO;
  if(isDemoSite) {
    let siteTemplate = await restUtils.post('getSiteTemplate', {userId: userId});
    resDTO = siteTemplate.data;
    resDTO.isExpired = false;
    resDTO.files = [{name:'File 1 (Demo)', size: 1024, url: '/demo'}, {name:'File 2 (Demo)', size: 1024, url: '/demo'}];
  }
   else if (base64EncodedFilePreviews && base64EncodedFilePreviews !='') {
      let decodedFiles = JSON.parse(Buffer.from(base64EncodedFilePreviews, 'base64').toString());
      resDTO = {isExpired: false};
      resDTO.files = decodedFiles;
    }
   else if(uploadId && uploadId !='') { //TODO: Add user id not empty validation, keeping this for backward compatibility until all links will have a user id
     //TODO: Validate userId as well
    const v4 = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
    let isValidUploadId = Boolean(uploadId.match(v4));
    if(isValidUploadId) {
      let res;
      if(userId && userId != '') {
         res = await restUtils.post('getPageData', {uploadId: uploadId, userId: userId});
      } else { //TODO: keeping this for backward compatibility, remove once the condition above regarding user Id validation will exists
         res = await restUtils.post('filesMetaData', {uploadId: uploadId});
      }
      resDTO = res.data;
    }
  }
  
  const UA = context.req.headers['user-agent'];
  let isMobile;
  if(UA) {
     isMobile = Boolean(UA.match(/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i) || UA.match(/\b(Android|Windows Phone|iPad|iPod)\b/i)) || embedded;
  }
  // Pass data to the page via props
  return { props: {
   deviceType: isMobile ? 'mobile' : 'desktop', //TODO: Handle in the UI when we decide to make a mobile flow
   decodedFiles: resDTO && !resDTO.error ? resDTO.files : [],
   uploadMessage: resDTO && !resDTO.error && resDTO.message ? resDTO.message : null,
   isUploadExpired: resDTO ? resDTO.isExpired : false,
   siteTemplateDTO: resDTO && resDTO.templatesDTO ? resDTO.templatesDTO : null,
   isDemoSite: isDemoSite,
   animatedDemo: animatedDemo,
   isSafari: /^((?!chrome|android).)*safari/i.test(UA),
   isEmbedded: embedded,
   newChromeInstall: newChromeInstall,
   signup: signup === 'true',
   startUpload,
   uploadId: uploadId ? uploadId : null,
   uploadCompleted: resDTO ? resDTO.completed : null
  // customSiteContent: {title: 'My Site.com', description: 'This is <br/> My Site', oneLiner: 'this is my oneLiner', backgroundColor: "#F8746B"}
  } }
}