import DownloadIconCircle from './downloadIconCircle';
/* eslint-disable react/react-in-jsx-scope */
import style from './fileDownloadItem.module.css'
import utils from './utils'
import consts from './consts'

import { useStateValue } from '../state';

export default function FileDownloadItem({fileName, fileSize, url}) {
    const [
        {siteTemplate, user},
        dispatch,
      ] = useStateValue()

    let linkUrl = siteTemplate.isDemoTemplate ? window.location.href : `${process.env.HOST}${url}`;
    async function handleDownloadClick() {
        let uri = url.replaceAll('%','/').split('/').slice(4,7).join('/').split('?')[0];
        let eventData = {
            file_name: fileName,
            file_size: fileSize !== null ? fileSize : 0,
            file_type: utils.getFileType(fileName),
            file_uri: uri,
        }
        utils.trackFileDownloaded(eventData)
    }
    return (
        <li className={url ? style.listItemStyle : style.listItemStyleInProgress} key={fileName}>
         <div style={{display: 'inline-flex', position: 'relative', top: '5px', width: 'inherit'}}>
         <p className={style.fileName}> {fileName} </p>
         <DownloadIconCircle width={user.deviceType === consts.DEVICE_TYPES.MOBILE && '30'} height={user.deviceType === consts.DEVICE_TYPES.MOBILE && '30'} color={siteTemplate.complementaryColor} fileName={fileName} href={linkUrl} onClick={handleDownloadClick} iconStyle={{position: 'relative', top: '15px', left: user.deviceType === consts.DEVICE_TYPES.MOBILE ? '15px' : '20px'}}/>
        </div>   
         <p className={style.fileSize}> {fileSize} </p>
        </li>
    )
  }