
/* eslint-disable react/react-in-jsx-scope */
import style from './resendVerificationView.module.css'
import BackIcon from './backIcon'
import { useEffect } from 'react'

export default function ResendVerificationView({onBack, updateEmailFrom, emailFrom, transferButton})  {
    const INPUT_PLACE_HOLDER = "Enter Your Email"

    useEffect(() => {
        history.pushState({}, '');
        window.onpopstate = function(event) {
         onBack();
        };
    })

    return (
        <div style={{overflow: 'hidden'}}>
        <div className={style.container}>
            <div style={{textAlign: 'center', marginTop: '10px'}}>
        <BackIcon onClick={onBack}/>
        <div className={style.lockStyle}>
        <img src="/images/lock.jpg" alt="lock"></img>
        </div>
            <div className={style.verifyEmailTitle}>
                <p> Is this your email?</p>
            </div>
            <div className={style.varificationText}>
                <p>Just making sure we have the right email. <br/> After you verified your email click the button bellow.</p>
            </div>
            <input
                type="text"
                id= "resend_input"
                value={emailFrom}
                onChange={e => updateEmailFrom(e.target.value)}
                className={style.input}
                placeholder= {INPUT_PLACE_HOLDER}
                autoComplete='off'
            />
            </div>
        </div>
        {transferButton}
        </div>
    ); 
};