/* eslint-disable react/react-in-jsx-scope */
import { useRef, useState } from 'react';
import style from './floatingLabeTextArea.module.css'

export default function FloatingLabeTextArea({name, value, label, onChange, onFocus, onBlur, onKeyDown, extendLabelStyle = {}, extendInputStyle = {}, hideLabelOnBlur, maxLength, textRowCount}) {
    const inputRef = useRef(null);
    const [isMessageBlur, updateIsMessageBlur] = useState(true);

    function handleOnFocus() {
        updateIsMessageBlur(false);
        typeof onFocus === 'function' && onFocus();
    }

    function handleOnBlur() {
        updateIsMessageBlur(true);
        typeof onBlur === 'function' && onBlur();
    }
    return (
        <div onClick={() => inputRef && inputRef.current.focus()} id="float-label">
            <label style={{opacity: hideLabelOnBlur && isMessageBlur && value != '' ? '0' : '0.4',height: hideLabelOnBlur && isMessageBlur && value != '' ? '0px' : '32px', ...extendLabelStyle}} className={style.label}>
                {label}
            </label>
            <textarea
                id= {name}
                value={value}
                onChange={onChange}
                className={style.floatingLabeTextArea}
                onFocus={handleOnFocus}
                autoComplete='off'
                onKeyDown={onKeyDown}
                ref={inputRef}
                style={extendInputStyle}
                onBlur={handleOnBlur}
                rows = {textRowCount}
                maxLength = {maxLength || 524288}
            />
        </div>
);
                }