/* eslint-disable no-undef */
let channels = [];
let receiverDataChannel = null;

function addChannel(dataChannel, clientId) {
   dataChannel.clientId = clientId;
   dataChannel.onclose = () => {
      console.log('channel closed detected, removing it from cache...')
      removeChannel(dataChannel);
   }
   channels.push(dataChannel);
   console.log('channels: ' + channels.length)
}

function removeChannel(channel) {
   console.log('removing data chanel')
   channels = channels.filter(i => i.clientId !== channel.clientId);
   console.log('channels: ' + channels.length)
}

function send(string, clientId) {
   let channel = channels.find(i => i.clientId === clientId);
   if(channel) {
      try {
         channel.send(string);
      } catch (error) {
         console.error(error)
      }
     
   } else {
      console.error('RTC data channel was not found for client id: ' + clientId);
   }
}

function broadcast(string) {
   try {
      for(let i=0; i < channels.length; i++) {
         let channel = channels[i];
         if(channel.readyState === 'open') {
            console.log(`sending data rtc channel: ${channel.clientId}. channel state:  ${channel.readyState}, message: ${string}`)
            channel.send(string);
         } else {
            if(channel.readyState === 'closed') {
               console.log('removing channel due to invalid ready state: ' + channel.readyState);
               removeChannel(channel);
            } else {
               console.log('skipping channel due to invalid ready state: ' + channel.readyState);
            }
         }
      }
   } catch (error) {
      console.error(error)
   }
}

function isInitialized() {
   return channels.length > 0;
}

function createListener(uploadId, connection, onMessageCb) {
   receiverDataChannel = connection.createDataChannel(`sendChannel_${uploadId}_${new Date().getTime()}`, {reliable: true});
   receiverDataChannel.onmessage = onMessageCb;
   //receiverDataChannel.onopen = () => handleSendChannelStatusChange('open');
   receiverDataChannel.onclose = () => {console.log('data channel close event received, clearing data channel'); receiverDataChannel = null;}
}

 export default {
   addChannel,
   removeChannel,
   send,
   broadcast,
   isInitialized,
   createListener
 }
