/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';

import style from './addBrandCta.module.css'
import consts from './consts'
import utils from './utils'
import { useStateValue } from '../state';
import AnimatedArrow from './animatedArrow';

export default function AddBrandCta({Url, onBrandSkipClick, CtaText, showArrow}) {

  useEffect(() => {
    utils.trackPageView('AddBrandCta');
  }, [])

  const [
    {},
  ] = useStateValue()

    return (
      <div className={style.addBrandCtaWrapper}>
      <div className={style.addBrandCtaContainer}>
        <div className={style.addBrandCtaContentWrapper}>
        <div className={style.addBrandCtaContent}>
          <a className={style.brandPreviewImage}  target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} href={Url}>
          <img src={'/images/brandCtaPreviewForm.png'}/>
          </a>
         <div className={style.addBrandCtaRight}>
         <span className={style.addBrandCtaTextBold}>Now, let’s create <br/> your download page!</span>
         <span className={style.addBrandCtaText}>Impress your recipients with <br/> your custom download page.<br/> 
         Choose your colors, add a <br/> cover photo and text and <br/> make it your own!</span>
         <div className={style.buttonContainer}>
         <a target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} href={Url} className={style.addBrandButton}>{CtaText || 'Create Your Page'}</a>
         {showArrow && <AnimatedArrow arrowStyle={{top: '263px', left: '207px'}}/>}
         </div>
         {false && <span onClick={onBrandSkipClick} className={style.skipBrand}>Skip this step</span>}
       </div>
        </div>
        </div>
      </div>
      </div>
    )
  }