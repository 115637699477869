/* eslint-disable react/react-in-jsx-scope */
import AddFilesForm from './addFilesForm'
import AddFilesView from './addFilesView'
import VerificationForm from './varificationForm'
import ResendVerificationView from './resendVerificationView'
import MailDetails from './mailDetails';
import style from './formContainer.module.css'
import previewStyle from './formContainerPreview.module.css'
import utils from './utils'
import { useState, useMemo, useEffect, useRef } from "react";
import LoadingView from './loadingview';
import UploadFinishedView from './uploadFinishedView'
import consts from './consts'
import restUtils from './restUtils'
import UploadExpiredView from './uploadExpiredView'
import NoConnectionView from './noConnectionView'
import TransferStoppedView from './transferStoppedView'
import TransferWaitingView from './transferWaitingView'
import { toast } from 'react-toastify';
import {useVerifyCode} from './useVerifyCode'
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from './animatedProgressProvider'
import { Line } from 'rc-progress';
import SelectionChip from './selectionChip'

import { useStateValue } from '../state';
import EditTabsContainer from './editTabsContainer';
import LinkBox from './linkBox';
import rtcUtils from './rtcUtils';

export default function FormContainer({droppedFiles, updateDroppedFiles, showForm, decodedFilesForDownload, isUploadExpired, previewModeActive, deviceType, isCustomView, uploadMessage, isEmbedded, embeddedUpsellCTA})  {
    const FILE_FORM_VIEW = "file-form";
    const DOWNLOAD_FORM_VIEW = "download-form";
    const VARIFICATION_VIEW = "varification-form"
    const RESEND_VERIFICATION_VIEW = "resend-varification-form"
    const LOADING_VIEW = "loading-form"
    const DONE_VIEW = "done-form"
    const UPLOAD_EXPIRED_VIEW = "expired-form"
    const NO_CONNECTION_VIEW = "no-connection-form"
    const RTC_TRANSFER_STOPPED_VIEW = "rtc-transfer-stopped-form"
    const RTC_TRANSFER_WAITING_VIEW = "rtc-transfer-waiting-form"

    const BUTTON_TEXT_VERIFY = "Verify"
    const BUTTON_TEXT_RESEND = "Resend"
    const BUTTON_TEXT_CANCEL_LOADING = "Cancel"
    const BUTTON_TEXT_ADD_MORE_FILES = "Drive more files"
    const BUTTON_TEXT_DOWNLOAD_ALL = "Download All"
    const BUTTON_TEXT_UPLOAD_EXPIRED = "Drive new files"
    const BUTTON_TEXT_NO_CONNECTION = "Let’s Try Again"
    const MIN_CODE_LENGTH = 6;

    const RTC_TRANSFER_STATUS_IN_PROGRESS = 'rtc-transfer-status-in-progress';
    const RTC_TRANSFER_STATUS_COMPLETED = 'rtc-transfer-status-completed';
    const RTC_TRANSFER_STATUS_STOPPED = 'rtc-transfer-status-stopped';
    const RTC_TRANSFER_STATUS_WAITING = 'rtc-transfer-status-waiting';
    const MAX_RTC_CONNECTIONS = 30;

    let wsConnection = null;
    let rtcConnections = [];
    let iceServers = null;
    let rtcSetupInfo = []; //[{clientId: '123', setupInProgress: false}]

    const [viewState, updateViewState] = useState(isUploadExpired ? UPLOAD_EXPIRED_VIEW : typeof decodedFiles === 'undefined' || decodedFiles && decodedFiles.length < 1 ? FILE_FORM_VIEW : DOWNLOAD_FORM_VIEW);
    const [selectedFiles, updateSelectedFiles] = useState([]);
    const [emailFrom, updateEmailFrom] = useState("");
    const [emailTo, updateEmailTo] = useState([]);
    const [emailMessage, updateMessage] = useState("");
    const [verificationCode, updateVerificationCode] = useState("");
    const [uploadProgressInPercentage, updateUploadProgressInPercentage] = useState(null);
    const [isUploadInProgress, updateIsUploadInProgress] = useState(false);
    const [currentUploadedFileName, updateCurrentUploadedFileName] = useState("");
    const [mbUploaded, updateMbUploaded] = useState(null);
    const  [fileSize, updateFileSize] = useState(0);
    const [isVerificationFailed, updateIsVerificationFailed] = useState(false);
    const [items, updateItems] = useState([]);
    const [completionEmailStatus, updateCompletionEmailStatus] = useState("");
    const [uploadListChanged, updateUploadListChanged] = useState(false);
    const [isVerificationInProgress, updateIsVerificationInProgress] = useState(false);
    const [isFileUploadPreparationInProgress, updateIsFileUploadPreparationInProgress] = useState(null);
    const [fileUploadCompletedName, updateFileUploadCompleted] = useState("");
    const [uploadErrorOccurred, updateUploadErrorOccurred] = useState(null);
    const [validFiles, updateValidFiles] = useState(null);
    const [allValidFilesUploaded, updateAllValidFilesUploaded] = useState(false);
    const [successfulUploads, updateSuccessfulUploads] = useState(null);
    const [downloadInProgress, updateDownloadInProgress] = useState(false);

    const [uploadCandidates, updateUploadCandidates] = useState(null);
    const [progressBarCompleted, updateProgressBarCompleted] = useState(false);
    const [totalBytesUploaded, updateTotalBytesUploaded] = useState(0);
    const [totalFilesSizeInBytes, updateTotalFilesSizeInBytes] = useState(0);
    const [startUploading, updateStartUploading] = useState(false);
    const [downloadLink, updateDownloadLink] = useState(null);
    const [batchUploadInProgress, updateBatchUploadInProgress] = useState(false);
    const [mockProgress, updateMockProgress] = useState(0);
    const [mockProgressUpdater, updateMockProgressUpdater] = useState(null);
    const [autoUploadStarted, updateAutoUploadStarted] = useState(false);
    const [rtcTransferData, updateRtcTransferData] = useState(null);
    const [decodedFiles, updateDecodedFiles] = useState(decodedFilesForDownload || {});
    

    const scrollableDivRef = useRef(null);

    const [
      {codeVerificationData, senderEmail, siteTemplate, user, formFooterViewState, transferType,
        currentActiveTab, productTutorial, formResizeMode, uploadId, uploadCompleted},
      dispatch,
    ] = useStateValue();

    useEffect(() => {
       // Currently the caching feature of uploaded files is disabled, unmark to add it back.
       /* let cachedUploadedFiles = utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.UPLOADED_FILES);
        cachedUploadedFiles && updateSelectedFiles(cachedUploadedFiles);
        cachedUploadedFiles != null && dispatch({ type: 'FILE-PREVIEW-ADDED', payload: {previewFiles: cachedUploadedFiles.map(i => {return {name: i.name, size: i.size, type: i.type , url:'/preview' }}), totalFiles: cachedUploadedFiles.length}}) */
        //showToast();
        let updater = utils.getMockProgress(2, updateMockProgress);
        updateMockProgressUpdater(updater);
  },[])

   /* useEffect(() => {
        let uniqueArray = utils.arrayUniqueNames(selectedFiles.concat(droppedFiles))
        uniqueArray && uniqueArray.length > 0 && updateSelectedFiles(uniqueArray)
    }, [droppedFiles])*/

    useEffect(() => {
      if(selectedFiles && selectedFiles.length > 0 && typeof selectedFiles[0] !== 'undefined') {
        let calculatedTotalBytesUploaded = selectedFiles.reduce((total, file) => total + utils.undefineIsZero(file.mbUploaded), 0 );
        updateTotalBytesUploaded(calculatedTotalBytesUploaded);
        let validFiles = selectedFiles.filter(i => isValidFile(i));
        updateValidFiles(validFiles);
        let totalFilesSize = validFiles.reduce((total, file) => total + file.size, 0);
        updateTotalFilesSizeInBytes(totalFilesSize);
        let uploadedFiles = selectedFiles.filter((file) => file.uploadCompleted === true && file.completedSuccessfully === true).map((file) => {return {mbUploaded: file.mbUploaded, name: file.name, size: file.size, uri: file.uri, uploadCompleted: file.uploadCompleted, uploadInProgress: file.uploadInProgress, uploadProgressInPercentage: file.uploadProgressInPercentage, isTooLarge: file.isTooLarge, uploadDuration: file.uploadDuration, rejected: file.rejected, completedSuccessfully: file.completedSuccessfully}});
        utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.UPLOADED_FILES, uploadedFiles, 6);
        let successfulUploads = selectedFiles && selectedFiles.filter(file => file.uploadCompleted === true && file.completedSuccessfully === true);
        let allValidFilesUploaded = successfulUploads && successfulUploads.length > 0 && successfulUploads.length === validFiles.length;
        updateSuccessfulUploads(successfulUploads);
        updateAllValidFilesUploaded(allValidFilesUploaded);
        let uploadCandidates = selectedFiles.filter((file) => file.uploadCompleted !== true && isValidFile(file));
        updateUploadCandidates(uploadCandidates);
      }
  }, [selectedFiles])

    useEffect(() => {
      if(viewState === DOWNLOAD_FORM_VIEW) {
        return;
      }
      scrollableDivRef.current && scrollableDivRef.current.scrollToBottom();
   }, [uploadListChanged])

    useEffect(() => {
      if(mbUploaded && selectedFiles && selectedFiles.length > 0 && typeof selectedFiles[0] !== 'undefined') {
        let currentFileUploading = selectedFiles.find((file) => file.name === mbUploaded.fileName);
        if(currentFileUploading) {
          currentFileUploading.mbUploaded = mbUploaded.mbUploaded;
          updateSelectedFiles([...selectedFiles, ...[]]);
      }
      } 
}, [mbUploaded])

    useEffect(() => {
  if(selectedFiles && selectedFiles.length > 0 && typeof selectedFiles[0] !== 'undefined') {
    let currentFileUploading = selectedFiles.find((file) => file.uploadInProgress === true);
    if(currentFileUploading) {
      currentFileUploading.uploadProgressInPercentage = uploadProgressInPercentage;
      updateSelectedFiles([...selectedFiles, ...[]]);
  }
  } 
}, [uploadProgressInPercentage])

    useEffect(() => {
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_FROM,emailFrom, 6);
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_RECIPIENTS, items, 6);
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_MESSAGE, emailMessage, 6);
},[items, emailFrom, emailMessage])

    useEffect(() => {
      if(selectedFiles && selectedFiles.length > 0 && typeof selectedFiles[0] !== 'undefined' && isFileUploadPreparationInProgress) {
        let currentFileUploading = selectedFiles.find((file) => file.name === isFileUploadPreparationInProgress.fileName);
        if(currentFileUploading) {
            currentFileUploading.preparingForUpload = isFileUploadPreparationInProgress.prepInProgress;
            updateSelectedFiles([...selectedFiles, ...[]]);
      }
  } 
    },[isFileUploadPreparationInProgress])

    useEffect(() => {
      async function completeSingleFileTransfer(uploadedFile) {
        let uploadId = getUploadIdFromDownloadLink();
        let fileMetadata = {name: uploadedFile.name, uri: uploadedFile.uri, size: uploadedFile.size, type: utils.getFileType(uploadedFile.name)};
        let res = await restUtils.post('completeSingleFileTransfer', {userId: user.userId, uploadId: uploadId, fileMetadata: fileMetadata});
        if(res && res.data) {
          console.log('single file transfer completed, file url: ' + res.data);
          uploadedFile.url = res.data;
          updateUploadProgressInPercentage({uploadProgress:0, fileName: fileUploadCompletedName});
          updateSelectedFiles([...selectedFiles, ...[]]);
        } else {
          console.error('Error on completeSingleFileTransfer, did not receive response from server');
          return;
        }

      }
      if(selectedFiles && selectedFiles.length > 0 && typeof selectedFiles[0] !== 'undefined') {
        let uploadedFile = selectedFiles.find((file) => file.name === fileUploadCompletedName);
        if(uploadedFile && uploadedFile.name === fileUploadCompletedName) {
          uploadedFile.uploadCompleted = true;
          uploadedFile.completedSuccessfully = true;
          uploadedFile.uploadInProgress = false;
          completeSingleFileTransfer(uploadedFile);
      }
    } 
    },[fileUploadCompletedName])

    useEffect(() => {
      if(uploadErrorOccurred && uploadErrorOccurred.errorOccurred === true) {
        if(selectedFiles && selectedFiles.length > 0 && typeof selectedFiles[0] !== 'undefined') {
          let currentFileUploading = selectedFiles.find((file) => file.name === uploadErrorOccurred.fileName);
          if(currentFileUploading) {
            //TODO: Support delete on server side as well, in the future we could stop and resume the upload and not delete the file
            const filteredSelectedFiles = selectedFiles.filter(file => file.name !== currentFileUploading.name)
            updateSelectedFiles(filteredSelectedFiles);
            updateDroppedFiles([]); //an ugly hack to clear the Dropzone cache 
            //currentFileUploading.uploadErrorOccurred = true;
            if(currentFileUploading.uploadInProgress && typeof currentFileUploading.cancelUpload == 'function') {
              currentFileUploading.cancelUpload();
            }
            updateUploadProgressInPercentage({uploadProgress:0, fileName: uploadErrorOccurred.fileName});
            //updateSelectedFiles([...selectedFiles, ...[]]);
            updateViewState(NO_CONNECTION_VIEW);
            let eventDataDone = {from_step: FILE_FORM_VIEW, to_step: NO_CONNECTION_VIEW, user_email: emailFrom, is_work_email: utils.isWorkEmail(emailFrom)};
            utils.trackFormStepChanged(eventDataDone);
        }
      }
  } 
  },[uploadErrorOccurred])
  
  useEffect(() => {
    if(user.isLoggedIn && emailFrom === "") {
      updateEmailFrom(user.userEmail);
      dispatch({ type: 'SENDER-EMAIL-CHANGED', payload: {email: user.userEmail, isValid: true}}); 
    }
},[user])

  useEffect(() => {
    switch (codeVerificationData.status) {
      case consts.CODE_VERIFICATION_STATUS.CODE_SENT:
        sendVerificationCode(codeVerificationData.forceResend, codeVerificationData.verificationMethod, codeVerificationData.userPhone);
        break;
      case consts.CODE_VERIFICATION_STATUS.IN_PROGRESS:
        updateIsVerificationInProgress(true);
        break;
      case consts.CODE_VERIFICATION_STATUS.SUCCESSFUL:
        updateIsVerificationInProgress(false);
        updateIsVerificationFailed(false);
        startUploadFiles();
        break;
      case consts.CODE_VERIFICATION_STATUS.FAILED:
        updateIsVerificationInProgress(false);
        updateIsVerificationFailed(true);
        break;
    }
  },[codeVerificationData])

  useEffect(() => {
    async function getSignedUrl(file) {
      const currentUTCDate = new Date().toISOString().slice(0,10); //returns UTC yyyy-mm-dd format
      const getUserId = utils.userId();
      const userId = getUserId();
      let uriName = `${currentUTCDate}/${userId}/${file.name}`
      let signedUrl = await restUtils.getSignedUrl(uriName, updateUploadErrorOccurred, 'files');
      file.signedUrl = signedUrl.data;
    }
    async function uploadBatch(batch) {
      const getUserId = utils.userId();
      const userId = getUserId();
      const currentUTCDate = new Date().toISOString().slice(0,10); //returns UTC yyyy-mm-dd format
      let uris = batch.map(file => {
        let uriName = `${currentUTCDate}/${userId}/${file.name}`; //take the name of the first file of the uri
        return uriName;
      })
      let signedUrls = await restUtils.getSignedUrl(uris, updateUploadErrorOccurred, 'files');
      if(signedUrls && signedUrls.data) {
        let fileUploads = batch.map(async (file, i) => {
          return new Promise((resolve, reject) => {
              // let file = uploadCandidates[0]; 
             if(file.isPaused) {
               file.resumeUpload();
             }else if(file.uploadInProgress !== true && isValidFile(file)){ 
               file.signedUrl = signedUrls.data[i];
               //updateCurrentUploadedFileName(file.name);
               //dispatch({ type: 'SHOW-SLIDING-PANEL-TOGGLE', payload: true}) 
               uploadFile(file, () =>resolve(), () => reject());
            /* let nextFile = uploadCandidates.length > 1 && uploadCandidates[1];
             if(nextFile && !nextFile.signedUrl) {
               getSignedUrl(nextFile);
             }*/
             } 
          })
        })
        let res = await Promise.all(fileUploads);
        return res;
      } else {
        updateUploadErrorOccurred(true);
      }

    }

    function sizeComparer( a, b ) {
      if ( a.size < b.size ){
        return -1;
      }
      if ( a.size > b.size ){
        return 1;
      }
      return 0;
    }

    async function createAndUpdateDownloadLink() {
      let filesMetadata = uploadCandidates.map(file => {return {name: file.name, uri: file.uri, size: file.size, type: utils.getFileType(file.name)}});
      const getUserId = utils.userId();
      const userId = getUserId();
      let downloadLink = await getDownloadLink(filesMetadata, userId, emailMessage);
      updateDownloadLink(downloadLink);
    }

    async function uploadFilesByBatches(batchSize) {
      updateBatchUploadInProgress(true);
      let sizeSortedUploadCandidates = uploadCandidates.sort(sizeComparer);
      let numberOfBatches = Math.ceil(sizeSortedUploadCandidates.length / batchSize);
      for (let i = 0; i < numberOfBatches; i++) {
        let startIndex = i === 0 ? 0 : endIndex + 1;
        let endIndex =  i === 0 ? Math.min(batchSize, sizeSortedUploadCandidates.length) : startIndex + batchSize - 1;
        let batch = sizeSortedUploadCandidates.slice(startIndex, endIndex);
        await uploadBatch(batch);
      }
     updateBatchUploadInProgress(false);
    }
    if(user.startUpload && !autoUploadStarted && uploadCandidates && uploadCandidates.length > 0) {
      startUploadFiles(true);
      updateAutoUploadStarted(true);
    }
    if(startUploading && !isUploadInProgress && !batchUploadInProgress) {
     if(uploadCandidates && uploadCandidates.length > 0){
      const batchSize = 20;
      generateMockProgress();
      createAndUpdateDownloadLink();
      uploadFilesByBatches(batchSize);
      }
    }
  },[uploadCandidates, startUploading, isUploadInProgress, user, autoUploadStarted])

  useEffect(() => {
    async function onUploadCompleted() {
      updateProgressBarCompleted(true);
      await handleFilesUploadCompleted();
    }
    if(allValidFilesUploaded && formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS && !uploadErrorOccurred) {
      onUploadCompleted();
     } 
  },[allValidFilesUploaded, formFooterViewState])

  useEffect(() => {
    if(transferType != null) {
      reportTransferType(transferType);
    }
  },[transferType])

  useEffect(() => {
    if(user && !user.isLoggedIn) {
      updateEmailFrom("");
      dispatch({ type: 'SENDER-EMAIL-CHANGED', payload: {email: "", isValid: false}}); 
    }
  },[currentActiveTab])

  useEffect(() => {
    if(productTutorial && productTutorial.show) {
      renderAnimatedArrow();
    }
  }, [productTutorial])

  useEffect(() => {
    async function executeOnboarding() {
      if(isEmbedded) {
        await utils.reportGoogleAnalyticsEvent('onboarding', 'extension_opened');
      }
      showTutorialStepsByState();
    }
    if(user.isLoggedIn && viewState !== DOWNLOAD_FORM_VIEW && !uploadId) {
      let completedTransfers = getCompletedTransfersFromLocalStorage();
      if(completedTransfers.count < 1) {
          executeOnboarding();
      }
    }
  }, [selectedFiles, items, currentActiveTab, formFooterViewState, user])

  useEffect(() => {
    if(mockProgress > 0 || totalBytesUploaded > 0 || embeddedUpsellCTA) {
      parent.postMessage('auto-transfer-started', '*');
    }
  }, [mockProgress, totalBytesUploaded, embeddedUpsellCTA])

  useEffect(() => {
    if(downloadLink) { //if this client is the sending part
      openSenderWs(); //open a sender web socket channel for WebRTC signaling 
      user.startUpload &&  utils.sendChromeExtensionMessage({message: 'download-link', value: downloadLink}) //update the extension with the generated link
    } else if(uploadId) { //if this client is the receiving part
      decodedFiles.length === 0 && openReceiverWs(); //If the transfer is still in progress, open a receiver web socket channel for WebRTC signaling 
      if(!uploadCompleted) {
        updateViewState(RTC_TRANSFER_WAITING_VIEW) //set the default view to waiting for data
      } else if(uploadCompleted) {  
        updateViewState(DOWNLOAD_FORM_VIEW)
      }
      
    }
  }, [downloadLink, uploadId, uploadCompleted])

  useEffect(() => {
    if(rtcTransferData && uploadId) {
      rtcTransferData.files && updateDecodedFiles(rtcTransferData.files)
      switch(rtcTransferData.status) {
        case RTC_TRANSFER_STATUS_STOPPED:
          updateViewState(RTC_TRANSFER_STOPPED_VIEW);
          break;
        case RTC_TRANSFER_STATUS_WAITING:
          updateViewState(RTC_TRANSFER_WAITING_VIEW);
          break;
        case RTC_TRANSFER_STATUS_IN_PROGRESS:
          if(decodedFiles.length > 0) {
            updateViewState(DOWNLOAD_FORM_VIEW);
          }  
          break;
        default:
          break;
      }
    }
  }, [rtcTransferData])

  useEffect(() => {
    if(rtcUtils.isInitialized && viewState !== DONE_VIEW && startUploading) {
      let progress = (totalBytesUploaded / totalFilesSizeInBytes) * 100; 
      let transferData = {progress: {value: progress > 0 ? progress : mockProgress, isMock: progress === 0}, files: validFiles && validFiles.map(({name, url, size}) => {return {name, url, size}}), uploadedFiles: successfulUploads, status: RTC_TRANSFER_STATUS_IN_PROGRESS, totalBytesUploaded: totalBytesUploaded, totalFilesSizeInBytes: totalFilesSizeInBytes}
      rtcUtils.broadcast(JSON.stringify(transferData));
    }
  }, [totalBytesUploaded, totalFilesSizeInBytes, validFiles, successfulUploads, mockProgress])

    function getUploadIdFromDownloadLink() {
      let parsedLink = downloadLink && downloadLink.substring(downloadLink.indexOf('uploadId=')+9,downloadLink.length);
      let uploadId = parsedLink && parsedLink.substring(0, parsedLink.indexOf('&') > -1 ?  parsedLink.indexOf('&') : parsedLink.length);
      return uploadId;
    }

    function showTutorialStepsByState() {
      if(formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD && (deviceType === consts.DEVICE_TYPES.DESKTOP || isEmbedded)) {
        if(selectedFiles.length < 1) {
          dispatch({ type: 'PRODUCT-TUTORIAL-CHANGED', payload: {show: true, currentStep: consts.PRODUCT_TUTORIAL_STEPS.UPLOAD_FILES}}); 
        } else if (items.length < 1 && currentActiveTab.name === consts.TAB_NAMES.Send_Email) {
          dispatch({ type: 'PRODUCT-TUTORIAL-CHANGED', payload: {show: true, currentStep: consts.PRODUCT_TUTORIAL_STEPS.ADD_RECIPIENTS}}); 
        } else if(progressBarCompleted && currentActiveTab.name === consts.TAB_NAMES.Generate_Link) {
          dispatch({ type: 'PRODUCT-TUTORIAL-CHANGED', payload: {show: true, currentStep: consts.PRODUCT_TUTORIAL_STEPS.COPY_LINK}}); 
        }
        else{
          dispatch({ type: 'PRODUCT-TUTORIAL-CHANGED', payload: {show: true, currentStep: consts.PRODUCT_TUTORIAL_STEPS.CLICK_SEND}}); 
        }
      } else {
        dispatch({ type: 'PRODUCT-TUTORIAL-CHANGED', payload: {show: false, currentStep: null}}); 
      }
    }
    
    const addFilesView = () => {
       return <AddFilesView droppedFiles = {droppedFiles} selectedFiles = {selectedFiles} updateSelectedFiles = {updateSelectedFiles} updateDroppedFiles = {updateDroppedFiles} encodedFiles = {decodedFiles} uploadListChanged = {uploadListChanged} updateUploadListChanged = {updateUploadListChanged} updateFileUploadCompleted = {updateFileUploadCompleted} totalFilesSizeInBytes={totalFilesSizeInBytes} uploadMessage={uploadMessage} isEmbedded={isEmbedded}/>
    }
    const mailDetails = () => {
        return <MailDetails emailFrom = {emailFrom} updateEmailFrom = {updateEmailFrom} emailTo = {emailTo} updateEmailTo = {updateEmailTo} message = {emailMessage} updateMessage = {updateMessage} items = {items} updateItems = {updateItems}/>
     }

     const clearState = () => {
      updateSelectedFiles([]);
      updateEmailFrom("");
      updateEmailTo([]);
      updateMessage("");
      updateVerificationCode("");
      updateDroppedFiles([]);
      updateUploadProgressInPercentage(null);
      updateIsUploadInProgress(false);
      updateCurrentUploadedFileName("");
      updateMbUploaded(null);
      updateFileSize(0);
      updateIsVerificationFailed(false);
      updateItems([]);
      updateCompletionEmailStatus("");
      updateUploadListChanged(false);
      updateIsVerificationInProgress(false)
      updateIsFileUploadPreparationInProgress(null);
      updateFileUploadCompleted("");
      updateUploadErrorOccurred(null);
      updateAllValidFilesUploaded(false);
      updateDownloadInProgress(false)
      updateUploadCandidates(null);
      updateProgressBarCompleted(false);
      updateTotalBytesUploaded(0);
      updateTotalFilesSizeInBytes(0);
      updateStartUploading(false);
      updateDownloadLink(null);
      updateBatchUploadInProgress(false);
      updateMockProgress(0);
      updateMockProgressUpdater(null);
      updateAutoUploadStarted(false);
      updateSuccessfulUploads(null);
      updateRtcTransferData(null);
      updateDecodedFiles(null);
     }

     const clearLocalStorage = () => {
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_FROM,null, 6);
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_RECIPIENTS, null, 6);
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.EMAIL_MESSAGE, null, 6);
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.UPLOADED_FILES, null, 6);
     }

    const memoizedMailDetails = useMemo(() => mailDetails() , [emailFrom, emailTo, emailMessage, items]);

    const memoizedFilesView = useMemo(() => addFilesView() , [selectedFiles, droppedFiles, decodedFiles, uploadMessage, totalFilesSizeInBytes, isEmbedded]);

    const renderTransferButton = ({extendedStyle = {}, loadingCondition}) => {
      return (
        <div style={extendedStyle} className={style.transferButtonContinaer}>
        <button type="submit" disabled={getIsButtonDisabled()} onClick={() => handleClickByState()} className={getButtonClass(viewState)} style = {{background: isCustomView || previewModeActive ? siteTemplate && siteTemplate.complementaryColor : getIsButtonDisabled() && '#D7D7D7', cursor: getIsButtonDisabled() && 'default' }}>
         {loadingCondition ? <img className={style.spinner} src="/images/spinner.gif" alt=""/> : renderButtonTextByState()}
        </button>
        {viewState === FILE_FORM_VIEW && <EditTabsContainer tabGroupName={consts.TAB_GROUPS.FORM} containerStyle={{width: 'auto', justifyContent: 'center'}} tabStyle={{minWidth: '91.02px'}} selectedTabClassName='selectedTabForm'/>}
    </div>
      );
    }

    const handleFilesUploadCompleted = async () => {
      if(deviceType === consts.DEVICE_TYPES.MOBILE && !isEmbedded){
        switch (currentActiveTab.name) {
          case consts.TAB_NAMES.Send_Email:
            sendVerificationCode(true);
            updateViewState(VARIFICATION_VIEW);
            break;
          case consts.TAB_NAMES.Generate_Link:
            updateViewState(LOADING_VIEW);
            break;
          default:
            break;
        }
      } else {
        await completeTransfer();
      }
    }

    const getButtonTextByTabState = () => {
      switch (currentActiveTab.name) {
        case consts.TAB_NAMES.Send_Email:
          return 'Send';
        case consts.TAB_NAMES.Generate_Link:
          return 'Create Link';
      }
    }

    const getTransferPanelHeaderText = () => {
     if(totalBytesUploaded > 0 || mockProgress === 100) {
      let actionName = `${selectedFiles.length} ${selectedFiles.length > 1 ? 'files...' : 'file...'}`;
      switch (currentActiveTab.name) {
        case consts.TAB_NAMES.Generate_Link:
          actionName = 'Uploading';
          break;
          case consts.TAB_NAMES.Send_Email:
           actionName = 'Sending';
           break;
      }
      return `${actionName} ${selectedFiles.length} ${selectedFiles.length > 1 ? 'files...' : 'file...'}`
     } else {
       return 'Warming engines...'
     }
    }

    function generateMockProgress() {
     mockProgressUpdater && mockProgressUpdater.progressUpdater();
    }

    const renderTransferPanel = ({extendedStyle = {}}) => {
      let uploadProgress = (totalBytesUploaded / totalFilesSizeInBytes) * 100; 
      let showMockProgress = totalBytesUploaded > 0 || mockProgress === 100;
      return (
        <div style={extendedStyle} className={style.transferButtonContinaer}>
          <div className={style.transferPanelContainer}>
           <div className={style.transferPanelHeaderWrapper}>
           {utils.undefineIsZero(uploadProgress) !== 0 ? <div className={style.ldsRing}><div></div><div></div><div></div><div></div></div> : null}
           <div className={style.transferPanelHeaderText}>
           <span className={style.transferPanelHeader}>{getTransferPanelHeaderText()}</span>
           <span className={style.mbTransferred}>{showMockProgress ? `${utils.bytesToSize(utils.undefineIsZero(totalBytesUploaded), true)} / ${utils.bytesToSize(utils.undefineIsZero(totalFilesSizeInBytes), true)}` : "We'll start in a few moments"}</span>
           </div>
           <span onClick={() => dispatch({ type: 'FORM-FOOTER-VIEW-STATE-CHANGED', payload:consts.FORM_FOOTER_VIEW_STATE.CANCEL_UPLOAD})} className={currentActiveTab.name ===  consts.TAB_NAMES.Send_Email ? style.cancelTransferEmail : style.cancelTransfer}>{'Cancel'}</span>
            </div> 
        <AnimatedProgressProvider valueStart={0} valueEnd={showMockProgress ? utils.undefineIsZero(uploadProgress) : mockProgress} duration={0.1} easingFunction={easeQuadInOut}>
          {value => {
          return (
            <Line style={{display: !allValidFilesUploaded ? 'inline-block' : !progressBarCompleted && totalBytesUploaded > 0 ? 'inline-block' : 'none', borderRadius: '50px'}} trailWidth="2" percent={value} strokeWidth="2" strokeColor="#13BCB4" />
          );
        }}
        </AnimatedProgressProvider>
          </div>
          <LinkBox downloadLink={downloadLink} linkBoxContainerStyle={{marginTop: '18px', position: 'relative', right: '5px'}} linkBoxStyle={{width: '98%', borderRadius: '12px'}} linkBoxButtonStyle={{position :'absolute', right: '-7px'}} emptyValue={'Generating link...'}/>
    </div>
      );
    }

    const renderReceiverProgressPanel = ({extendedStyle = {}}) => {
      let uploadProgress = rtcTransferData.progress.value;
      let showRealProgress = !rtcTransferData.progress.isMock;
      return (
        <div style={extendedStyle} className={style.transferButtonContinaer}>
          <div className={style.transferPanelContainer}>
           <div className={style.transferPanelHeaderWrapper}>
           {utils.undefineIsZero(uploadProgress) !== 0 ? <div className={style.ldsRing}><div></div><div></div><div></div><div></div></div> : null}
           <div className={style.transferPanelHeaderText}>
           <span className={style.transferPanelHeader}>{`Receiving ${rtcTransferData.files.length} ${rtcTransferData.files.length > 1 ? 'files' : 'file'}`}</span>
           <span className={style.mbTransferred}>{showRealProgress ? `${utils.bytesToSize(utils.undefineIsZero(rtcTransferData.totalBytesUploaded), true)} / ${utils.bytesToSize(utils.undefineIsZero(rtcTransferData.totalFilesSizeInBytes), true)}` : "We'll start in a few moments"}</span>
           </div>
            </div> 
        <AnimatedProgressProvider valueStart={0} valueEnd={utils.undefineIsZero(uploadProgress)} duration={0.1} easingFunction={easeQuadInOut}>
          {value => {
          return (
            <Line style={{display: 'inline-block', borderRadius: '50px'}} trailWidth="2" percent={value} strokeWidth="2" strokeColor="#13BCB4" />
          );
        }}
        </AnimatedProgressProvider>
          </div>
    </div>
      );
    }

    const onCancelConfirmationClick = (answer) => {
    switch (answer) {
      case 'Yes':
        updateStartUploading(false);
        updateBatchUploadInProgress(false);
        updateIsUploadInProgress(false);
        for(let uploadCandidate of uploadCandidates) {
          if(uploadCandidate.uploadInProgress && typeof uploadCandidate.pauseUpload == 'function') {
            uploadCandidate.pauseUpload();
          }
        }
       /* let currentFileUploading = selectedFiles.find((file) => file.uploadInProgress === true);
        if(currentFileUploading && typeof currentFileUploading.pauseUpload == 'function') {
          currentFileUploading.pauseUpload();
        }*/
        dispatch({ type: 'FORM-FOOTER-VIEW-STATE-CHANGED', payload:consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD});
        dispatch({ type: 'FORM-RESIZE-MODE-CHANGED', payload: consts.FORM_RESIZE_MODE.EXPANDED});
        if(rtcUtils.isInitialized) {
          let transferData = {status: RTC_TRANSFER_STATUS_STOPPED}
          rtcUtils.broadcast(JSON.stringify(transferData));
        }
        break;
      case 'No':
        dispatch({ type: 'FORM-FOOTER-VIEW-STATE-CHANGED', payload:consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS})
        break;
      default:
        break;
    }
    }

    const renderCancelPanel = ({extendedStyle = {}}) => {
      return (
        <div style={extendedStyle} className={style.transferButtonContinaer}>
          <div className={style.cancelPanelContainer}>
            <span className={style.confirmCancel}>Are you sure?</span>
            <div className={style.confirmCancelSelectionChips}>
            <SelectionChip chipStyle={{width: '85px', height: '35px'}} text = 'No'  onClick = {() =>  onCancelConfirmationClick('No')}/>
            <SelectionChip chipStyle={{width: '85px', height: '35px'}} text = 'Yes' onClick = {() =>  onCancelConfirmationClick('Yes')}/>
           </div> 
          </div>
    </div>
      );
    }

    const renderFormFooterView = ({extendedStyle = {}, loadingCondition}) => {
      switch (formFooterViewState) {
        case consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD:
          parent.postMessage('transfer-not-in-progress', '*'); //send a message to the extension to resize itself accordingly
          return renderTransferButton({extendedStyle, loadingCondition});
        case consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS:
          if(formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED) {
            parent.postMessage('transfer-in-progress', '*')
          }
          return renderTransferPanel({extendedStyle});
        case consts.FORM_FOOTER_VIEW_STATE.CANCEL_UPLOAD:
          if(formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED) {
            parent.postMessage('transfer-in-progress', '*')
          }
          //parent.postMessage('transfer-not-in-progress', '*');
          return renderCancelPanel({extendedStyle});
        default:
          break;
      }
    }

    const getButtonClass = (viewState) => {
        switch (viewState){
            case FILE_FORM_VIEW:
                return style.transferButtonStyle
            case VARIFICATION_VIEW:
            case RESEND_VERIFICATION_VIEW:
            case LOADING_VIEW:   
                 return style.verifyButtonContainer
            case DOWNLOAD_FORM_VIEW: 
                 return style.downloadAllFilesStyle      
            case NO_CONNECTION_VIEW:    
              return style.networkErrorButton 
            case UPLOAD_EXPIRED_VIEW:     
                 return style.addNewFilesButtonStyle
            case DONE_VIEW:
             return style.driveMoreButtonStyle
            default:
        }
        
    }

    function handleOnSendAgain() {
      updateViewState(RESEND_VERIFICATION_VIEW);
    }

    function isValidFile(file) {
      return !file.uploadErrorOccurred && !file.isTooLarge && !file.rejected;
    }

    const toastContent = () => 
      (
        <div style={{display: 'inline-block', maxWidth: '100%', alignItems: 'center', justifyContent: 'space-between', width: '420px', fontSize: '.875rem'}}>
          <div style={{marginRight: '24px'}}>
          While you're waiting, you can customize your recipient’s experience with your brand
          (This link will open in a new tab and <b>will not effect</b> the current upload)
          </div>
          <a style={{fontSize: '12px'}}> 
          Customize
         </a>
        </div>  
      );
    

    function showToast() {
      toast(toastContent(), {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

    function reportTransferType(transferType){
      let eventData = {transfer_type: transferType};
      utils.trackWorkQuestionAnsweredEvent(eventData);
      transferType == 'work' && utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.ANSWERED_WORK);
    }

    function getCompletedTransfersFromLocalStorage() {
      return utils.getLocalStorageItem(consts.LOCAL_STORAGE_KEYS.COMPLETED_TRANSFERS) || {count: 0, lastTransferDate: new Date()};
    }

    async function completeTransfer() {
      let uploadId = getUploadIdFromDownloadLink();
      let filesMetadata = successfulUploads.map(file => {return {name: file.name, uri: file.uri, size: file.size, type: utils.getFileType(file.name)}});
      restUtils.post('completeTransfer', {userId: user.userId, uploadId: uploadId, filesMetadata: filesMetadata});

      await sendMailOrGetLink();
      let eventDataDone = {from_step: LOADING_VIEW, to_step: isEmbedded ? `${DONE_VIEW}-embedded` : DONE_VIEW, user_email: emailFrom, is_work_email: utils.isWorkEmail(emailFrom)};
      utils.trackFormStepChanged(eventDataDone);
      dispatch({ type: 'FORM-RESIZE-MODE-CHANGED', payload: consts.FORM_RESIZE_MODE.EXPANDED});
      dispatch({ type: 'FORM-FOOTER-VIEW-STATE-CHANGED', payload:consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD}); //reset footer state 
      clearLocalStorage();
      let completedTransfers = getCompletedTransfersFromLocalStorage();
      let daysSinceLastTransfer = Math.floor((new Date().getTime() - new Date(completedTransfers.lastTransferDate).getTime())/(1000 * 60 * 60 * 24))

      if(completedTransfers.count > 1 && daysSinceLastTransfer < 15 && deviceType === consts.DEVICE_TYPES.DESKTOP) {
        utils.trackUserLeapEvent('show-engaged-user-survey');
        dispatch({ type: 'SHOW-ENGAGED-SURVEY-MODAL-CHANGED', payload:true}); 
      }
      if(completedTransfers.count === 0) {       
        dispatch({ type: 'SHOW-CELEBRATION-SCREEN-CHANGED', payload: true});
      } 
      utils.setLocalStorageItem(consts.LOCAL_STORAGE_KEYS.COMPLETED_TRANSFERS, {count: parseInt(completedTransfers.count) + 1, lastTransferDate: new Date()}, 365)
     
      if(rtcUtils.isInitialized) {
        let transferData = {status: RTC_TRANSFER_STATUS_COMPLETED}
        rtcUtils.broadcast(JSON.stringify(transferData));
      }
      updateViewState(DONE_VIEW);
    }

    async function handleOnLoadingFinished(transferType) {
      reportTransferType(transferType);
      await completeTransfer();
    }

    function renderDownloadFooter({extendedStyle = {}}) {
      if(rtcTransferData && rtcTransferData.status === RTC_TRANSFER_STATUS_IN_PROGRESS) {
          return  renderReceiverProgressPanel({extendedStyle});
      } else if(rtcTransferData && rtcTransferData.status === RTC_TRANSFER_STATUS_COMPLETED) {
        return renderTransferButton({extendedStyle: { textAlign: 'center'}, loadingCondition: downloadInProgress});
      } else { //default
        return renderTransferButton({extendedStyle: { textAlign: 'center'}, loadingCondition: downloadInProgress});
      }
    }

    const renderViewByState = () => {
        if(embeddedUpsellCTA) {
          return embeddedUpsellCTA;
        }
            switch (viewState) {
                case FILE_FORM_VIEW:
                 return <AddFilesForm addFilesView = {memoizedFilesView} mailDetailsView = {memoizedMailDetails} transferButton = {renderFormFooterView({extendedStyle: {borderTop: '1px solid #d4d7d9', boxSizing: 'border-box', boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.1)'}})} scrollableDivRef = {scrollableDivRef} scrollableContentHeight = {328} disableScroll = {false} formFooterViewState={formFooterViewState} isEmbedded={isEmbedded}/>;
                 case DOWNLOAD_FORM_VIEW:
                  return <AddFilesForm addFilesView = {memoizedFilesView} extendedStyle={{height: '100%'}} scrollableContentHeight={328} transferButton = {renderDownloadFooter({extendedStyle: {borderTop: '1px solid #d4d7d9', boxSizing: 'border-box', boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.1)'}})} scrollableDivRef = {scrollableDivRef} disableScroll = {decodedFiles.length < 5 && !uploadMessage ? true : false}/>
                  case UPLOAD_EXPIRED_VIEW:
                    return <UploadExpiredView transferButton = {renderTransferButton({})}/>
                  case NO_CONNECTION_VIEW:
                    return <NoConnectionView transferButton = {renderTransferButton({extendedStyle: {top: '20px'}})} isEmbedded={isEmbedded}/>  
                case VARIFICATION_VIEW:
                  return <VerificationForm onBack = {() => updateViewState(FILE_FORM_VIEW)} verificationCode = {verificationCode} updateVerificationCode = {updateVerificationCode} isVerificationFailed = {isVerificationFailed} emailFrom = {emailFrom} transferButton = {renderTransferButton({extendedStyle: {bottom: '85px', left: '30px'}, loadingCondition: isVerificationInProgress})} onSendAgainClick = {handleOnSendAgain}/>;
                  case RESEND_VERIFICATION_VIEW:
                  return <ResendVerificationView onBack = {() => updateViewState(VARIFICATION_VIEW)} updateEmailFrom = {updateEmailFrom} emailFrom = {emailFrom} transferButton = {renderTransferButton({extendedStyle: {left: '30px', bottom: '20px'}})}/>
                case LOADING_VIEW:
                  return <LoadingView onUploadFinished = {async (transferType) => await handleOnLoadingFinished(transferType)} completionEmailStatus={completionEmailStatus}/>;
                case DONE_VIEW:
                    return <UploadFinishedView uploadFinishedViewType={currentActiveTab.name === consts.TAB_NAMES.Send_Email ? 'email' : 'link'} transferButton = {renderTransferButton({extendedStyle: {padding: '0'}, loadingCondition: currentActiveTab.name === consts.TAB_NAMES.Send_Email ? completionEmailStatus !== 'DELIVERED' : null})} onBack = {() => deviceType === consts.DEVICE_TYPES.MOBILE && !isEmbedded ? updateViewState(VARIFICATION_VIEW) : updateViewState(FILE_FORM_VIEW)} recipientsEmails = {items} downloadLink={downloadLink}/>;
                case RTC_TRANSFER_STOPPED_VIEW:
                    return <TransferStoppedView/> 
                case RTC_TRANSFER_WAITING_VIEW:    
                    return <TransferWaitingView/>  
                }
    }

    const renderButtonTextByState = () => {
        { 
            switch (viewState) {
                case FILE_FORM_VIEW:
                  return getButtonTextByTabState();
                  //return selectedFiles && selectedFiles.length > 0 && selectedFiles.filter(file => file.uploadCompleted === true && file.completedSuccessfully === true).length !== selectedFiles.filter(i => isValidFile(i)).length ? "Uploading..." : "Send";
                case DOWNLOAD_FORM_VIEW:
                  return BUTTON_TEXT_DOWNLOAD_ALL;
                case VARIFICATION_VIEW:
                  return BUTTON_TEXT_VERIFY;
                case RESEND_VERIFICATION_VIEW:
                    return BUTTON_TEXT_RESEND;
                case LOADING_VIEW:
                  return BUTTON_TEXT_CANCEL_LOADING;
                case DONE_VIEW:
                    return BUTTON_TEXT_ADD_MORE_FILES;
                case UPLOAD_EXPIRED_VIEW:
                    return BUTTON_TEXT_UPLOAD_EXPIRED;  
                case NO_CONNECTION_VIEW:
                  return BUTTON_TEXT_NO_CONNECTION;     
                }
            }
    }

function updateProgress(progress, fileName) {
  updateUploadProgressInPercentage({uploadProgress:progress, fileName: fileName});
}    

async function uploadFile(file, cb, onError) {
  try {
       updateIsFileUploadPreparationInProgress(true);
        file.uploadInProgress = true;
        file.startTime = new Date();
        const currentUTCDate = new Date().toISOString().slice(0,10); //returns UTC yyyy-mm-dd format
        const getUserId = utils.userId();
        const userId = getUserId();
        let uriName = `${currentUTCDate}/${userId}/${file.name}`; //take the name of the first file of the uri
        let eventData = {
          file_name: autoUploadStarted ?  `${file.name}-gmail`: isEmbedded ? `${file.name}-embedded` : file.name,
          file_size: file.size,
          file_type: utils.getFileType(file.name),
          file_uri: uriName,
          user_email: emailFrom && emailFrom != '' ? emailFrom : '',
          is_work_email: emailFrom && emailFrom != '' ? utils.isWorkEmail(emailFrom): false
      }
        utils.trackFileUploadStarted(eventData);
        updateFileSize(file.size);
        file.uri = uriName;
        await restUtils.uploadFiles(file, uriName, 'files', updateMbUploaded, (progress) => updateProgress(progress, file.name), updateIsUploadInProgress, (prepInProgress) => updateIsFileUploadPreparationInProgress({prepInProgress: prepInProgress, fileName: file.name}), (errorOccurred) => updateUploadErrorOccurred({errorOccurred: errorOccurred, fileName: file.name}),
          (upload) => {
            file.cancelUpload = () => {
            let uploadDuration = utils.diff_minutes(new Date(), file.startTime);
            eventData.percentage_uploaded = typeof file.uploadProgressInPercentage == 'undefined' ? 0 : Math.round(file.uploadProgressInPercentage);
            eventData.upload_duration = uploadDuration;
            //eventData.upload_duration
            //console.log('upload cancelled event data: ' + JSON.stringify(eventData))
            utils.trackUploadCancelled(eventData);
            file.uploadCompleted = true;
            file.uploadInProgress = false;
            file.mbUploaded = 0;
            upload.cancel();
          },
          file.pauseUpload = () => {
            file.isPaused = true;
            upload.pause();
          },
          file.resumeUpload = () => {
            file.isPaused = false;
            upload.unpause();
          }
        }, (uploadedBytes, uploadedBytesUntilNow, animationTime) => {
          updateMbUploaded({mbUploaded: uploadedBytes, fileName: file.name})
            //utils.animateValue((progress) => updateMbUploaded({mbUploaded: progress, fileName: file.name}),uploadedBytesUntilNow,uploadedBytes, animationTime || 500);
          });
        updateFileUploadCompleted(file.name);
        let uploadDuration = utils.diff_minutes(new Date(), file.startTime);
        eventData.upload_duration = uploadDuration;
        file.uploadDuration = uploadDuration;
        eventData.total_upload_progress = Math.round(selectedFiles.filter(file => file.uploadCompleted === true).length / selectedFiles.length * 100);
        console.log('file upload completed event data: ' + JSON.stringify(eventData))
        utils.trackFileUploadCompleted(eventData);
        utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.ENGAGED_USER);
        cb();
  } catch (error) {
    file.uploadInProgress = false;
    console.error(error);
    onError && onError();
  }
    finally {
      //updateSelectedFiles([...selectedFiles, ...[]]);
    }
}

function cancelUpload(){
  restUtils.cancelUpload();
}

async function verifyCode(code){
  return useVerifyCode(code, dispatch, codeVerificationData, user);
}

async function sendMail(filesMetadata, recipientsEmails, emailMessage, userId, sessionId, email_from) {
  try {
       await restUtils.post('sendCompletionEmail', {recipientsEmails: recipientsEmails, filesMetadata: filesMetadata,
        message: emailMessage, urlPrefix: window.location.origin, userId: userId, sessionId: sessionId,
        recipient_address: email_from, downloadLink: downloadLink}, true);
  }
  catch (error){
      console.log(error);
  }
}

async function getDownloadLink(filesMetadata, userId, message) {
  try {
       let uploadId = getUploadIdFromDownloadLink();
       let linkRequestDTO = {filesMetadata: filesMetadata, urlPrefix: window.location.origin, userId: userId, existingUploadId: uploadId};
       if(currentActiveTab.name === consts.TAB_NAMES.Generate_Link) {
        linkRequestDTO.message = message;
       }
       let res = await restUtils.post('getLink', linkRequestDTO, true);
       return res.data;
  }
  catch (error){
      console.log(error);
  }
}

async function sendMailOrGetLink(){
  let uploadedFiles = selectedFiles.filter(file => file.uploadCompleted === true && file.completedSuccessfully === true);
  let filesMetadata = uploadedFiles.map(file => {return {name: file.name, uri: file.uri, size: file.size, type: utils.getFileType(file.name)}});
  const getUserId = utils.userId();
  const userId = getUserId();
  let eventDataUserEmail = emailFrom; //TODO: replace it with user's signup email
  let recipientsEmails;
  clearLocalStorage();
  await utils.wait(500); //a dirty hack to make sure that local storage is cleared (makes bugs otherwise)
  if(currentActiveTab.name === consts.TAB_NAMES.Send_Email) {
    if(completionEmailStatus !== 'SENT' && uploadedFiles.length > 0 && emailFrom != '' && items.length > 0) {
      recipientsEmails = items;
      updateCompletionEmailStatus('SENT');
      const sessionId = utils.getSessionId();
      await sendMail(filesMetadata, recipientsEmails, emailMessage, userId, sessionId, emailFrom);
      updateCompletionEmailStatus('DELIVERED');
    }
  } else if(currentActiveTab.name === consts.TAB_NAMES.Generate_Link) {
    eventDataUserEmail = 'download-link';
    //let downloadLink = await getDownloadLink(filesMetadata, userId, emailMessage);
    //updateDownloadLink(downloadLink);
  }

  
  let totalUploadDuration = 0;
  uploadedFiles.forEach(file => totalUploadDuration += file.uploadDuration)
  let totalFileSize = 0;
  uploadedFiles.forEach(file => totalFileSize += file.size)
  let eventData = {
    user_email: autoUploadStarted ? `${eventDataUserEmail}-gmail` : isEmbedded ? `${eventDataUserEmail}-embedded` : eventDataUserEmail,
    is_work_email: utils.isWorkEmail(eventDataUserEmail),
    email_message: emailMessage,
    total_upload_duration: totalUploadDuration,
    total_number_of_files: uploadedFiles.length,
    total_size_of_files: totalFileSize,
    total_number_of_recipients: recipientsEmails && recipientsEmails.length,
    total_number_of_work_recipients: recipientsEmails && recipientsEmails.map(email => {return {isWorkEmail: utils.isWorkEmail(email)}}).filter(email => email.isWorkEmail === true).length
  }
  console.log('upload completed event data: ' + JSON.stringify(eventData));
  utils.trackUploadCompleted(eventData);
}

function sendVerificationCode(forceResend = true, verificationMethod, userPhone){
  utils.sendVerificationCode(emailFrom, forceResend, verificationMethod, userPhone);
}

const redirectToHomePage = () => {
  isEmbedded ? window.location.assign(`${window.location.origin}/?embedded=true`) : window.location.assign(window.location.origin);
}

async function downloadAllFiles() {
  try {
    updateDownloadInProgress(true);
    if(decodedFiles.length === 1) { //if we have only 1 file, don't waste resources to zip it and download it directly
      let file = decodedFiles[0];
        let a = document.getElementById(`download_link_${file.name}`);
        let tempLink = document.createElement('a');
        document.body.appendChild(tempLink);
        tempLink.setAttribute('href', a.href.baseVal);
        tempLink.setAttribute('download', file.name);
        tempLink.click();
        updateDownloadInProgress(false);
        return;
    }
    let urls = [];
    let totalFilesSize = 0;
    for (let file of decodedFiles) {
        totalFilesSize += parseInt(file.size);
        let a = document.getElementById(`download_link_${file.name}`);
        urls.push(a.href.baseVal); //get the download url 
    }
    //https://bugs.webkit.org/show_bug.cgi?id=202142
    //https://touffy.me/client-zip/demo/worker
    //Due to a safari bug we cannot use service workers to create files (and we need it to use the streaming method of the library for large files).
    //Hence, if the user is using safari and the total files size > 500 MB (this number could be tested and refined), we currently do not enable zipped files download
    if(user.isUsingSafari) { 
      if(totalFilesSize <= 524288000) { //check to see if we can fallback to non-streaming method (i.e. total file size < 500MB)
        let fileBlobs = [];
        for (let url of urls) {
          let fileUrl = await restUtils.getExternalUrl(`${url}&returnUrl=true`);  //return the signed url of the files used to download it (the default is redirect to the url so the browser will download it)
          const fileBlob = await fetch(fileUrl.data);
          fileBlobs.push(fileBlob);
        }
          // get the ZIP stream in a Blob
          const downloadZip = (await import('client-zip')).downloadZip; //need to load the library dynamically as it doesn't work with SSR
          const blob = await downloadZip(fileBlobs).blob()
          // make and click a temporary link to download the Blob
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `files_${Date.now()}.zip`
          link.click()
          link.remove()
          updateDownloadInProgress(false);
      } else {
        alert('This feature is not supported in your current browser, we recommend switching to chrome for the best experience using FileDriver. To download the files in this browser, click the arrow button next to each file');
        updateDownloadInProgress(false);
      }
    } else {
      let encodedURLs = btoa(JSON.stringify(urls));
      var tempLink = document.createElement('a');
      document.body.appendChild(tempLink);
      tempLink.setAttribute('href',`downloadZip/?urls=${encodedURLs}`); //call downloadZip which is being intercepted by the service worker
      tempLink.click();
      updateDownloadInProgress(false);
    }
 
  } catch (error) {
      console.log(error);
  }
}

  function startUploadFiles(startMinimized) {
    let sorterValidationFailedReasons = validateRequiredTransferData();
    if(sorterValidationFailedReasons.length === 0) { //no validation failed
      updateDroppedFiles([]); //an ugly hack to clear the Dropzone cache 
      //deviceType === consts.DEVICE_TYPES.MOBILE && sendVerificationCode();
      dispatch({ type: 'FORM-FOOTER-VIEW-STATE-CHANGED', payload:consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS}); 
      updateStartUploading(true);
      if(startMinimized) {
        dispatch({ type: 'FORM-RESIZE-MODE-CHANGED', payload: consts.FORM_RESIZE_MODE.MINIMIZED});
      }
      //updateViewState(getViewToUpdate());
    }
  }

    const handleClickByState = async () => {
      const tooltipStyles = {};
      tooltipStyles[consts.VALIDATION_TOOLTIP_REASONS.NO_FILES_ADDED] = {
        container: {height: '137px', minHeight: '0px', top: '30vh'},
        pointer: {top: '8%'}
      };
      tooltipStyles[consts.VALIDATION_TOOLTIP_REASONS.NO_RECIPIENTS_EMAIL] = {
        container: {height: '137px', minHeight: '0px', top: validFiles && validFiles.length > 1 ? '39vh' : '36vh'},
        pointer: {top: '8%'}
      };
      tooltipStyles[consts.VALIDATION_TOOLTIP_REASONS.NO_USER_EMAIL] = {
        container: {height: '137px', minHeight: '0px', top: validFiles && validFiles.length > 1 ? '44.5vh' : '40vh'},
        pointer: {top: '8%'}
      };
      tooltipStyles[consts.VALIDATION_TOOLTIP_REASONS.USER_NOT_VERIFIED] = {
        container: {height: '137px', minHeight: '0px', top: '36vh'},
        pointer: {top: '8%'}
      };
      switch (viewState) {
        case FILE_FORM_VIEW:
          let sorterValidationFailedReasons = validateRequiredTransferData();
          if(sorterValidationFailedReasons.length < 1) {
            let quotaUsed = (user.monthlyTransferCount / user.monthlyTransfersLimit) * 100;
            if(quotaUsed >= 100) {
              dispatch({ type: 'UPSELL-TOOLTIP-CHANGED', payload: {show: true, reason: consts.UPSELL_REASONS.TRANSFER_LIMIT_EXCEEDED, currentLimit: user.monthlyTransfersLimit}});
              return;
            }
            startUploadFiles();
          } 
          else if (sorterValidationFailedReasons.length === 1 && sorterValidationFailedReasons[0].name === consts.VALIDATION_TOOLTIP_REASONS.USER_NOT_VERIFIED) {
            dispatch({ type: 'VALIDATION-TOOLTIP-CHANGED', payload: {show: true, reason:sorterValidationFailedReasons[0].name, customTooltipStyle: null}});
            //dispatch({ type: 'VERIFICATION-TOOLTIP-CHANGED', payload: {show: true}});
          }else {
            //let sortedReasons = sorterValidationFailedReasons.sort((reasonA, reasonB) => reasonA.priority - reasonB.priority);
            let reason = sorterValidationFailedReasons[0]; //take the first reason each time
            dispatch({ type: 'VALIDATION-TOOLTIP-CHANGED', payload: {show: true, reason: reason.name, customTooltipStyle: tooltipStyles[reason.name]}});
            dispatch({ type: 'VERIFICATION-TOOLTIP-CHANGED', payload: {show: false}});
          }

          break;
        case UPLOAD_EXPIRED_VIEW:  
          redirectToHomePage();
          break;  
        case DOWNLOAD_FORM_VIEW:
          if((!previewModeActive || previewModeActive ==! 'true') && !siteTemplate.isDemoTemplate) {
            downloadAllFiles(); 
          }
          break;  
        case VARIFICATION_VIEW:
              let success = await verifyCode(verificationCode);
              updateViewState(getViewToUpdate(success));
            break;
        case RESEND_VERIFICATION_VIEW:
              utils.trackFormStepChanged({from_step: VARIFICATION_VIEW, to_step: RESEND_VERIFICATION_VIEW, user_email: emailFrom, is_work_email: utils.isWorkEmail(emailFrom)})
              sendVerificationCode(true);
              updateViewState(getViewToUpdate());
            break;
        case LOADING_VIEW:
            cancelUpload();
            clearState();
            updateViewState(getViewToUpdate());
          break;
          case DONE_VIEW:
            clearState();
            clearLocalStorage();
            updateViewState(getViewToUpdate());
            redirectToHomePage();
            break;
          case NO_CONNECTION_VIEW:
            redirectToHomePage();
            break;
          default:
            updateViewState(getViewToUpdate());
        }
      
    }

    function receiveChannelCallback(event, clientId) {
      console.log('data channel received')
      let senderDataChannel = event.channel;
      senderDataChannel.onmessage = handleReceiveMessage;
      rtcUtils.addChannel(senderDataChannel, clientId);
      let transferStatus = rtcTransferData && rtcTransferData.status;
      if(rtcUtils.isInitialized) {
        if(transferStatus) {
          switch (rtcTransferData) {
            case RTC_TRANSFER_STATUS_IN_PROGRESS:
              let progress = (totalBytesUploaded / totalFilesSizeInBytes) * 100; 
              let transferData = {progress: progress, files: validFiles && validFiles.map(({name, url, size}) => {return {name, url, size}}), uploadedFiles: successfulUploads, status: RTC_TRANSFER_STATUS_IN_PROGRESS, totalBytesUploaded: totalBytesUploaded, totalFilesSizeInBytes: totalFilesSizeInBytes}
              rtcUtils.broadcast(JSON.stringify(transferData));
              break;
            case RTC_TRANSFER_STATUS_STOPPED:
              rtcUtils.broadcast(JSON.stringify({status: RTC_TRANSFER_STATUS_STOPPED}));
              break;
            default:
              break;
          }
        } else {
          rtcUtils.send(JSON.stringify({status: RTC_TRANSFER_STATUS_WAITING}), clientId);
        }
      }
    }

    function handleReceiveMessage(event) {
      console.log('received message vit WebRTC!: ' + event.data)
      let transferData = event.data;
      let parsedData = JSON.parse(transferData);
      parsedData && updateRtcTransferData(parsedData);
    }

    // Called by the WebRTC layer to let us know when it's time to
    // begin, resume, or restart ICE negotiation.
    async function handleNegotiationNeededEvent(sourceId) {
      try {
        let rtcConnection = null;
        if(uploadId && rtcConnections.length === 1) {
          rtcConnection = rtcConnections[0];
        } else {
          console.log('Error while executing handleNegotiationNeededEvent: rtcConnections has invalid number of connections: ' + rtcConnections.length);
          return;
        }
        console.log("Creating rtc offer");
        const offer = await rtcConnection.createOffer();
    
        // If the connection hasn't yet achieved the "stable" state,
        // return to the caller. Another negotiationneeded event
        // will be fired when the state stabilizes.
        if (rtcConnection.signalingState != "stable") {
          console.log("The connection isn't stable yet; postponing...")
          return;
        }
    
        // Establish the offer as the local peer's current
        // description.
        console.log("Setting local description to the offer");
        await rtcConnection.setLocalDescription(offer);
    
        // Send the offer to the remote peer.
    
        console.log("Sending the offer to the remote peer, sourceId: " + sourceId);
        var msgJSON = JSON.stringify({
          source: sourceId,
          target: `${uploadId}-s`,
          type: "transfer-offer",
          sdp: rtcConnection.localDescription
        });
        wsConnection.send(msgJSON);
      } catch(err) {
        console.log("The following error occurred while handling the negotiationneeded event: " + err);
      }
    }

    function handleICECandidateEvent(event, sourceId) {
      console.log('handleICECandidateEvent, uploadId: ' + uploadId);
      console.log('handleICECandidateEvent, downloadLink: ' + downloadLink);
      let targetId = "";
      let receiverId = "";
      if(uploadId) { //we are the receiver, we should send the ICE candidates to the sender
        targetId = `${uploadId}-s`;
        if(rtcConnections && rtcConnections.length === 1) {
          receiverId = rtcConnections[0].clientId;
        } else {
          console.log('Error while executing handleICECandidateEvent: rtcConnections has invalid number of connections: ' + rtcConnections.length);
        }
        
      } else if(downloadLink) { //we are the sender, we should send the ICE candidates to the receivers
        /*let parsedLink = downloadLink && downloadLink.substring(downloadLink.indexOf('uploadId=')+9,downloadLink.length);
        let uploadId = parsedLink && parsedLink.substring(0, parsedLink.indexOf('&') > -1 ?  parsedLink.indexOf('&') : parsedLink.length);
        targetId = `${uploadId}-r`*/
        targetId = sourceId;
      }
      console.log('handleICECandidateEvent, targetId: ' + targetId);
      if (event.candidate && targetId !== "") {
        console.log("Outgoing ICE candidate: " + event.candidate.candidate);
        var msgJSON = JSON.stringify({
          source: receiverId,
          type: "new-ice-candidate",
          target: targetId,
          candidate: event.candidate
        });
        wsConnection.send(msgJSON);
      } else {
        console.error('error finding rtc target id for ice candidate event')
      }
    }

    function handleICEConnectionStateChangeEvent(clientId) {
      let rtcConnection = null;
      if(uploadId) {
        if(rtcConnections.length === 1) {
          rtcConnection = rtcConnections[0];
        } else {
          console.log('Error while executing handleNegotiationNeededEvent: rtcConnections has invalid number of connections: ' + rtcConnections.length);
          return;
        }
      } else {
        rtcConnection = rtcConnections.find(i => i.clientId === clientId);
      }
      switch(rtcConnection.iceConnectionState) {   
        case "closed":
        case "failed":
        //case "disconnected":
          if(uploadId) {
           updateViewState(RTC_TRANSFER_STOPPED_VIEW);
          }
          if(["closed", "failed"].includes(rtcConnection.iceConnectionState)) {
            closePeerConnection(rtcConnection);
          }
          break;
        case "connected":
          if(uploadId) {
            updateViewState(DOWNLOAD_FORM_VIEW);
           }
      }
    }

    function closePeerConnection(connection) {
      if (connection) {
        console.log("Closing the peer connection");
    
        // Disconnect all our event listeners; we don't want stray events
        // to interfere with the hangup while it's ongoing.
        //connection.ontrack = null;
        connection.onnicecandidate = null;
        connection.oniceconnectionstatechange = null;
        connection.onsignalingstatechange = null;
        connection.onicegatheringstatechange = null;
        connection.onnotificationneeded = null;
    
        // Stop all transceivers on the connection
        connection.getTransceivers().forEach(transceiver => {
          transceiver.stop();
        });
    
        // Stop the webcam preview as well by pausing the <video>
        // element, then stopping each of the getUserMedia() tracks
        // on it.
    
       /* if (localVideo.srcObject) {
          localVideo.pause();
          localVideo.srcObject.getTracks().forEach(track => {
            track.stop();
          });
        }*/
    
        connection.close();
        connection = null;
        //webcamStream = null;
        if(downloadLink) {
            rtcUtils.removeChannel(connection);
        }

      }
    }

    async function createPeerConnection(clientId) {
      console.log("Setting up an RTC connection..."); 
      // Create an RTCPeerConnection which knows to use our chosen
      // STUN server.
      if(!iceServers) {
        let servers = await restUtils.postExternal(process.env.SIGNALING_SERVER_REST, 'iceServers');
        if(servers && servers.data) {
          iceServers = servers.data;
          if(iceServers && iceServers.length === 0) {
            console.log('error while getting ice servers, returned empty array')
          }
        }
      }
      let myPeerConnection = new RTCPeerConnection({
        iceServers: iceServers
        /*iceServers: [     // Information about ICE servers - Use your own!
          /*{
            urls: "stun:stun.l.google.com:19302"
            urls: "turn:" + myHostname,  // A TURN server
            username: "webrtc",
            credential: "turnserver"
          },
          {
            urls: [
              "turn:34.133.20.6:3478?transport=udp",
              "turn:34.133.20.6:3478?transport=tcp"
            ],
            username: "filedriverturn",
            credential: "f1l@Dr!ver"
          }
        ]*/
      });
      myPeerConnection.clientId = clientId;
      // Set up event handlers for the ICE negotiation process.
      myPeerConnection.onicecandidate = (e) => handleICECandidateEvent(e, clientId);
      myPeerConnection.onnegotiationneeded = () => handleNegotiationNeededEvent(clientId);
      myPeerConnection.ondatachannel = (e) => receiveChannelCallback(e, clientId);
      myPeerConnection.oniceconnectionstatechange = () => handleICEConnectionStateChangeEvent(clientId);
     /* myPeerConnection.onicegatheringstatechange = handleICEGatheringStateChangeEvent;
      myPeerConnection.onsignalingstatechange = handleSignalingStateChangeEvent;*/
      
      //myPeerConnection.ontrack = handleTrackEvent;
      return myPeerConnection;
    }

    async function handelTransferOfferMessage(msg, rtcConnection) {
      // If we're not already connected, create an RTCPeerConnection
      // to be linked to the caller.
      console.log("Received rtc offer from " + msg.source);
    
      if(!rtcConnection) {
        console.error('Error while handling RTC offer, cannot find rtc connection.')
        return;
      }
      // We need to set the remote description to the received SDP offer
      // so that our local WebRTC layer knows how to talk to the caller.
      var desc = new RTCSessionDescription(msg.sdp);
    
      // If the connection isn't stable yet, wait for it...
    
      if (rtcConnection.signalingState != "stable") {
        console.log("The signaling state isn't stable, so triggering rollback");
    
        // Set the local and remove descriptions for rollback; don't proceed
        // until both return.
        await Promise.all([
          rtcConnection.setLocalDescription({type: "rollback"}),
          rtcConnection.setRemoteDescription(desc)
        ]);
        return;
      } else {
        console.log ("Connection is stable - setting remote description");
        await rtcConnection.setRemoteDescription(desc);
      }
    
      console.log("Creating and sending answer to caller, targetClientId: " + msg.source);
    
      await rtcConnection.setLocalDescription(await rtcConnection.createAnswer());
    
      var msgJSON = JSON.stringify({
        target: msg.source,
        type: "transfer-answer",
        sdp: rtcConnection.localDescription
      });
      wsConnection.send(msgJSON);
    }

    // A new ICE candidate has been received from the other peer. Call
  // RTCPeerConnection.addIceCandidate() to send it along to the
  // local ICE framework.
    async function handleNewICECandidateMsg(msg) {
    var candidate = new RTCIceCandidate(msg.candidate);
    console.log("Adding received ICE candidate: " + JSON.stringify(candidate));
    try {
      let rtcConnection = null;
      if(uploadId) {
        if(rtcConnections.length === 1) {
          rtcConnection = rtcConnections[0];
        } else {
          console.log('Error while executing handleNegotiationNeededEvent: rtcConnections has invalid number of connections: ' + rtcConnections.length);
          return;
        }
      } else {
        rtcConnection = rtcConnections.find(i => i.clientId === msg.source);
      }
      if(!rtcConnection) {
        console.error('Error while handling new ice candidate, cannot find rtc connection. current connections: ' + rtcConnections && rtcConnections.map(i => i.clientId));
        return;
      }
      await rtcConnection.addIceCandidate(candidate)
    } catch(err) {
      console.log('Error while adding ICE candidate: ' + err);
    }
    }

    // Responds to the "video-answer" message sent to the caller
// once the callee has decided to accept our request to talk.
    async function handleTransferAnswerMessage(msg, clientSetupInfo) {
    console.log("RTC recipient has accepted the offer");
    let rtcConnection = null;
    if(uploadId && rtcConnections.length === 1) {
      rtcConnection = rtcConnections[0];
    } else {
      console.log('Error while executing handleNegotiationNeededEvent: rtcConnections has invalid number of connections: ' + rtcConnections.length);
      return;
    }
    // Configure the remote description, which is the SDP payload
    // in our "video-answer" message.
    var desc = new RTCSessionDescription(msg.sdp);
    await rtcConnection.setRemoteDescription(desc).catch((err) => console.log(err));
    clientSetupInfo.inProgress = false;
    console.log('answer has been processed successfully');
   }

   async function initRtcPeer(msg, setupInfo) {
    let rtcConnection = await createPeerConnection(msg.source);
    rtcConnections.push(rtcConnection);
    await handelTransferOfferMessage(msg, rtcConnection);
    setupInfo.inProgress = false;
   }

    async function openSenderWs() {
    let uploadId = getUploadIdFromDownloadLink();
    //let serverUrl = `ws://localhost:8081/?clientId=${uploadId}-s`;
    let serverUrl = `${process.env.SIGNALING_SERVER}/?clientId=${uploadId}-s`;
    wsConnection = new WebSocket(serverUrl, "json");

    wsConnection.onopen = function(evt) {
      console.log('ws connection opened:' + JSON.stringify(evt));
    };
  
    wsConnection.onerror = function(evt) {
      console.log('Error opening ws connection:' + JSON.stringify(evt));
    }
    wsConnection.onmessage = async function(evt) {
      var msg = JSON.parse(evt.data);
      console.log("Message received: " + JSON.stringify(msg));
      
      switch(msg.type) {
        case "transfer-offer": 
        if(rtcConnections.length >= MAX_RTC_CONNECTIONS) {
          console.log('Max RTC connections limit reached: ' + rtcConnections.length);
          break;
        }
        let clientSetupInfo = rtcSetupInfo.find(i => i.clientId === msg.source);
        if(clientSetupInfo) {
          if(clientSetupInfo.inProgress) {
            break;
          } else {
            clientSetupInfo.inProgress = true;
            initRtcPeer(msg, clientSetupInfo);
          }
         
        } else {
            let newClientSetupInfo = {clientId: msg.source, inProgress: true};
            rtcSetupInfo.push(newClientSetupInfo);
            initRtcPeer(msg, newClientSetupInfo);
        }
        break;
        case "new-ice-candidate": // A new ICE candidate has been received
          handleNewICECandidateMsg(msg);
          break;
        // Unknown message; output to console for debugging.
        default:
          console.log("Unknown message received: " + JSON.stringify(msg));
      }
    }
    }

    async function openReceiverWs() {
    let serverUrl = `${process.env.SIGNALING_SERVER}/?clientId=${uploadId}-r`;
    wsConnection = new WebSocket(serverUrl, "json");

    wsConnection.onopen = function(evt) {
      console.log('ws connection opened:' + JSON.stringify(evt));
    };
  
    wsConnection.onerror = function(evt) {
      console.log('Error opening ws connection:' + JSON.stringify(evt));
    }

    wsConnection.onmessage = async function(evt) {
      var msg = JSON.parse(evt.data);
      console.log("Message received: " + JSON.stringify(msg));

      let senderId = `${uploadId}-s`;
      let clientSetupInfo = rtcSetupInfo.find(i => i.clientId === senderId);

      switch(msg.type) {
        case "id":
        if(clientSetupInfo) {
          if(clientSetupInfo.inProgress) {
            break;
          } else {
            clientSetupInfo.inProgress = true;
            let rtcConnection = await createPeerConnection(msg.id);
            rtcConnections.push(rtcConnection);
            rtcUtils.createListener(uploadId, rtcConnection, handleReceiveMessage);
          }
         
        } else {
            let newClientSetupInfo = {clientId: senderId, inProgress: true};
            rtcSetupInfo.push(newClientSetupInfo);
            let rtcConnection = await createPeerConnection(msg.id);
            rtcConnections.push(rtcConnection);
            rtcUtils.createListener(uploadId, rtcConnection, handleReceiveMessage);
        }
        break;
        case "transfer-answer":  // Callee has answered our offer
          handleTransferAnswerMessage(msg, clientSetupInfo);
          break;
        case "new-ice-candidate": // A new ICE candidate has been received
          handleNewICECandidateMsg(msg);
          break;
        // Unknown message; output to console for debugging.
        default:
          console.log("Unknown message received: " + msg);
      }
  }
    }

    const validateRequiredTransferData = () => {
      let validationFailedReasons = [];
      switch (currentActiveTab.name) {
        case consts.TAB_NAMES.Generate_Link:
          //return !(uploadCandidates && uploadCandidates.length > 0 || allValidFilesUploaded && selectedFiles.length > 0);
          if(!(selectedFiles && selectedFiles.length > 0 && validFiles && validFiles.length > 0 )) {
            validationFailedReasons.push({name: consts.VALIDATION_TOOLTIP_REASONS.NO_FILES_ADDED, priority: 1});
          }
          return validationFailedReasons;
          //return (uploadCandidates && uploadCandidates.length > 0 || allValidFilesUploaded && selectedFiles.length > 0);
          case consts.TAB_NAMES.Send_Email:
            let isUserVerified = user && user.isLoggedIn || codeVerificationData.status === consts.CODE_VERIFICATION_STATUS.SUCCESSFUL;
            //return !(items.length > 0 && senderEmail.email != '' && senderEmail.isValid && (uploadCandidates && uploadCandidates.length > 0 || allValidFilesUploaded) && (deviceType === consts.DEVICE_TYPES.MOBILE || isUserVerified));
            if(!items.length > 0) {
              validationFailedReasons.push({name: consts.VALIDATION_TOOLTIP_REASONS.NO_RECIPIENTS_EMAIL, priority: 2});
            }
            if(!(senderEmail.email != '' && senderEmail.isValid)) {
              validationFailedReasons.push({name: consts.VALIDATION_TOOLTIP_REASONS.NO_USER_EMAIL, priority: 3});
            }
            if(!(selectedFiles && selectedFiles.length > 0 && validFiles && validFiles.length > 0)) {
              validationFailedReasons.push({name: consts.VALIDATION_TOOLTIP_REASONS.NO_FILES_ADDED, priority: 1});
            }
            if(!((deviceType === consts.DEVICE_TYPES.MOBILE && !isEmbedded) || isUserVerified)) {
              validationFailedReasons.push({name: consts.VALIDATION_TOOLTIP_REASONS.USER_NOT_VERIFIED, priority: 4});
            }
            let sortedReasons = validationFailedReasons.sort((reasonA, reasonB) => reasonA.priority - reasonB.priority);
            return sortedReasons;
            //return (items.length > 0 && senderEmail.email != '' && senderEmail.isValid && (uploadCandidates && uploadCandidates.length > 0 || allValidFilesUploaded) && (deviceType === consts.DEVICE_TYPES.MOBILE || isUserVerified));
        default:
         return false;
      }
    }

    const getIsButtonDisabled = () => {
    if(previewModeActive) {
      return true;
    }  
    switch (viewState) {
        case FILE_FORM_VIEW:
          //return getDisabledButtonByTransferMode();  Moving from disabled/enabled states to always enabled with validation tooltips
          return false;
        case VARIFICATION_VIEW:
          return !(verificationCode && verificationCode.length >= MIN_CODE_LENGTH) || isVerificationInProgress;
          case RESEND_VERIFICATION_VIEW:
            return emailFrom === '';
        default:
          return false;
        } 
    }

    async function sendCompletionMailIfLoggedIn() {
      if(user.isLoggedIn) {
        await sendMailOrGetLink();
      }
    }
    const getViewToUpdate = (conditionToRender) => {
        switch (viewState) {
            case FILE_FORM_VIEW:
              let nextView = user.isLoggedIn ? DONE_VIEW : deviceType === consts.DEVICE_TYPES.DESKTOP ? LOADING_VIEW : VARIFICATION_VIEW;
              let eventData = {from_step: FILE_FORM_VIEW, to_step: nextView, user_email: emailFrom, is_work_email: utils.isWorkEmail(emailFrom)};
              if(utils.isWorkEmail(emailFrom)) { 
                utils.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.VERIFICATION_AND_WORK_EMAIL);
              }
              utils.trackFormStepChanged(eventData);
              return nextView;
            case VARIFICATION_VIEW:
              let eventDataVerification = {from_step: VARIFICATION_VIEW, to_step: LOADING_VIEW, user_email: emailFrom, is_work_email: utils.isWorkEmail(emailFrom)};
              conditionToRender && utils.trackFormStepChanged(eventDataVerification);
              return conditionToRender ? LOADING_VIEW : VARIFICATION_VIEW;
            case RESEND_VERIFICATION_VIEW:
                return VARIFICATION_VIEW;
            case LOADING_VIEW:
              return FILE_FORM_VIEW;
            case DONE_VIEW:
              let eventDataDone = {from_step: DONE_VIEW, to_step: FILE_FORM_VIEW, user_email: emailFrom, is_work_email: utils.isWorkEmail(emailFrom)};
              utils.trackFormStepChanged(eventDataDone);
              return FILE_FORM_VIEW;
            }
    }

    function renderAnimatedArrow() {
      if(productTutorial.currentStep) {
        let arrowClass;
        switch (productTutorial.currentStep) {
          case consts.PRODUCT_TUTORIAL_STEPS.UPLOAD_FILES:
            arrowClass = style.iconUploadFiles;
            break;
          case consts.PRODUCT_TUTORIAL_STEPS.ADD_RECIPIENTS:
            arrowClass = style.iconAddRecipients;
            break;
          case consts.PRODUCT_TUTORIAL_STEPS.CLICK_SEND:
            arrowClass = style.iconClickSend;
            break;
          case consts.PRODUCT_TUTORIAL_STEPS.COPY_LINK:
            arrowClass = style.iconCopyLink;
            break;
          default:
            break;
        }
        return<div className={arrowClass}>
        <div className={style.arrow}></div>
      </div>
      }
    }

    function getFooterStyle() {
      if(uploadErrorOccurred && uploadErrorOccurred.errorOccurred) { //wait 1s to make sure the parent iframe will receive the massage (it dosen't work if sent immediately)
        setTimeout(() => {
          dispatch({ type: 'FORM-RESIZE-MODE-CHANGED', payload: formResizeMode === consts.FORM_RESIZE_MODE.EXPANDED ? consts.FORM_RESIZE_MODE.MINIMIZED : consts.FORM_RESIZE_MODE.EXPANDED});
          parent.postMessage("expand", "*")
        }, 1000)
      } 
      return {marginTop: !isEmbedded && '63px', background: 'white', width: '290px', height: 'unset', border: '1.41402px solid #E6E9EF', boxSizing: 'border-box', boxShadow: '0px -5.6561px 22.6244px rgba(0, 0, 0, 0.1)', paddingBottom: isEmbedded && '16px'};
    }

    function renderFilesForm(){
      let formStyle = previewModeActive ? previewStyle : style;
      return(
       isEmbedded && (formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS || formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.CANCEL_UPLOAD) && formResizeMode === consts.FORM_RESIZE_MODE.MINIMIZED ? renderFormFooterView({extendedStyle: getFooterStyle()}) :
       <div style={{display: showForm && 'flex', marginTop: (isCustomView && deviceType === consts.DEVICE_TYPES.DESKTOP) && '27vh', height: viewState === FILE_FORM_VIEW && formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD  ? '439px' : formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS  && '458px'}} className={formStyle.container}>
          {productTutorial && productTutorial.show && renderAnimatedArrow()}
          <div className={formStyle.innerContainer}>
            {renderViewByState()}
          </div>
    </div>
      )
    }

    return (
     renderFilesForm()
    )
}
