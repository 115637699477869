/* eslint-disable react/react-in-jsx-scope */
import style from './addFilesView.module.css'
import AddFileIcon from './addFileIcon';
import React, { useRef, useEffect, useState } from "react";
import FileItem from './fileItem';
import utils from './utils'
import FileDownloadItem from './fileDownloadItem'
import DisabledOverlay from './disabledOverlay'
import consts from './consts'

import { useStateValue } from '../state';

export default function AddFilesView({droppedFiles, selectedFiles, updateSelectedFiles, updateDroppedFiles, encodedFiles,
  uploadListChanged, updateUploadListChanged, updateFileUploadCompleted, totalFilesSizeInBytes, uploadMessage, isEmbedded})  {
  
    const DOWNLOAD_BUTTON_FEATURE_FLAG = false;

    const inputFileRef = useRef(null);
    const addSingleFileRef = useRef(null);
    const addMoreFilesRef = useRef(null);
    const messagesEndRef = useRef(null);

    const [isMouseHoverAddFilesView, updateIsMouseHoverAddFilesView] = useState(false);
    const [isMouseHoverAddMoreFilesView, updateIsMouseHoverAddMoreFilesView] = useState(false);

    const [
      {formFooterViewState, user, validationTooltip},
      dispatch,
    ] = useStateValue();

    const handleOnMouseEnter = () => {
      updateIsMouseHoverAddFilesView(true);
    }
    const handleOnMouseLeave = () => {
      updateIsMouseHoverAddFilesView(false);
    }

    const handleAddMoreOnMouseEnter = () => {
      updateIsMouseHoverAddMoreFilesView(true);
    }
    const handleAddMoreOnMouseLeave = () => {
      updateIsMouseHoverAddMoreFilesView(false);
    }

    const scrollToBottom = () => {
       if(messagesEndRef.current){
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }

      const mergeDroppedWithSelectedFiles = () => {
        if(droppedFiles && droppedFiles.length > 0){
          addFiles(droppedFiles);
        }
      }

      useEffect(mergeDroppedWithSelectedFiles, [droppedFiles]);
    
      //useEffect(scrollToBottom, [selectedFiles]);

    const onButtonClick = (e) => {
      inputFileRef.current.click();
        if(e) {
            e.stopPropagation();
        }
      };

      const onAddSingleFileIconClick = (e) => {
        addSingleFileRef.current.click();
        if(e) {
         //   e.preventDefault()
            e.stopPropagation();
            e.target.value = null
        }
      };

      const onAddMoreFilesClick = (e) => {
        if(e) {
            e.preventDefault();
            e.stopPropagation();
        }
        addMoreFilesRef.current.click();
        // `current` points to the mounted file input element
      };

      const onItemClose = (e) => {
        const selectedFile = selectedFiles.find(file => file.name === e.fileName)
        
        //TODO: Support delete on server side as well
        const filteredSelectedFiles = selectedFiles.filter(file => file.name !== e.fileName)
        updateSelectedFiles(filteredSelectedFiles);
        updateDroppedFiles([]); //an ugly hack to clear the Dropzone cache 

        if(selectedFile.uploadInProgress && typeof selectedFile.cancelUpload == 'function'){
           selectedFile.cancelUpload();
        } else { //file was already uploaded and was saved in local storage - delete it from there
          utils.deleteFileFromLocalStorage(e.fileName);
        }

        filteredSelectedFiles && dispatch({ type: 'FILE-PREVIEW-ADDED', payload: {previewFiles: filteredSelectedFiles.map(i => {return {name: i.name, size: i.size, type: i.type , url:'/preview' }}), totalFiles: filteredSelectedFiles.length}}) 
        
        let type = utils.getFileType(selectedFile.name);
        let eventData = {
         file_name: selectedFile.name,
         file_size: selectedFile.size,
         file_type: type
        }
        utils.trackFileRemoved(eventData);
      };

      const validateFileSize = (file) => {
        if(file.size >= 50 * 1024 * 1024 * 1024 ) { //Check if is equal or bigger than 50 GB 
          file.isTooLarge = true;
          file.uploadCompleted = true;
          file.uploadInProgress = false;
        } 
        return file;
      }

      const validateFileType = (file) => {
        let fileTypeBlacklist = ['text/html', 'text/javascript'];
        let fileExtensionBlacklist = ['z', 'Z'];
        let type = file.type;
        let extension = utils.getFileType(file.name);
        if(fileTypeBlacklist.includes(type) || fileExtensionBlacklist.includes(extension)){
          file.rejected = true;
        }
        return file;
      }

      const addFiles = (filesArray) => {
        var res = [];
        let totalAddedFileSize = 0;
        for(let i = 0; i < filesArray.length; i++){
          let file = filesArray[i];
          let type = utils.getFileType(file.name);        
          let eventData = {
            file_name: isEmbedded ? `${file.name}-embedded` : file.name,
            file_size: file.size,
            file_type: type
        }
          totalAddedFileSize += file.size;
          //let sizeValidatedFile = validateFileSize(file);
          let typeValidatedFile = validateFileType(file);
          res.push(typeValidatedFile);
          utils.trackFileAdded(eventData);
      }
      if(totalAddedFileSize + totalFilesSizeInBytes > user.totalFilesSizeLimit) {
        dispatch({ type: 'UPSELL-TOOLTIP-CHANGED', payload: {show: true, reason: consts.UPSELL_REASONS.UPLOAD_LIMIT_EXCEEDED, currentLimit: user.totalFilesSizeLimit}});
        return;
      }
      dispatch({ type: 'UPSELL-TOOLTIP-CHANGED', payload: {show: false}});
      var mergedArray = utils.arrayUniqueNames(selectedFiles.concat(res));
      updateSelectedFiles(mergedArray);
      updateUploadListChanged(!uploadListChanged);
      dispatch({ type: 'FILE-PREVIEW-ADDED', payload: {previewFiles: mergedArray.map(i => {return {name: i.name, size: i.size, type: i.type , url:'/preview' }}), totalFiles: mergedArray.length}}) 
      utils.setSessionStorageItem(consts.SESSION_STORAGE_KEYS.FILES_ADDED, true);
      }

      const onChangeFile = (event) => {
        event.preventDefault();
        event.stopPropagation();
        var files = event.target.files;
        addFiles(files);
    }

    function renderUploadIcon() {
      return (
        <svg className={style.uploadIcon} xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
        <path d="M1 15.75V17.875C1 18.4386 1.22388 18.9791 1.6224 19.3776C2.02091 19.7761 2.56141 20 3.125 20H15.875C16.4386 20 16.9791 19.7761 17.3776 19.3776C17.7761 18.9791 18 18.4386 18 17.875V15.75" stroke="#13BCB4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.1875 7.25L9.5 1.9375L14.8125 7.25" stroke="#13BCB4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.5 1.9375V14.6875" stroke="#13BCB4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      );
    }

    const renderEmptyState = () => {
        return (
            <div onMouseOver={handleOnMouseEnter} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} style={{background: isMouseHoverAddFilesView && 'rgb(240,240,240)', borderTopLeftRadius: isMouseHoverAddFilesView && '19px', borderTopRightRadius: isMouseHoverAddFilesView && '19px'}} onClick={ (e) => onAddSingleFileIconClick(e)} onChange={onChangeFile} className={style.addFilelabel}>
              <div style={{borderColor: validationTooltip.show && validationTooltip.reason === consts.VALIDATION_TOOLTIP_REASONS.NO_FILES_ADDED && '#FF3B4D'}} className={style.formTitleWrapper}>
              <div className={style.formTitleContainer}>
                <div className={style.formTitleInnerContainer}>
                {renderUploadIcon()}
                <p className={style.addYouFilesTitle}>Upload your files</p>
             </div>
              {((user && user.deviceType === consts.DEVICE_TYPES.DESKTOP)) && <span className={style.dragAndDropText}>{"or drag & drop here"}</span>}
                </div>
              </div>

            {false && <div className={style.selectFolderLabel} onClick={ (e) => onButtonClick(e)} onChange={onChangeFile} role="button" tabIndex={0}>
            <p >or select a folder</p>
            <input type="file" multiple webkitdirectory="webkitdirectory" directory="directory" ref={inputFileRef} style={{display: 'none'}}/>
            </div>}
            <div className={style.addFilesIcon} role="button" tabIndex={1}>
            {false && <AddFileIcon width={'36px'} height={'36px'}/>}
            <input type="file" multiple ref={addSingleFileRef} style={{display: 'none'}}/>
            </div>  
            </div>        
        );
    }

    const renderAddMoreFiles = () => {
        return (
            <div>
            <ul className={style.filesList}>
              {selectedFiles.map(({name, size, uploadCompleted, uploadProgressInPercentage, isTooLarge, mbUploaded, uploadInProgress, rejected, preparingForUpload}) => {
              return <FileItem onClose={onItemClose} key= {name} text={isTooLarge ? 'Woops! Files is too large (50GB Max)' : rejected ? 'Whoops! File type not supported' : name} fileName={name} fileSize={utils.bytesToSize(size)} uploadCompleted={uploadCompleted}
              fileUploadProgressInPercentage={preparingForUpload ? 0 : uploadProgressInPercentage} mbUploaded = {preparingForUpload ? 0 : mbUploaded} uploadInProgress = {uploadInProgress && formFooterViewState === consts.FORM_FOOTER_VIEW_STATE.UPLOAD_IN_PROGRESS} preparingForUpload = {preparingForUpload} updateFileUploadCompleted = {updateFileUploadCompleted}/>})}
              <div ref={messagesEndRef} />
            </ul>
            {formFooterViewState !== consts.FORM_FOOTER_VIEW_STATE.EDIT_UPLOAD && <DisabledOverlay/>}
            <div className={style.addMoreFilesWrapper} onMouseOver={handleAddMoreOnMouseEnter}
            onMouseEnter={handleAddMoreOnMouseEnter} onMouseLeave={handleAddMoreOnMouseLeave}
            style={{background: isMouseHoverAddMoreFilesView ? 'rgb(240,240,240)' : '#FAFAFA'}} onClick={ () => onAddMoreFilesClick()} onChange={onChangeFile} role="button" tabIndex={0}>
            <div className={style.addMoreFilesTitle}>
               <p style={{position: 'relative', left: '40px', top: '10px', paddingBottom: '3px'}}>Add more files</p>
                <AddFileIcon width={'24px'} height={'24px'} styleOverride = {{position: "relative", display: 'flex', left: "10px", bottom: '20px'}}/>
                <input type='file' multiple id='file' ref={addMoreFilesRef} style={{display: 'none'}}/>
                <div className={style.filesAddedTitle}>
               {selectedFiles.length <=1 ? `${selectedFiles.length} File Added` : `${selectedFiles.length} Files Added`}
                </div>
            </div>
            </div>
            </div>
        );
    }

    const renderDownloadButton = (extendedStyle) => {
      return (
        <div style={extendedStyle}>
        <button className={style.downloadButton} type="submit" onClick={() => {downloadFiles()}}>
        Download
        </button>
    </div>
      );
    }

    function nameComparer( a, b ) {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    }

    function urlExistsComparer(a, b) {
      if (a.url){
        return -1;
      } else if (b.url ){
        return 1;
      }
      return 0;
    }

    function getDownloadFormText(transferInProgress) {
      if(transferInProgress) {
        let readyFiles = encodedFiles.filter(i => i.url);
        let leftFiles = encodedFiles.filter(i => !i.url);
        return `${readyFiles.length} files ready, ${leftFiles.length} left`
      } else {
        return encodedFiles.length < 2 ? `${encodedFiles.length} File is waiting for you.` : `${encodedFiles.length} Files are waiting for you.`;
      }
    }

    const renderDownloadFiles = () => {
      let transferInProgress = encodedFiles.find(i => !i.url);
      let comparer = transferInProgress ? urlExistsComparer : nameComparer;

      return (
        <div>
          {uploadMessage && <React.Fragment>
          <textarea value={uploadMessage} className={style.linkMessage}/>
          <div className={style.divider}/>
          </React.Fragment> }
        <div className={style.filesToDownload}>
        {getDownloadFormText(transferInProgress)}
        <ul className={style.filesToDownloadList}>
        {encodedFiles.sort(comparer).map(({name, url, size}) => {
            return <FileDownloadItem key= {name} fileName={name} url={url} fileSize={size && utils.bytesToSize(size)}/>})}
        </ul>
        {DOWNLOAD_BUTTON_FEATURE_FLAG && renderDownloadButton({paddingBottom: '20px'})} 
          </div>
          {encodedFiles.length > 4 && updateUploadListChanged(true)}
        </div>
    );
  }

    return ( 
           encodedFiles && typeof encodedFiles !== 'undefined' && encodedFiles.length > 0 ?
           renderDownloadFiles() : 
            <div className={selectedFiles.length !== 0 ? style.container :  style.containerEmptyState}>
            {
              selectedFiles.length !== 0 ?
                renderAddMoreFiles() : 
                renderEmptyState() 
            }
        </div>
    );
}